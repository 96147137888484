module.exports = {
  "settings-interaction": {
    "name": "settings-interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "app": {
        "type": "string",
        "isOptional": true
      },
      "actionType": {
        "type": [
          "CONTACT",
          "COMPANY",
          "DEAL",
          "TASK",
          "TICKET"
        ],
        "isOptional": true
      },
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "clientType": {
        "type": "string",
        "isOptional": true
      },
      "quotasChanged": {
        "type": "number",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settingsInteractionWithType": {
    "name": "settings interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "type": "string",
      "hasWriteScope": {
        "type": "boolean",
        "isOptional": true
      },
      "modal": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settingsInteractionWithSuccess": {
    "name": "settings interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "type": {
        "type": "boolean"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "saveBoardCardCustomizationSettings": {
    "name": "Save Board Card Customization Settings",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "location": "string",
      "objectTypeId": "string",
      "properties": "array",
      "propertiesCount": "number"
    },
    "namespace": "settingsui"
  },
  "settings-email-plugin-check-retry-clicked": {
    "name": "settings-email-plugin-check-retry-clicked",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-enable-company-autocreate": {
    "name": "settings-enable-company-autocreate",
    "class": "usage",
    "properties": {
      "backfillRequired": "boolean",
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-import-export-clicked-import-button": {
    "name": "settings-import-export-clicked-import-button",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-start-company-autocreate-backfill": {
    "name": "settings-start-company-autocreate-backfill",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-bcc": {
    "name": "view-settings-bcc",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-calling": {
    "name": "view-settings-calling",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-companies": {
    "name": "view-settings-companies",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "deprecated": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-config": {
    "name": "view-settings-config",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-contacts": {
    "name": "view-settings-contacts",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "deprecated": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-deals": {
    "name": "view-settings-deals",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "deprecated": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-email": {
    "name": "view-settings-email",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-importexport": {
    "name": "view-settings-importexport",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-notifications": {
    "name": "view-settings-notifications",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-privacy": {
    "name": "view-settings-privacy",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-supportForm": {
    "name": "view-settings-supportForm",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-salestools": {
    "name": "view-settings-salestools",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-tasks": {
    "name": "view-settings-tasks",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "view-settings-tickets": {
    "name": "view-settings-tickets",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "deprecated": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "deal-pipeline-saved": {
    "name": "Deal Pipeline Saved",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "deal-pipeline-created": {
    "name": "Deal Pipeline Created",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "atPipelineLimit": {
        "type": "boolean"
      },
      "pipelineLimit": {
        "type": "number"
      }
    },
    "namespace": "settingsui"
  },
  "lifecycle-stage-automation-setting-changed": {
    "name": "Lifecycle stage automation setting update",
    "class": "usage",
    "properties": {
      "action": {
        "type": "string"
      },
      "enabled": {
        "type": "boolean"
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "lifecycle-stage-saved": {
    "name": "Lifecycle Stage Saved",
    "class": "usage",
    "properties": {
      "action": "string",
      "count": {
        "type": "number"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "ticket-pipeline-saved": {
    "name": "Ticket Pipeline Saved",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "ticket-pipeline-created": {
    "name": "Ticket Pipeline Created",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "atPipelineLimit": {
        "type": "boolean"
      },
      "pipelineLimit": {
        "type": "number"
      }
    },
    "namespace": "settingsui"
  },
  "deal-stage-created": {
    "name": "Deal Stage Created",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "ticket-stage-created": {
    "name": "Ticket Stage Created",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-usage": {
    "name": "Settings Usage",
    "class": "usage",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "subAction": {
        "type": "string",
        "isOptional": true
      },
      "enabled": {
        "type": "boolean",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "settings-navigation": {
    "name": "Settings Navigation",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "contact-settings-pageview": {
    "name": "Contact Settings Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "company-settings-pageview": {
    "name": "Company Settings Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "deal-settings-pageview": {
    "name": "Deal Settings Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "ticket-settings-pageview": {
    "name": "Ticket Settings Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  },
  "import-export-pageview": {
    "name": "Import Export Pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "settingsui"
  }
};