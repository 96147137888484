import { userInfoSync } from 'hub-http/userInfo';
import memoizeOne from 'react-utils/memoizeOne';
const convertToSet = memoizeOne(gates => new Set(gates));
const emptySet = new Set();
export const getGatesSync = () => {
  try {
    // WARN: ignoreRedirect is important! Without it, any consumers that don't
    // support or shim userInfo can end up redirecting (the default behavior).
    // This has caused past critsits:
    // https://git.hubteam.com/HubSpot/Critsit/issues/3557
    // https://git.hubteam.com/HubSpot/Critsit/issues/1040
    const {
      gates
    } = userInfoSync({
      ignoreRedirect: true
    });
    return convertToSet(gates);
  } catch (e) {
    return emptySet;
  }
};