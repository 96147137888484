import { keyBy } from '../utils/keyBy';
// @ts-expect-error untyped
import { deepDiff } from '../vendor/deep-diff';
import { createWorkerInstance } from './createWorkerInstance';
let logPatch = false;
try {
  logPatch = localStorage.getItem('FDSR:LogPatch') === 'true';
} catch (e) {
  // Do nothing
}
const emptyFunction = () => {};
export const runWorker = ({
  // HACK: We want to run some normalization on each entity type before diffing
  // to mimic the behavior of a BE that would be enhanced to support delta-caching.
  // To prevent blocking the main thread, this happens in the worker.
  cacheName,
  current,
  previous,
  extraProcessing,
  normalizeForPatchDiffing
}) => new Promise((resolve, reject) => {
  const worker = createWorkerInstance(`
(() => {
  var DeepDiff = (${deepDiff})();

  var keyBy = ${keyBy};

  var normalize = ${normalizeForPatchDiffing || emptyFunction};

  var extraProcessing = ${extraProcessing || emptyFunction};

  var cacheName = "${cacheName}";

  var logPatch = ${logPatch};

  self.onmessage = (e) => {
    try {
      var current = e.data.current;
      var previous = e.data.previous;

      var extraProcessingResult = extraProcessing(current);

      var normalizedCurrent = normalize(current, { keyBy });

      var diff = DeepDiff.diff(
        normalize(previous, { keyBy }),
        normalizedCurrent
      );

      if (!diff) {
        self.postMessage({
          type: "DATA",
          patchSizeBytes: 0,
          patchSizePct: 0,
          extraProcessingResult
        });
      }

      var patch = [];

      var convertDiffToJsonPatch = (diff, path) => {
        switch(diff.kind) {
          case 'A': {
            convertDiffToJsonPatch(diff.item, diff.path.concat(diff.index));
          }
          case 'N': {
            patch.push({
              op: 'add',
              path: path.join('/'),
              value: diff.rhs
            });
            break;
          }
          case 'D': {
            patch.push({
              op: 'remove',
              path: path.join('/'),
            });
            break;
          }
          case 'E': {
            patch.push({
              op: 'replace',
              path: path.join('/'),
              value: diff.rhs
            });
            break;
          }
        }
      }

      diff.forEach(diff => convertDiffToJsonPatch(diff, diff.path));

      var encoder = new TextEncoder();

      var patchSizeBytes = encoder.encode(JSON.stringify(patch)).length;
      var currentSizeBytes = encoder.encode(JSON.stringify(normalizedCurrent)).length;

      var patchSizePct = patchSizeBytes / currentSizeBytes;

      if (logPatch) {
        console.groupCollapsed('[' + cacheName + '] patch ratio: ' + (patchSizePct * 100).toFixed(2) + '%');
        console.log(JSON.stringify({
          patchSizeBytes,
          totalSizeBytes: currentSizeBytes,
          patch,
        }, null, 2));
        console.groupEnd();
      }

      self.postMessage({
        type: "DATA",
        patchSizeBytes,
        patchSizePct,
        extraProcessingResult
      });
    } catch (e) {
      self.postMessage({ type: "ERROR", error: e });
    }
  }
})();`);
  let didTerminate = false;
  worker.onmessage = function (e) {
    didTerminate = true;
    worker.terminate();
    if (e.data.type === 'ERROR') {
      reject(e.data.error);
    } else if (e.data.type === 'DATA') {
      resolve(e.data);
    }
  };
  setTimeout(() => {
    if (!didTerminate) {
      didTerminate = true;
      worker.terminate();
      reject(Error('Worker timeout'));
    }
  }, 60000);
  worker.postMessage({
    current,
    previous,
    cacheName,
    logPatch
  });
});