'use es6';

import { OrderedMap, Record } from 'immutable';
const defaults = {
  campaignId: 0,
  timestampToCounters: OrderedMap({}),
  gmtOffset: ''
};
class CampaignEngagementOverTime extends Record(defaults) {
  static from(json) {
    const clone = Object.assign({}, json);
    const timestamps = Object.keys(json.timestampToCounters);
    const counters = timestamps.reduce((accum, timestamp) => {
      /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
      return [...accum, [timestamp, json.timestampToCounters[timestamp]]];
    }, []);
    clone.timestampToCounters = OrderedMap(counters);
    return new CampaignEngagementOverTime(clone);
  }
  setGmtOffset(offset) {
    return new CampaignEngagementOverTime({
      campaignId: this.campaignId,
      timestampToCounters: this.timestampToCounters,
      gmtOffset: offset
    });
  }
}
export default CampaignEngagementOverTime;