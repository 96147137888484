import { NON_RECURRING, RECURRING } from 'self-service-api/constants/ChargeTypes';
import { QUANTITY_PACK } from 'self-service-api/constants/SkuTypes';
import { getProductInfoFromQuantitySkuId, getProductInfoFromSkuId, getSkuMap } from './productUtils';
const reduceProductConfigByChargeType = (productConfigurations, chargeType, products) => {
  const skuMap = getSkuMap(products);
  return productConfigurations.filter(productConfiguration => {
    const productInfo = getProductInfoFromSkuId(products, productConfiguration.skuId) || getProductInfoFromQuantitySkuId(products, productConfiguration.skuId);
    return productInfo && productInfo.chargeType === chargeType;
  }).map(({
    skuId,
    quantity
  }) => {
    const skuInfo = skuMap[skuId];
    if (skuInfo.skuType === QUANTITY_PACK) {
      const {
        includedWithQuantityPack
      } = skuInfo;
      const calculatedQuantity = Math.ceil(quantity / includedWithQuantityPack);
      return {
        skuId,
        quantity: calculatedQuantity
      };
    }
    return {
      skuId,
      quantity
    };
  });
};
export const createOfferPayload = ({
  offerId,
  productConfigurations = [],
  productBasedOffers = [],
  products,
  requestedCurrency
}) => {
  const offerProductConfigurations = [];
  const recurringProducts = reduceProductConfigByChargeType(productConfigurations, RECURRING, products);
  if (recurringProducts.length && offerId) {
    offerProductConfigurations.push({
      offerId,
      productConfigurations: recurringProducts
    });
  }
  const nonRecurringProductConfigurations = reduceProductConfigByChargeType(productConfigurations, NON_RECURRING, products);
  productBasedOffers.forEach(productBasedOfferId => {
    offerProductConfigurations.push({
      offerId: productBasedOfferId,
      productConfigurations: []
    });
  });
  return {
    offerProductConfigurations,
    nonRecurringProductConfigurations,
    requestedCurrency
  };
};