export const ACTIVITY_CREATOR_INTERACTIONS = 'activityCreatorInteractions';
export const ACTIVITY_CREATOR_FILE_INTERACTIONS = 'activityCreatorFileInteractions';
export const ACTIVITY_CREATOR_REORDER_INTERACTIONS = 'activityCreatorReorderInteractions';
export const BIZOPS_INTERACTION = 'bizops-interaction';
export const CREATE_ENGAGEMENT_ACTIVATION = 'createEngagementActivation';
export const CRM_DEBUG = 'crm-debug';
export const CRM_ERROR_BOUNDARY = 'crmErrorBoundary';
export const CRM_ONBOARDING = 'crm-onboarding';
export const ENGAGEMENT_USAGE = 'engagementUsage';
export const INDEX_INTERACTION = 'index-interaction';
export const INTERNAL_CRM_TRACKING = 'internal-crm-tracking';
export const RECORD_INTERACTION = 'record-interaction';
export const SETTINGS_INTERACTION = 'settings-interaction';