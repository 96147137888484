import isEqual from 'hs-lodash/isEqual';
import { ALL_TEAMS_OPTION, ME_OPTION, MY_TEAMS_OPTION, SELECTION_ID_ALL, SELECTION_ID_ME, SELECTION_ID_MY_TEAMS, SELECTION_TYPE_CUSTOM, SELECTION_TYPE_TEAM, SELECTION_TYPE_USER } from 'framework-listing-lib/constants/access';
import { getAllChildTeamIdsForParentTeamId } from 'ui-asset-management-lib/utils/TeamUtils';
export function isTeamOption(option) {
  return option.type === 'TEAM';
}
export function isUserOption(option) {
  return option.type === 'USER';
}

/**
 * Returns true if @option is { id: 'ALL', type: 'CUSTOM' }
 */
export function isMyTeamsOption(option) {
  return option.id === MY_TEAMS_OPTION.id && option.type === MY_TEAMS_OPTION.type;
}

/**
 * Returns true if @option is { id: 'ALL', type: 'CUSTOM' }
 */
export function isAllTeamsOrUsersOption(option) {
  return option.id === ALL_TEAMS_OPTION.id && option.type === ALL_TEAMS_OPTION.type;
}

/**
 * Returns true if @option is { id: 'ASSIGNED_TO_USER', type: 'CUSTOM' }
 */
export function isMeOption(option) {
  return option.id === ME_OPTION.id && option.type === ME_OPTION.type;
}

/**
 * Users are not filterable if
 * - there are zero or one users
 */
export function usersAreFilterable(users) {
  return users && users.length > 1;
}

/**
 * Teams are not filterable if
 * - there are no teams OR
 * - there is only one team and that team has no child teams
 */
export function teamsAreFilterable(teams) {
  if (!teams || teams.length === 0) {
    return false;
  }
  const hasNoChildTeams = teams.every(team => !team.childTeams || team.childTeams.length === 0);
  return !(teams.length === 1 && hasNoChildTeams);
}
export const DEFAULT_USE = 'label';

/**
 * The list of teams includes sub-teams that the user is not explicitly
 * assigned to (indicated by my_team being false). We don't filter these
 * out because we want to include these teams in our query to CrmSearch.
 */
export function getMyTeamIds(userInfo) {
  return userInfo && userInfo.user && userInfo.user.teams ? userInfo.user.teams.map(team => team.id) : [];
}

/**
 * The list of all team ids in the portal in the hierarchy
 */
export function getAllTeamIds(allTeams) {
  return allTeams.reduce((acc, team) => {
    return [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    ...acc, team.id, ...getAllChildTeamIdsForParentTeamId(team.id, allTeams)];
  }, []);
}

/**
 * Return the value that should be selected in the dropdown, based on
 * the current filter values.
 */
export function getSelectedValueFromFilters({
  allTeams = [],
  allUsers = [],
  selectedUserIds,
  selectedTeamIds,
  myUserId,
  myTeamIds,
  hasAllTeamsOption,
  hasAllUsersOption,
  isMulti
}) {
  const haveTeamIds = selectedTeamIds && selectedTeamIds.length;
  const haveUserIds = selectedUserIds && selectedUserIds.length;

  // the logged in user's user is selected
  if (isEqual(selectedUserIds, [myUserId])) {
    if (!isMulti) {
      return SELECTION_ID_ME;
    }
    if (myUserId) {
      return [SELECTION_ID_ME, myUserId];
    }
    return [SELECTION_ID_ME];
  }
  if (hasAllUsersOption && hasAllTeamsOption && isMulti) {
    return [SELECTION_ID_ALL, ...allUsers.map(user => user.id), ...getAllTeamIds(allTeams)];
  }
  if (hasAllUsersOption && isMulti) {
    return [SELECTION_ID_ALL, ...allUsers.map(user => user.id)];
  }
  if (hasAllTeamsOption && isMulti) {
    return [SELECTION_ID_ALL, ...getAllTeamIds(allTeams)];
  }

  // the logged in user's teams are selected
  const hasSelectedMyTeams = myTeamIds && myTeamIds.length > 0 && myTeamIds.every(teamId => selectedTeamIds && selectedTeamIds.includes(teamId)) && selectedTeamIds && selectedTeamIds.length === myTeamIds.length;
  if (hasSelectedMyTeams) {
    return isMulti ? [SELECTION_ID_MY_TEAMS, ...myTeamIds] : SELECTION_ID_MY_TEAMS;
  }

  // a user is selected
  if (haveUserIds) {
    return isMulti ? selectedUserIds : selectedUserIds[0];
  }

  // a team is selected
  if (haveTeamIds) {
    return isMulti ? selectedTeamIds : selectedTeamIds[0];
  }

  // nothing is selected
  return isMulti ? [] : SELECTION_ID_ALL;
}

/**
 * Return the selected User IDs based on the event received from the dropdown.
 */
export function getUserIdsFromChangeEvent(type, id, myUserId) {
  if (type === SELECTION_TYPE_USER) {
    return [id];
  } else if (type === ME_OPTION.type && id === ME_OPTION.id) {
    return [myUserId];
  } else {
    return [];
  }
}

/**
 * Return the selected Team IDs based on the event received from the dropdown.
 */
export function getTeamIdsFromChangeEvent(type, id, myTeamIds, teams) {
  if (type === SELECTION_TYPE_TEAM) {
    /**
     * When selecting a team we need to send all team hierarchy under that
     * selected team in order to get the correct results from crm-search
     * endpoint.
     */
    return [id, ...getAllChildTeamIdsForParentTeamId(id, teams)];
  } else if (type === SELECTION_TYPE_CUSTOM && id === SELECTION_ID_MY_TEAMS) {
    return myTeamIds;
  } else {
    return [];
  }
}

/**
 * Returns which option should be selected next based on previous selection of
 * "All teams" or "My team" options.
 */
export function handleMultiTeamChangeEvent({
  allTeamIds,
  myTeamIds,
  newValue,
  selectAllRefValue,
  selectMyTeamsRefValue
}) {
  const hasMyTeamsOption = newValue.some(isMyTeamsOption);
  const hasAllTeamsOption = newValue.some(isAllTeamsOrUsersOption);
  const hasSelectedUserIndividualTeamIds = newValue.length === myTeamIds.length && myTeamIds.every(teamId => newValue.find(option => option.type === 'TEAM' && option.id === teamId));

  /**
   * Is selecting "My teams" option:
   *   - @selectMyTeamsRef is false and @hasMyTeamsOption is true OR
   *   - @selectMyTeamsRef is false and user has ONLY selected all of his/her individual teams
   */
  const isSelectingMyTeamsOption = !selectMyTeamsRefValue && (hasMyTeamsOption || hasSelectedUserIndividualTeamIds);
  if (isSelectingMyTeamsOption) {
    return {
      isSelectingMyTeamsOption
    };
  }

  /**
   * Is selecting "All teams" option:
   *   - @selectAllRef is false and @hasAllTeamsOption is true OR
   *   - @selectAllRef is false and all team ids are selected
   */
  const hasSelectedAllTeamsIds = allTeamIds.every(teamId => newValue.some(option => option.type === 'TEAM' && option.id === teamId));
  const isSelectingAllTeamsOption = !selectAllRefValue && (hasAllTeamsOption || hasSelectedAllTeamsIds);
  if (isSelectingAllTeamsOption) {
    return {
      isSelectingAllTeamsOption
    };
  }

  /**
   * Is de-selecting "My teams" option:
   *   - @selectMyTeamsRef is true and @newValue contains user's team ids
   */
  const isDeselectingMyTeamsOption = selectMyTeamsRefValue && hasSelectedUserIndividualTeamIds;
  if (isDeselectingMyTeamsOption) {
    return {
      isDeselectingMyTeamsOption
    };
  }

  /**
   * Is de-selecting "All teams" option:
   *   - @selectAllRef is true and @newValue contains all team ids
   */

  const hasAllOption = newValue.some(isAllTeamsOrUsersOption);
  const isDeselectingAllTeamsOption = !hasAllOption && hasSelectedAllTeamsIds;
  if (isDeselectingAllTeamsOption) {
    return {
      isDeselectingAllTeamsOption
    };
  }
  return {
    isNormalFlow: true
  };
}

/**
 * Returns which option should be selected next based on previous selection of
 * "All users" or "Me" options.
 */
export function handleMultiUserChangeEvent({
  myUserId,
  newValue,
  selectAllRefValue,
  selectMeRefValue,
  users
}) {
  const hasMeOption = newValue.some(isMeOption);
  const hasAllUsersOption = newValue.some(isAllTeamsOrUsersOption);

  /**
   * Is selecting "Me" option:
   *   - @selectMeRef is false and @hasMeOption is true OR
   *   - @selectMeRef is false user has selected [him|her]self
   */
  const isSelectingMeOption = !selectMeRefValue && (selectAllRefValue || !hasAllUsersOption) && (hasMeOption || newValue.length === 1 && newValue[0].id === myUserId);
  if (isSelectingMeOption) {
    return {
      isSelectingMeOption
    };
  }
  const hasSelectedAllUserIds = users.every(user => newValue.find(option => option.type === 'USER' && option.id === user.id));

  /**
   * Is selecting "All users" option:
   *   - @selectAllRef is false and @hasAllUsersOption is true OR
   *   - @selectAllRef is false and all user ids are selected
   */
  const isSelectingAllUsersOption = !selectAllRefValue && (hasAllUsersOption || hasSelectedAllUserIds);
  if (isSelectingAllUsersOption) {
    return {
      isSelectingAllUsersOption
    };
  }

  /**
   * Is de-selecting "Me" option:
   *   - @selectMeRef is true and @newValue contains users's id
   */
  const isDeselectingMeOption = selectMeRefValue && newValue.length === 1 && newValue[0].id === myUserId;
  if (isDeselectingMeOption) {
    return {
      isDeselectingMeOption
    };
  }

  /**
   * User is de-selecting "All users" option:
   *   - @selectAllRef is true and @newValue contains all user ids are selected
   */
  const hasAllOption = newValue.some(isAllTeamsOrUsersOption);
  const isDeselectingAllUsersOption = !hasAllOption && hasSelectedAllUserIds;
  if (isDeselectingAllUsersOption) {
    return {
      isDeselectingAllUsersOption
    };
  }
  return {
    isNormalFlow: true
  };
}

/**
 * Given the newValue, this function will remove any child teams if a parent was deselected. If
 * there is no parent team being unselected it will return the original newValue.
 */
export function handleDeselectParentTeam({
  prevValue,
  newValue,
  teams
}) {
  const teamIds = teams.map(team => team.id);
  const newValueIds = newValue.map(team => team.id);
  const deselectedIds = prevValue ? prevValue.filter(id => !newValueIds.includes(id)) : [];
  const deselectedParentIds = deselectedIds.filter(id => teamIds.includes(id) && getAllChildTeamIdsForParentTeamId(id, teams).length);
  return deselectedParentIds.reduce((filteredNewValue, parentTeamId) => removeChildTeams({
    parentTeamId,
    newValue: filteredNewValue,
    teams
  }), newValue);
}

/**
 * Removes any children teams from the TeamOption[] and returns the remaining values
 */
export function removeChildTeams({
  parentTeamId,
  newValue,
  teams
}) {
  const childIds = getAllChildTeamIdsForParentTeamId(parentTeamId, teams);
  return newValue.filter(value => {
    if (value.type === 'TEAM' && typeof value.id === 'number') {
      return !childIds.includes(value.id);
    }
    return true;
  });
}