import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["earlyRequest"];
import quickFetch from 'quick-fetch';
import { getAvroGateway } from './chirp-avro';
// Borrowed from: https://git.hubteam.com/HubSpot/apollo-link-hub-http/blob/master/static/js/internal/stableStringify.ts#L1
const stableCopy = value => {
  if (!value || typeof value !== 'object') {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(stableCopy);
  }
  const keys = Object.keys(value).sort();
  const stable = {};
  for (let i = 0; i < keys.length; i++) {
    stable[keys[i]] = stableCopy(value[keys[i]]);
  }
  return stable;
};
const stableStringify = value => {
  return JSON.stringify(stableCopy(value));
};
const createRequestName = (details, request) => {
  const hashCode = s =>
  // eslint-disable-next-line no-bitwise
  s.split('').reduce((a, b) => (a << 5) - a + b.charCodeAt(0) | 0, 0);
  const stableString = stableStringify({
    rpcName: details.rpcName,
    serviceName: details.serviceName,
    fingerprint: details.fingerprint,
    request: request !== null && request !== void 0 ? request : {}
  });
  return hashCode(stableString).toString();
};

/**
 * Makes a chirp early request using quick-fetch.
 *
 * This REQUIRES the details object from `/earlyRequest/{SERVICE}.early.ts for tree shaking purposes.
 *
 * To receive this data, use the normal details object in a call. If the data is ready, it will be returtned.
 */
export const makeChirpEarlyRequest = (details, inputs) => {
  const {
    request
  } = inputs || {
    request: {}
  };
  const {
    auth,
    serviceName,
    rpcName,
    fingerprint
  } = details;
  const detailsCopyWithoutEarlyRequest = _objectWithoutPropertiesLoose(details, _excluded);
  const requestName = createRequestName(detailsCopyWithoutEarlyRequest, request);
  const endpointUrl = `${quickFetch.getApiUrl(getAvroGateway(auth))}/${serviceName}/${rpcName}`;
  return quickFetch.makeEarlyRequest(requestName, {
    type: 'POST',
    data: JSON.stringify(request),
    extraHeaders: [{
      header: 'x-hs-fingerprint',
      value: fingerprint
    }],
    url: endpointUrl,
    timeout: 7000
  });
};
export const getChirpEarlyRequest = ({
  details,
  requestObject
}) => {
  const {
    request
  } = requestObject || {
    request: {}
  };
  const requestName = createRequestName(details, request);
  const requestState = quickFetch.getRequestStateByName(requestName);
  if (requestState && !requestState.error) {
    return new Promise((resolve, reject) => {
      requestState.whenFinished(response => {
        quickFetch.removeEarlyRequest(requestName);
        // Quick fetch responses are always strings
        resolve(JSON.parse(response));
      });
      requestState.onError((_, errorMessage) => {
        quickFetch.removeEarlyRequest(requestName);
        reject(errorMessage);
      });
    });
  }
  return undefined;
};