import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
export function fetchWorkflowsRequest(emailId) {
  return http.get(`cosemail/v1/emails/${emailId}/workflows`, null);
}
export function fetchSimpleWorkflowsRequest(emailId) {
  const uuid = `${PortalIdParser.get()}-${emailId}`;
  return http.get('automationapps/v1/summaries', {
    query: {
      limit: 25,
      sortBy: 'updatedAt',
      sortOrder: 'descending',
      uuid
    }
  });
}