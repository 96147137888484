'use es6';

import formatName from 'I18n/utils/formatName';
import { createSelector } from 'reselect';
import { getEmailAuthSelectors } from 'EmailData/selectors/emailAuthSelectors';
import { getHasAccessToEmailType } from 'EmailData/helpers/EmailTypeHelpers';
import EmailType from 'EmailData/enums/EmailType';
import { getIsProductReadOnly } from 'EmailData/selectors/portalCritsitSelectors';
import { createHasGateSelector, getGatesArray } from 'EmailData/selectors/gates';
import { createHasScopeSelector, getScopes } from 'EmailData/selectors/scopes';
import { getPortalRestrictions } from 'EmailData/portalStatus/selectors';
import { gate } from 'hub-http/gates';
export const EMAIL_BUSINESS_UNIT_GATE = 'campaign-business-unit';
const emailAuthSelectors = getEmailAuthSelectors(getGatesArray, getScopes);
export const getUser = state => state.get('user');
export const getUserName = state => {
  const user = getUser(state);
  return formatName({
    firstName: user.get('first_name'),
    lastName: user.get('last_name')
  });
};
export const getEmailTypesAuthedFor = emailAuthSelectors.getEmailTypesAuthedFor;
export const getHasAttributionScope = createHasScopeSelector('revenue-attribution-read');
export const getHasSoftLimitScope = createHasScopeSelector('email-sending-soft-limit');
export const getHasPermissionsToLoadDashboard = createHasScopeSelector('email-dashboard-access');
export const getHasBlogSettingsRead = createHasScopeSelector('blog-settings-read');
export const getHasEmailRecipientFatigueSuppressionReadScope = createHasScopeSelector('email-recipient-fatigue-suppression-read');
export const getIsFreeEmail = emailAuthSelectors.getHasFreeEmail;
export const getIsStarterEmail = emailAuthSelectors.usingEmailStarter;
export const getIsProOrEnterprise = createSelector([getIsFreeEmail, getIsStarterEmail], (isFreeEmail, isStarterEmail) => !isFreeEmail && !isStarterEmail);
export const getIsFreeOrStarter = createSelector([getIsFreeEmail, getIsStarterEmail], (isFreeEmail, isStarterEmail) => isFreeEmail || isStarterEmail);
export const getHasDedicatedIp = createHasScopeSelector('dedicated-ip-marketing');
export const getHasAutomatedEmailsAuth = createSelector([getScopes], scopes => getHasAccessToEmailType(scopes, EmailType.AUTOMATED_EMAIL));
export const getHasUpdatedPostSendCopy = createHasGateSelector('MarketingEmail:UpdatedPostSendCopy');
export const getIsUngatedForAutomatedABAdvanced = createHasGateSelector('MarketingEmail:AbAutomatedAdvanced');
export const getHasMarketingContacts = createSelector([getIsFreeEmail, createHasScopeSelector('marketable-contacts-access') //Regular access
], (isFreeEmail, hasAccess) => {
  return !isFreeEmail && hasAccess;
});
export const getHasMarketingContactsWriteAccess = createHasScopeSelector('marketable-contacts-write');
export const getHasMultiLangAccess = createHasScopeSelector('cms-multi-language');
export const getHasFullEditorAccess = emailAuthSelectors.getHasFullEditorAccess;
export const getHasOnboarding = emailAuthSelectors.getHasOnboarding;
export const getHasCampaignsAccess = emailAuthSelectors.getHasCampaignsAccess;
export const getHasCrmExportScope = createHasScopeSelector('crm-export');
export const getHasCrmImportScope = emailAuthSelectors.getHasCrmImportScope;
export const getContactsListsAccessScope = createHasScopeSelector('contacts-lists-access');
export const getShouldShowBranding = createSelector([getScopes, getIsFreeEmail], (scopes, isFree) => isFree && scopes.indexOf('leadin-no-branding') < 0);
export const getCanHaveMultipleOfficeLocations = emailAuthSelectors.getCanHaveMultipleOfficeLocations;
export const hasCtaAccessSelector = createHasScopeSelector('cta-access');
export const hasSupportAccessSelector = emailAuthSelectors.getHasSupportAccessSelector;
export const getHasMarketingEmailProduct = emailAuthSelectors.getHasMarketingEmailProduct;
export const userIdSelector = state => state.getIn(['user', 'user_id'], null);
export const getCanSelectMoreThanRegularEmail = createSelector(getEmailTypesAuthedFor, emailTypes => emailTypes.indexOf(EmailType.AUTOMATED_EMAIL) > -1 || emailTypes.indexOf(EmailType.BLOG_EMAIL) > -1 || emailTypes.indexOf(EmailType.RSS_EMAIL) > -1);
export const getHasReadOnlyFatigueSuppression = emailAuthSelectors.getHasReadOnlyFatigueSuppression;
export const getIsHubSpotter = createHasScopeSelector('jita-user');
export const getIsPartner = createHasScopeSelector('partner-employee');
export const canCreateDragDropEmail = emailAuthSelectors.canCreateDragDropEmail; // args are (gates, scopes)
export const hasContextualAutomationGates = emailAuthSelectors.hasContextualAutomationGates; // arg is (gates)
export const canCreateClassicEmail = emailAuthSelectors.canCreateClassicEmail; // args are (scopes)
export const getCanCreateClassicEmail = emailAuthSelectors.getCanCreateClassicEmail;
export const getCanCreateDragDropEmail = emailAuthSelectors.getCanCreateDragDropEmail;
export const getHasEmailBlogOnlyAccess = emailAuthSelectors.getHasEmailBlogOnlyAccess;
export const getDragDropEmailAvailable = emailAuthSelectors.getDragDropEmailAvailable;
export const getPqlUpgradeProduct = emailAuthSelectors.getPqlUpgradeProduct;
export const getHasFullDashboardAccess = emailAuthSelectors.getHasEmailAccess;
export const getHasEmailAccess = emailAuthSelectors.getHasEmailAccess;
export const getHasEmailEditorAccess = emailAuthSelectors.getHasEmailEditorAccess;
export const getPortalHasSavedTemplates = emailAuthSelectors.getPortalHasSavedTemplates;
export const getCanUseTestSend = emailAuthSelectors.getCanUseTestSend;
export const getHasWorkflowsWriteAccess = emailAuthSelectors.getHasWorkflowsWriteAccess;
export const getHasFormsWriteAccess = emailAuthSelectors.getHasFormsWriteAccess;
export const getHasTransactionSmtpAccess = createHasScopeSelector('transactional-email-smtp');
export const getCanAccessCampaignScopes = createSelector([getHasCampaignsAccess, getHasEmailEditorAccess], (hasCampaignsAccess, hasEmailEditorAccess) => hasCampaignsAccess && hasEmailEditorAccess);
export const getHasCosEmailTrialGate = createHasGateSelector('cos_trial_email_ungated');
export const getShouldDisableCreateEmailAction = createSelector([getUser, getCanCreateClassicEmail, getCanCreateDragDropEmail, getIsProductReadOnly], (user, createClassicEmail, createDragAndDropEmail, productReadOnly) => {
  const hasEmailCreatePermission = user.canWrite();
  return !hasEmailCreatePermission || !createClassicEmail && !createDragAndDropEmail || productReadOnly;
});
export const getIsUngatedForSubscriptionGroups = createHasGateSelector('subscriptions:groupSettings');
const getIsUngatedForBusinessUnit = createHasGateSelector(EMAIL_BUSINESS_UNIT_GATE);
export const getHasPermissionToLoadBusinessUnit = createHasScopeSelector('business-units-access');
export const hasAccessToBusinessUnit = createSelector([getIsUngatedForBusinessUnit, getHasPermissionToLoadBusinessUnit], (canAccessBusinessUnit, hasPermissionToLoadBusinessUnit) => canAccessBusinessUnit && hasPermissionToLoadBusinessUnit);
export const getIsUngatedForRegularAutomation = createHasGateSelector('Email:ContextualAutomationV1');
export const getHasAutomationGates = createSelector([getIsUngatedForRegularAutomation], isUngatedForRegularAutomation => isUngatedForRegularAutomation);
export const getHasAccessToEmailApprovalWriteScope = createSelector(getScopes, scopes => scopes.includes('email-approval-write'));
export const getHasAccessToEmailApprovalReadScope = createSelector(getScopes, scopes => scopes.includes('email-approval-read'));
export const getIsFreePortal = state => {
  const portalRestrictions = getPortalRestrictions(state);
  return portalRestrictions.isFreePortal();
};
export const getHasAccessToSeamlessPortalMigration = createSelector([getIsFreePortal], isFreePortal => !isFreePortal);
export const getHasEmailFatigueSuppressionAccess = emailAuthSelectors.getHasFatigueSuppressionAccess;
export const getCanSeeAdditionalMoreToolsContent = createSelector([getIsHubSpotter, getCanCreateDragDropEmail, getHasFullDashboardAccess, getHasTransactionSmtpAccess, getHasSoftLimitScope], (isHubSpotter, _canCreateDragDropEmail, hasFullDashboardAccess, hasTransactionalSMTP, hasSoftLimit) => {
  return isHubSpotter && !_canCreateDragDropEmail || !hasFullDashboardAccess || hasTransactionalSMTP && hasFullDashboardAccess || !hasSoftLimit;
});
export const getIsUngatedForBUPicker = createHasGateSelector('navigation:businessUnitPicker');
export const getShouldUseBUPicker = createSelector([getIsUngatedForBUPicker, hasAccessToBusinessUnit], (isUngatedForBUPicker, canAccessToBusinessUnit) => {
  return isUngatedForBUPicker && canAccessToBusinessUnit;
});
export const getIsUngatedForTeamPartitioningLists = createHasGateSelector('teams-partitioning-lists');
export const getIsUngatedForDisableNonMarketableListCreation = createHasGateSelector('Email:DisableNonMarketableListCreation');
export const getIsSuperUser = createHasScopeSelector('super-user');
export const getIsSuperAdmin = createHasScopeSelector('super-admin');
export const getIsSuperAdminOrUser = createSelector([getIsSuperUser, getIsSuperAdmin], (isSuperUser, isSuperAdmin) => {
  return isSuperAdmin || isSuperUser;
});
export const getCanCreateNonMarketableListAsSuper = createSelector([getIsUngatedForDisableNonMarketableListCreation, getIsSuperAdminOrUser], (isUngatedForDisableNonMarketableListCreation, isSuperAdminOrUser) => {
  return !isUngatedForDisableNonMarketableListCreation || isUngatedForDisableNonMarketableListCreation && isSuperAdminOrUser;
});
export const getIsUngatedForAssignTeamInCreation = createHasGateSelector('Email:AssignTeamInCreation');
const hasContentPartitioningScope = scopes => scopes.indexOf('teams-content-partitioning') >= 0 && scopes.indexOf('teams-marketing-email-partitioning') >= 0;
const hasEmailPartitioningGate = gates => gates.indexOf('teams-partitioning-group-2') >= 0;
export const hasEmailContentPartitioning = (gates, scopes) => hasContentPartitioningScope(scopes) && hasEmailPartitioningGate(gates);
export const getHasUsersTeamsConfig = state => hasEmailContentPartitioning(getGatesArray(state), getScopes(state));
export const getHasUsersTeamsAccess = createSelector([getHasUsersTeamsConfig], hasUsersTeamsConfig => hasUsersTeamsConfig // || hasUserTeamsAssignment - only when ungated to all
);
export const hasContentPartitioningAssignScope = scopes => scopes.indexOf('content-team-assignment') >= 0;
export const getHasUserTeamsAssignment = state => hasContentPartitioningAssignScope(getScopes(state));
export const getHasAssignAssetAccess = createSelector([getHasUsersTeamsAccess, getHasUserTeamsAssignment], (hasUsersTeamsAccess, hasUserTeamsAssignment) => hasUsersTeamsAccess && hasUserTeamsAssignment);
export const getUserTeams = createSelector([getUser], user => (user.teams || []).filter(team => team.my_team).map(team => team.id));
export const getIsUngatedForNewArchiveModal = createHasGateSelector('Email:ArchiveEmails');
export const getShouldUseNewArchiveModal = createSelector([getIsUngatedForNewArchiveModal, getIsUngatedForRegularAutomation], (isUngatedForNewArchiveModal, isUngatedForRegularAutomation) => isUngatedForNewArchiveModal && isUngatedForRegularAutomation);
export const getIsUngatedForInAppGoogleYahooAlerts = createHasGateSelector('Email:InAppGoogleYahooAlerts');
export const getIsUngatedForGenerateAITranslatedEmail = createHasGateSelector(gate('Email:AITranslation'));
export const getIsUngatedForBounceActionsRemoval = createHasGateSelector(gate('Messaging:RemoveBounceActions'));