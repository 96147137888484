'use es6';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { KOALA } from 'HubStyleTokens/colors';

/**
 * A simple skeleton ui for text which is currently loading; shows a gray bar.
 * It's approximately the height of average text in the current font size (0.8em),
 * so it looks sort of like text when you squint.
 *
 * Example:
 *   <SkeletonText />
 *   <SkeletonText width={80} />
 *
 * See https://git.hubteam.com/HubSpot/conversations-skeleton-state
 */

const getSize = value => typeof value === 'number' ? `${value}px` : value;
const SkeletonText = styled.div.withConfig({
  displayName: "SkeletonText",
  componentId: "sc-1aqgyng-0"
})(["background-color:", ";border-radius:999px;display:inline-block;height:0.8em;width:", ";"], KOALA, ({
  width
}) => getSize(width));
SkeletonText.defaultProps = {
  width: '6em'
};
SkeletonText.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default SkeletonText;