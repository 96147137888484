import styled from 'styled-components';
import { BATTLESHIP, GYPSUM, OLAF } from 'HubStyleTokens/colors';
import UIBox from 'UIComponents/layout/UIBox';
import UIFlex from 'UIComponents/layout/UIFlex';
const FOLDER_TAB_WIDTH = 120;
export const ViewTabsContainer = styled(UIFlex).attrs({
  'data-test-id': 'view-tabs-container'
}).withConfig({
  displayName: "StyledComponents__ViewTabsContainer",
  componentId: "p5bpng-0"
})(["border-bottom:1px solid ", ";"], BATTLESHIP);
export const StyledUIBox = styled(UIBox).withConfig({
  displayName: "StyledComponents__StyledUIBox",
  componentId: "p5bpng-1"
})(["position:relative;top:1px;"]);
export const OverflowAutoUIBox = styled(StyledUIBox).withConfig({
  displayName: "StyledComponents__OverflowAutoUIBox",
  componentId: "p5bpng-2"
})(["overflow:auto;"]);
export const StyledViewTabs = styled.div.withConfig({
  displayName: "StyledComponents__StyledViewTabs",
  componentId: "p5bpng-3"
})(["display:grid;grid-gap:calc(0 / 2);grid-template-rows:minmax(40px,1fr);grid-auto-flow:column;grid-auto-columns:minmax(100px,450px);grid-template-columns:repeat(auto-fill,minmax(100px,450px));grid-column:1 / -1;scrollbar-width:none;padding-bottom:0;&::-webkit-scrollbar{display:none;}"]);
export const StyledFolderTabs = styled(StyledViewTabs).withConfig({
  displayName: "StyledComponents__StyledFolderTabs",
  componentId: "p5bpng-4"
})(["grid-auto-columns:minmax(100px,", "px);grid-template-columns:repeat( auto-fill,minmax(", "px,", "px) );margin-left:50px;min-width:", "px;width:", "px;"], FOLDER_TAB_WIDTH, FOLDER_TAB_WIDTH, FOLDER_TAB_WIDTH, FOLDER_TAB_WIDTH, FOLDER_TAB_WIDTH);
export const StyledFolderTab = styled(UIBox).withConfig({
  displayName: "StyledComponents__StyledFolderTab",
  componentId: "p5bpng-5"
})(["margin-left:50px;min-width:", "px;width:", "px;"], FOLDER_TAB_WIDTH, FOLDER_TAB_WIDTH);
export const StyledFolderTabContent = styled(UIFlex).withConfig({
  displayName: "StyledComponents__StyledFolderTabContent",
  componentId: "p5bpng-6"
})(["background-color:", ";border-bottom:", ";border-left:calc(1px / 2) solid ", ";border-right:calc(1px / 2) solid ", ";border-top:1px solid ", ";cursor:pointer;height:39px;padding:0 15px;position:relative;top:1px;"], props => props.active ? `${OLAF}` : `${GYPSUM}`, props => props.active ? `1px solid transparent` : `1px solid ${BATTLESHIP}`, BATTLESHIP, BATTLESHIP, BATTLESHIP);