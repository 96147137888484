import { BIZOPS_INTERACTION, CRM_DEBUG, CRM_ONBOARDING, INDEX_INTERACTION, INTERNAL_CRM_TRACKING, RECORD_INTERACTION, SETTINGS_INTERACTION } from './constants/eventNames';
import { fromJS } from 'immutable';
import { isCustomObject } from './utils/isCustomObject';
const SCREENS = fromJS({
  [RECORD_INTERACTION]: {
    CONTACT: 'Contact',
    COMPANY: 'Company',
    DEAL: 'Deal',
    QUOTE: 'Quote',
    TICKET: 'Ticket',
    TASK: 'Tasks',
    LEAD: 'Lead'
  },
  [INDEX_INTERACTION]: {
    CONTACT: 'Contact index',
    COMPANY: 'Company index',
    DEAL: 'Deal Index',
    TICKET: 'Ticket index',
    TASK: 'Tasks',
    VISIT: 'Visits'
  },
  [INTERNAL_CRM_TRACKING]: {
    CONTACT: 'Contacts',
    COMPANY: 'Companies',
    DEAL: 'Deals',
    TASK: 'Tasks',
    VISIT: 'Visits'
  },
  [SETTINGS_INTERACTION]: {
    CONTACT: 'Contact',
    COMPANY: 'Company',
    DEAL: 'Deal'
  },
  [CRM_ONBOARDING]: {
    CONTACT: 'Contact',
    COMPANY: 'Company',
    DEAL: 'Deal',
    QUOTE: 'Quote',
    TASK: 'Tasks',
    SETTINGS: 'Settings'
  },
  [CRM_DEBUG]: {
    CONTACT: 'Contact',
    COMPANY: 'Company',
    DEAL: 'Deal',
    TASK: 'Tasks'
  },
  [BIZOPS_INTERACTION]: {
    CONTACT: 'Contact',
    COMPANY: 'Company',
    DEAL: 'Deal'
  }
});
const defaultProps = {
  app: 'CRM'
};
const _isValidConfig = (event, objectType) => SCREENS.hasIn([event, objectType]) || isCustomObject(objectType);
export function includeScreen({
  event,
  objectType,
  eventProps
}) {
  if (!_isValidConfig(event, objectType)) {
    console.warn('Invalid event or objectType sent to MakeLogEventProps: ', event, objectType);
    return undefined;
  }
  const screen = isCustomObject(objectType) ? 'custom' : SCREENS.getIn([event, objectType]);
  return {
    event,
    objectType,
    eventProps: Object.assign({
      screen
    }, defaultProps, eventProps)
  };
}