import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["businessUnitsInfo"],
  _excluded2 = ["businessUnitsInfo"];
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import http from 'hub-http/clients/apiClient';
import CampaignRecord from '../data/CampaignRecord';
import { getCrmSearchCampaignOptions, mapCrmSearchCampaignsToLegacy } from '../utils/campaignDaoUtils';
import { mapCrmSearchCampaignsToLegacyFull, mapCrmSearchCampaignToCampaignSelectOptions } from '../utils/campaignDaoUtilsWithDLB';
import { BASE_URL, CRM_SEARCH_CAMPAIGNS_URL, CAMPAIGN_DEFAULTS, INCLUDE_CAMPAIGN_STEPS_PARAM } from '../constants/campaignDaoConstants';
import { campaignFields } from '../constants/campaignFieldsForEditing';
export function createCampaign(campaign, userId) {
  // BE doesn't support sending this property as part of the request body.
  // Only as a query param
  const _campaignFields$BUSIN = campaignFields.BUSINESS_UNITS_INFO,
    {
      [_campaignFields$BUSIN]: businessUnitId
    } = campaign,
    campaignWithoutBUInfo = _objectWithoutPropertiesLoose(campaign, [_campaignFields$BUSIN].map(_toPropertyKey));
  return http.post(`${BASE_URL}`, {
    query: {
      businessUnitId
    },
    data: Object.assign({}, CAMPAIGN_DEFAULTS, campaignWithoutBUInfo, {
      createdBy: userId
    })
  });
}
export function deleteCampaign(campaign) {
  return http.delete(`${BASE_URL}/${campaign.guid}`);
}
export function updateCampaign(campaign, query) {
  return http.put(`${BASE_URL}/${campaign.guid}`, {
    query,
    data: campaign.toPayload()
  });
}
export const getCrmSearchCampaignsPaginatedRaw = options => {
  return http.post(CRM_SEARCH_CAMPAIGNS_URL, {
    data: getCrmSearchCampaignOptions({
      offset: options.offset,
      limit: options.limit,
      nameSearch: options.name,
      sortProperty: options.sort,
      sortDir: options.sortDir,
      properties: options.properties,
      customFilters: options.customFilters
    })
  });
};
export let getCrmSearchCampaignsPaginated = options => {
  return getCrmSearchCampaignsPaginatedRaw(options).then(mapCrmSearchCampaignsToLegacy);
};
export const getCrmSearchCampaignsPaginatedFull = options => {
  return getCrmSearchCampaignsPaginatedRaw(options).then(mapCrmSearchCampaignsToLegacyFull);
};
export const getCrmSearchCampaignsPaginatedAsOption = options => {
  return getCrmSearchCampaignsPaginatedRaw(options).then(mapCrmSearchCampaignToCampaignSelectOptions);
};
export const getUnformattedCampaignLegacy = (campaignId, includeSteps) => {
  const includeStepsParam = includeSteps ? INCLUDE_CAMPAIGN_STEPS_PARAM : '';
  return http.get(`${BASE_URL}/${campaignId}${includeStepsParam}`);
};
export const getCampaignLegacy = (campaignId, includeSteps) => {
  return getUnformattedCampaignLegacy(campaignId, includeSteps).then(campaign => CampaignRecord.from(campaign));
};

/**
 * Fetches a Campaign from the `campaigns/v1/campaigns` endpoint. This endpoint
 * will return 403 if the Campaign exists but the user does not have permissions
 * to view it.
 * @function
 * @param {string} campaignGuid - The campaignGuid to be fetched.
 * @param {function} [legacyGetCampaignFetcher=getCampaignLegacy] - The function that fetches `campaigns/v1/campaigns`.
 * @param {boolean} [includeSteps=false] - Whether the request should include the campaign's steps
 * @param {function} [crmGetCampaignFetcher=getCrmSearchCampaign] - The function that fetches `crm-search/search`.
 * @returns {Promise} - Promise with an instance of "CampaignRecord" if response is resolved
 */
export const getCampaign = (campaignGuid, legacyGetCampaignFetcher = getCampaignLegacy, includeSteps = false, crmGetCampaignFetcher = getUnformattedCrmSearchCampaign) => {
  return Promise.all([legacyGetCampaignFetcher(campaignGuid, includeSteps), getCrmSearchCampaign(campaignGuid, crmGetCampaignFetcher)]).then(([legacyResult, crmResult]) => {
    let campaign = legacyResult;
    if (!(campaign instanceof CampaignRecord)) {
      campaign = CampaignRecord.from(legacyResult);
    }
    if (crmResult && crmResult.results && crmResult.results.length > 0) {
      const {
        budgetTotalAmount,
        objectId,
        spendTotalAmount
      } = crmResult.results[0];
      return campaign.merge({
        budgetTotalAmount,
        objectId,
        spendTotalAmount
      });
    }
    return campaign;
  });
};
export function getUnformattedCrmSearchCampaign(campaignId, requestOptions) {
  return http.post(CRM_SEARCH_CAMPAIGNS_URL, {
    data: getCrmSearchCampaignOptions({
      offset: 0,
      limit: Array.isArray(campaignId) ? campaignId.length : 1,
      guidValue: campaignId,
      requestOptions
    })
  });
}
export function getCrmSearchCampaign(campaignId, crmGetCampaignFetcher = getUnformattedCrmSearchCampaign) {
  return crmGetCampaignFetcher(campaignId).then(mapCrmSearchCampaignsToLegacy);
}

// @deprecated
export function cloneCampaign(campaign, _ref = {}) {
  let {
      businessUnitsInfo = 0
    } = _ref,
    campaignProps = _objectWithoutPropertiesLoose(_ref, _excluded);
  return http.post(`${BASE_URL}/${campaign.guid}/clone`, {
    data: Object.assign({}, campaignProps, {
      businessUnitsInfo: {
        selectedBusinessUnits: [businessUnitsInfo]
      }
    })
  }).then(response => {
    return getCampaign(response.campaignId).then(c => ({
      campaign: c,
      progressionId: response.progressionId
    }));
  });
}
export function cloneCampaignV2(campaign, _ref2 = {}) {
  let {
      businessUnitsInfo = 0
    } = _ref2,
    campaignProps = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  return http.post(`${BASE_URL}/${campaign.guid}/clone/v2`, {
    data: Object.assign({}, campaignProps, {
      businessUnitsInfo: {
        selectedBusinessUnits: [businessUnitsInfo]
      }
    })
  }).then(response => {
    return getCampaign(response.campaignId).then(c => ({
      campaign: c,
      progressionId: response.progressionId
    }));
  });
}
export function getCampaignUtms(campaignId) {
  return http.get(`${BASE_URL}/${campaignId}/utms`);
}
export let fetchCampaignsBatch = (guid = []) => {
  return http.get(`${BASE_URL}/guids`, {
    query: {
      guid
    }
  });
};
export function getCrmSearchCampaignAsCampaignSelectOption(campaignGuid) {
  return getCrmSearchCampaign(campaignGuid).then(({
    results
  }) => results.map(currentValueResponse => Object.assign({}, currentValueResponse, {
    colorHex: currentValueResponse.colorHex,
    text: currentValueResponse.display_name,
    value: currentValueResponse.guid || undefined,
    utm: currentValueResponse.utm || null
  })));
}
export function fetchIsCampaignAtLimit({
  assetObjectType,
  campaignGuid,
  numOfObjectsToAssociate = 1
}) {
  return http.get('campaigns/associations/assets/v2/is-limit-reached', {
    query: {
      campaignGuid,
      assetObjectType,
      numOfObjectsToAssociate
    }
  });
}
export const setFetchCampaignsBatchForTesting = f => {
  // @ts-expect-error this function is only used for testing and it fails
  // to match the real function's types
  fetchCampaignsBatch = f;
};
export const setGetCrmSearchCampaignsPaginatedForTesting = f => {
  // @ts-expect-error this function is only used for testing and it fails
  // to match the real function's types
  getCrmSearchCampaignsPaginated = f;
};