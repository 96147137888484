import makeGlobalStateHook from 'messaging-management-lib/data/makeGlobalStateHook';
import { useCallback, useMemo } from 'react';
let useSelectedOptionState = makeGlobalStateHook();
export default function useEmailSelectedOptionState() {
  const [state, setState] = useSelectedOptionState(null);
  const setSelectedOption = useCallback(option => {
    setState(option);
  }, [setState]);
  return useMemo(() => ({
    selectedOption: state,
    setSelectedOption
  }), [state, setSelectedOption]);
}
// This is for use within tests
export function resetEmailSelectedOptionState() {
  useSelectedOptionState = makeGlobalStateHook();
}