import { gate } from 'hub-http/gates';
import { useHasGate } from '../../userInfo/hooks/useHasGate';
import { useHasScope } from '../../userInfo/hooks/useHasScope';
export const useCanUserWriteViews = () => {
  const {
    loading: scopeLoading,
    data: hasWriteViewsScope
  } = useHasScope('customize-crm-owned-views-access');
  const {
    loading: gateLoading,
    data: isUngatedForEnforcement
  } = useHasGate(gate('CRM:Index:EnforceViewOnlyPermissions'));
  if (scopeLoading || gateLoading) {
    return false;
  }
  return hasWriteViewsScope || !isUngatedForEnforcement;
};