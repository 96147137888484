import { useCallback, useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import { useListing } from 'framework-listing-lib/internal/providers/ListingProvider';
import { useIsFoldersMode } from 'framework-listing-lib/internal/hooks/useUIMode';
import listingLibObserver from 'framework-listing-lib/utils/listingLibObserver';
import { useViews as useViewsProvider } from 'framework-listing-lib/internal/ViewTabs/providers/ViewsProvider';
import { useListingLibData } from 'framework-listing-lib/internal/providers/DataProvider';
import useCrmUI from 'framework-listing-lib/internal/hooks/useCrmUI';
import useUserInfo from 'framework-listing-lib/internal/hooks/useUserInfo';
import useObjectTypeId from 'framework-listing-lib/internal/hooks/useObjectTypeId';
import { findViewById, getViewId } from 'framework-listing-lib/internal/ViewTabs/utils/views';
const DEFAULT_SAVE_AS_NEW_PROPS = {
  disabled: false
};

/**
 * Find current view id based on props.
 *  1. @viewProps.currentViewId is priority given it has been sent from the outside.
 *  2. @pinnedViews comes next as we read which views have been pinned via useFetchViews hook.
 *  3. In case there are no pinned views but there are views, we use the first view id in the array.
 *  4. If none of the above is met, we return undefined.
 */
function getCurrentViewId(pinnedViews, views, currentViewId) {
  if (pinnedViews.length === 0 && views.length === 0) {
    return undefined;
  }
  const maybeView = currentViewId ? findViewById(views, currentViewId) : undefined;
  if (currentViewId && maybeView) {
    return getViewId(maybeView);
  }
  if (currentViewId) {
    // There are two main ways we might have a currentViewId without it being found in views:
    //  1. The currentViewId is invalid (sent in from consumer; doesn't correspond to any views)
    //  2. The currentViewId is from a view that was recently created and isn't in our cache
    // If it's case 1, then ideally we would redirct to the first pinnedView, or first view
    // But with the PinnedViewsBar, case 2 is more likely to happen because of a race condition between
    // setting the new currentViewId and updating the GET_VIEWS cache. In that case we want to avoid
    // redirecting to the first pinnedView prematurely.
    // Also PinnedViewsBar is mostly able to handle a currentViewId not yet in their cache,
    // they'll automatically add it to their list of pinned view ids https://git.hubteam.com/HubSpot/customer-data-views-management/blob/f806a18699f31a9454c8a72d1fbe822a6c965bdd/customer-data-views-management/static-1.15807/js/pinnedViews/hooks/useAutoPinCurrentView.tsx#L72

    return currentViewId;
  }
  if (pinnedViews.length) {
    return getViewId(pinnedViews[0]);
  }
  if (views.length) {
    return getViewId(views[0]);
  }
  return undefined;
}
export function useViewProps() {
  const {
    viewProps
  } = useListing();
  return viewProps;
}
export function useHasViews() {
  const viewProps = useViewProps();
  return useMemo(() => Boolean(viewProps && viewProps.hasViews), [viewProps]);
}
export function useOnViewIdChange() {
  const viewProps = useViewProps();
  return useCallback((viewId, options) => {
    if (viewProps && viewProps.onViewIdChange) {
      viewProps.onViewIdChange(viewId, options);
    }
    listingLibObserver.setBulkActionSelectionMode('none');
  }, [viewProps]);
}
export function useViewsNamespace() {
  const viewProps = useViewProps();
  return useMemo(() => viewProps && viewProps.namespace ? viewProps.namespace : 'NONE', [viewProps]);
}
export function useDefaultQuickFetchViewId() {
  const viewProps = useViewProps();
  return useMemo(() => viewProps && viewProps.defaultQuickFetchViewId ? viewProps.defaultQuickFetchViewId : undefined, [viewProps]);
}
export function useGetSaveAsNewViewProps() {
  const viewProps = useViewProps();
  const currentView = useCurrentView();
  return useMemo(() => {
    if (!currentView || !viewProps || !viewProps.getSaveAsNewViewProps) {
      return DEFAULT_SAVE_AS_NEW_PROPS;
    }
    return viewProps.getSaveAsNewViewProps(currentView);
  }, [currentView, viewProps]);
}
export function useRenderTab() {
  const viewProps = useViewProps();
  return useMemo(() => viewProps && viewProps.renderTab ? viewProps.renderTab : undefined, [viewProps]);
}
export function useFiltersForNewViews() {
  const viewProps = useViewProps();
  return useMemo(() => Object.assign({
    filterGroups: [],
    quickFilters: []
  }, viewProps && viewProps.filtersForNewViews ? viewProps.filtersForNewViews || {} : {}), [viewProps]);
}
export function usePinnedViews() {
  const {
    pinnedViews
  } = useListingLibData();
  return pinnedViews;
}
export function useViews() {
  const {
    views
  } = useListingLibData();
  return views;
}
export function useViewPropsCurrentViewId() {
  const viewProps = useViewProps();
  return useMemo(() => viewProps && viewProps.currentViewId ? viewProps.currentViewId : undefined, [viewProps]);
}
export function useCurrentViewId() {
  const isFoldersMode = useIsFoldersMode();
  const viewProps = useViewProps();
  const pinnedViews = usePinnedViews();
  const views = useViews();
  return useMemo(() => {
    if (isFoldersMode) {
      return undefined;
    }
    if (views.length === 0 && pinnedViews.length === 0) {
      return undefined;
    }
    return getCurrentViewId(pinnedViews, views, viewProps !== null && viewProps !== void 0 && viewProps.currentViewId ? String(viewProps.currentViewId) : undefined);
  }, [isFoldersMode, pinnedViews, viewProps, views]);
}
export function useCurrentView() {
  const views = useViews();
  const currentViewId = useCurrentViewId();
  return useMemo(() => {
    if (!currentViewId || views.length === 0) {
      return undefined;
    }
    return findViewById(views, currentViewId);
  }, [currentViewId, views]);
}

/**
 * Id of the first view to be shown in the UI.
 * Can be a pinned or custom view.
 */
export function useDefaultViewId() {
  const pinnedViews = usePinnedViews();
  return useMemo(() => pinnedViews.length ? String(pinnedViews[0].id) : undefined, [pinnedViews]);
}
export function useIsCurrentViewReadOnly() {
  const currentView = useCurrentView();
  const {
    userInfo
  } = useUserInfo();
  return Boolean(userInfo && currentView && userInfo.user.user_id !== currentView.ownerId);
}
export function useCurrentViewFilters() {
  const currentView = useCurrentView();
  return useMemo(() => {
    if (currentView) {
      return {
        filterGroups: currentView.filterGroups,
        quickFilters: currentView.quickFilters || []
      };
    }
    return {
      filterGroups: [],
      quickFilters: []
    };
  }, [currentView]);
}
export function useViewUpdateState() {
  const {
    viewUpdateState,
    setViewUpdateState
  } = useViewsProvider();
  return [viewUpdateState, setViewUpdateState];
}
export function useViewColumns() {
  const {
    columns
  } = useViewsProvider();
  return columns;
}
export function useSetViewColumns() {
  const {
    setColumns
  } = useViewsProvider();
  return setColumns;
}
export function useHasFrozenColumns() {
  const crmUI = useCrmUI();
  return crmUI;
}
export function useNumberOfFrozenColumns() {
  const hasFrozenColumns = useHasFrozenColumns();
  const {
    numberOfFrozenColumns
  } = useViewsProvider();
  return useMemo(() => {
    if (!hasFrozenColumns) {
      return 0;
    }
    return numberOfFrozenColumns;
  }, [hasFrozenColumns, numberOfFrozenColumns]);
}
export function useSetNumberOfFrozenColumns() {
  const {
    setNumberOfFrozenColumns
  } = useViewsProvider();
  return setNumberOfFrozenColumns;
}
export function useSetCardProperties() {
  const {
    setCardProperties
  } = useViewsProvider();
  return setCardProperties;
}
export function useCardProperties() {
  const {
    cardProperties
  } = useViewsProvider();
  return cardProperties;
}
export function useGetAllViewsUrl() {
  const viewProps = useViewProps();
  const objectType = useObjectTypeId();
  const {
    userInfo
  } = useUserInfo();
  const namespace = useViewsNamespace();
  const urlSearchParams = useMemo(() => new URLSearchParams(), []);
  urlSearchParams.append('namespace', namespace);
  urlSearchParams.append('objectTypeId', objectType);
  urlSearchParams.append('returnLink', `${window.location.pathname}${window.location.search}`);
  if (userInfo !== null && userInfo !== void 0 && userInfo.user) {
    urlSearchParams.append('ownerId', `${userInfo.user.user_id}`);
  }
  return useMemo(() => viewProps && viewProps.allViewsUrl ? `${viewProps.allViewsUrl}?${urlSearchParams.toString()}` : `/manage-views/${PortalIdParser.get()}/all?${urlSearchParams.toString()}`, [urlSearchParams, viewProps]);
}