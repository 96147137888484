export class ChirpInternalError extends Error {}
export class ChirpError extends Error {}
export function getAvroGateway(auth) {
  return `/chirp-frontend-${auth}/v1/gateway`;
}

/**
 * @deprecated This was used only from code generation which has been removed. This will be removed when usage moves to 0
 */

/**
 * @deprecated This was used only from code generation which has been removed. This will be removed when usage moves to 0
 */

/**
 * @deprecated This was used only from code generation which has been removed. This will be removed when usage moves to 0
 */

/**
 *
 * Represents an object property marked as `@Default` in Java.
 * These properties are always present on responses, but are optional on requests (empty values will fall back to the default specified on the model).
 * To be able to assign a value to this field, wrap the type in Requestify
 */

export function parseResultUnion(rawResponse) {
  const response = rawResponse;
  if (response.type === 'data') {
    return response.data;
  } else if (response.type === 'rpcError') {
    throw new ChirpError('CHIRP RPC failed with a known error', {
      cause: response.rpcError
    });
  } else {
    throw new ChirpInternalError('CHIRP RPC failed with a CHIRP internal error', {
      cause: response.internalError
    });
  }
}
export function makeChirpAvroCall({
  hubHttpClient,
  details,
  inputs,
  alternateData
}) {
  const {
    request
  } = inputs || {
    request: {}
  };
  const {
    auth,
    serviceName,
    rpcName,
    fingerprint
  } = details;
  const syncStackError = new Error(`CHIRP RPC failed for ${rpcName}`);
  const data = alternateData !== null && alternateData !== void 0 ? alternateData : hubHttpClient.post(`${getAvroGateway(auth)}/${serviceName}/${rpcName}`, {
    data: request,
    headers: {
      'x-hs-fingerprint': fingerprint
    }
  });
  return data.then(response => parseResultUnion(response)).catch(error => {
    syncStackError.cause = error;
    throw syncStackError;
  });
}