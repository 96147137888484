import { getHasCreatedEmailsEarlyRequest } from 'email-management-lib/EarlyRequest/utils';
import { gate } from 'hub-http/gates';
import makeGlobalStateHook from 'messaging-management-lib/data/makeGlobalStateHook';
import useHasAllGates from 'messaging-management-lib/user/hooks/useHasAllGates';
import { useEffect } from 'react';
const useHasCreatedEmailsRequest = new Promise((resolve, reject) => {
  var _getHasCreatedEmailsE, _getHasCreatedEmailsE2;
  (_getHasCreatedEmailsE = getHasCreatedEmailsEarlyRequest()) === null || _getHasCreatedEmailsE === void 0 || _getHasCreatedEmailsE.onError(error => reject(error));
  (_getHasCreatedEmailsE2 = getHasCreatedEmailsEarlyRequest()) === null || _getHasCreatedEmailsE2 === void 0 || _getHasCreatedEmailsE2.whenFinished(response => resolve(response.totalCount > 0));
});
const useRequestState = makeGlobalStateHook();
export default function useEmptyState() {
  const [requestState, setRequestState] = useRequestState({
    hasCreatedEmails: false,
    isLoading: true
  });
  const hasAllGates = useHasAllGates();
  const isUngated = hasAllGates(gate('MM:EmailEmptyState'));
  useEffect(() => {
    useHasCreatedEmailsRequest.then(result => setRequestState({
      hasCreatedEmails: result,
      isLoading: false
    })).catch(() => setRequestState({
      hasCreatedEmails: true,
      isLoading: false
    }));
  }, [setRequestState]);
  return {
    loading: requestState.isLoading,
    shouldRender: !requestState.hasCreatedEmails,
    isUngated
  };
}