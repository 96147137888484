module.exports = {
  "pageLoad": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "newPostSend": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": "string",
      "subscreen2": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "analyzeInteraction": {
    "name": "analyze-interaction",
    "class": "interaction",
    "properties": {
      "secondaryMetric": {
        "type": "string",
        "isOptional": true
      },
      "businessUnitId": {
        "type": "string",
        "isOptional": true
      },
      "rangeType": {
        "type": "string",
        "isOptional": true
      },
      "size": {
        "type": "string",
        "isOptional": true
      },
      "altered": {
        "type": "string",
        "isOptional": true
      },
      "chartType": {
        "type": "string",
        "isOptional": true
      },
      "tableSearch": {
        "type": "string",
        "isOptional": true
      },
      "campaign": {
        "type": "string",
        "isOptional": true
      },
      "startDate": {
        "type": "string",
        "isOptional": true
      },
      "tableType": {
        "type": "string",
        "isOptional": true
      },
      "endDate": {
        "type": "string",
        "isOptional": true
      },
      "frequency": {
        "type": "string",
        "isOptional": true
      },
      "metric": {
        "type": "string",
        "isOptional": true
      },
      "emailType": {
        "type": "string",
        "isOptional": true
      },
      "stacked": {
        "type": "string",
        "isOptional": true
      },
      "property": {
        "type": "string",
        "isOptional": true
      },
      "component": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "isVNext": {
        "type": "boolean",
        "isOptional": true
      },
      "columns": {
        "type": "array",
        "isOptional": true
      },
      "team": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": "string",
      "subscreen2": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "dashboardInteraction": {
    "name": "dashboard-interaction",
    "class": "interaction",
    "properties": {
      "emptyStateInteraction": {
        "type": "string",
        "isOptional": true
      },
      "sorting": {
        "type": "string",
        "isOptional": true
      },
      "hasCloneFromAccountToBusinessUnit": {
        "type": "string",
        "isOptional": true
      },
      "hasCloneFromBusinessUnitToBusinessUnit": {
        "type": "string",
        "isOptional": true
      },
      "filterType": {
        "type": "string",
        "isOptional": true
      },
      "actionType": {
        "type": "string",
        "isOptional": true
      },
      "hasCloneFromBusinessUnitToAccount": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "newPostSend": {
        "type": "string",
        "isOptional": true
      },
      "linkClicked": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      },
      "otherActions": {
        "type": "string",
        "isOptional": true
      },
      "lockedScreenType": {
        "type": [
          "asset",
          "tool"
        ],
        "isOptional": true
      },
      "clickMoreTools": {
        "type": "string",
        "isOptional": true
      },
      "filtering": {
        "type": "string",
        "isOptional": true
      },
      "viewSection": {
        "type": "string",
        "isOptional": true
      },
      "dashboardColumn": {
        "type": "string",
        "isOptional": true
      },
      "dashboardColumnLength": {
        "type": "number",
        "isOptional": true
      },
      "dashboardOnboardingCompletedStepsV2": {
        "type": "array",
        "isOptional": true
      },
      "dashboardOnboardingStartActionV2": {
        "type": "string",
        "isOptional": true
      },
      "dashboardOnboardingMigrationAction": {
        "type": "string",
        "isOptional": true
      },
      "onboardingChecklistAction": {
        "type": "string",
        "isOptional": true
      },
      "onboardingTourAction": {
        "type": "string",
        "isOptional": true
      },
      "onboardingChecklistCompletedSteps": {
        "type": "array",
        "isOptional": true
      },
      "pqlAction": {
        "type": "string",
        "isOptional": true
      },
      "willTriggerOnboarding": {
        "type": "boolean",
        "isOptional": true
      },
      "assetAccessAction": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "compareToolInteraction": {
    "name": "compare-tool-interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "selectedEmailsCount": {
        "type": "number",
        "isOptional": true
      },
      "linkClicked": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "frameworkTableInteraction": {
    "name": "framework-table-interaction",
    "class": "interaction",
    "properties": {
      "filtering": {
        "type": "string",
        "isOptional": true
      },
      "otherActions": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "postSendInteraction": {
    "name": "post-send-interaction",
    "class": "interaction",
    "properties": {
      "hasBotFilteringEnabled": {
        "type": "string",
        "isOptional": true
      },
      "campaign_send_id": {
        "type": "string",
        "isOptional": true
      },
      "context": {
        "type": "string",
        "isOptional": true
      },
      "is_tldr_helpful": {
        "type": "string",
        "isOptional": true
      },
      "campaign_id": {
        "type": "string",
        "isOptional": true
      },
      "level": {
        "type": "string",
        "isOptional": true
      },
      "base_type": {
        "type": "string",
        "isOptional": true
      },
      "dismiss_type": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      },
      "newPostSend": "string",
      "otherAction": {
        "type": "string",
        "isOptional": true
      },
      "performanceInteraction": {
        "type": "string",
        "isOptional": true
      },
      "recipientInteraction": {
        "type": "string",
        "isOptional": true
      },
      "testResultInteraction": {
        "type": "string",
        "isOptional": true
      },
      "emailType": {
        "type": "string",
        "isOptional": true
      },
      "emailContext": {
        "type": "string",
        "isOptional": true
      },
      "newDashPageLoad": {
        "type": "string",
        "isOptional": true
      },
      "viewSection": {
        "type": "string",
        "isOptional": true
      },
      "tldrPqlAction": {
        "type": "string",
        "isOptional": true
      },
      "tldrPqlType": {
        "type": "string",
        "isOptional": true
      },
      "isPublished": {
        "type": "boolean",
        "isOptional": true
      },
      "isScheduled": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "testSendInteraction": {
    "name": "test send interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      }
    },
    "namespace": "email"
  },
  "tldrInteraction": {
    "name": "post-send-interaction",
    "class": "interaction",
    "properties": {
      "hasBotFilteringEnabled": {
        "type": "string",
        "isOptional": true
      },
      "campaignSendId": {
        "type": "string",
        "isOptional": true
      },
      "context": {
        "type": "string",
        "isOptional": true
      },
      "isTldrHelpful": {
        "type": "string",
        "isOptional": true
      },
      "campaignId": {
        "type": "string",
        "isOptional": true
      },
      "LEVEL": {
        "type": "string",
        "isOptional": true
      },
      "baseType": {
        "type": "string",
        "isOptional": true
      },
      "dismissType": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      },
      "newPostSend": "string",
      "tldrShown": {
        "type": "string",
        "isOptional": true
      },
      "isInline": {
        "type": "boolean",
        "isOptional": true
      },
      "updatedDesign": {
        "type": "boolean",
        "isOptional": true
      },
      "BASE": {
        "type": "string",
        "isOptional": true
      },
      "TYPE": {
        "type": "string",
        "isOptional": true
      },
      "tldrCampaignId": {
        "type": "number",
        "isOptional": true
      },
      "tldrAction": {
        "type": "string",
        "isOptional": true
      },
      "interactionWith": {
        "type": [
          "article",
          "action",
          "hover"
        ],
        "isOptional": true
      },
      "interactionSource": {
        "type": "string",
        "isOptional": true
      },
      "unengagedToggleValue": {
        "type": "boolean",
        "isOptional": true
      },
      "isHelpful": {
        "type": "boolean",
        "isOptional": true
      },
      "fromNotification": {
        "type": "boolean"
      }
    },
    "namespace": "email"
  },
  "remediationInteraction": {
    "name": "portal-remediation-interaction",
    "class": "interaction",
    "properties": {
      "shown": {
        "type": "string",
        "isOptional": true
      },
      "isTldrHelpful": {
        "type": "string",
        "isOptional": true
      },
      "surveyType": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": false
      },
      "state": {
        "type": "string",
        "isOptional": false
      },
      "reason": {
        "type": "string",
        "isOptional": false
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "integrationsInteraction": {
    "namespace": "integrations",
    "name": "email-interaction",
    "class": "interaction",
    "properties": {
      "applicationId": "number",
      "applicationName": "string",
      "action": [
        "OPEN_SHARE_SLACK",
        "SEND_TO_SLACK"
      ],
      "succeeded": {
        "type": "boolean",
        "isOptional": true
      }
    }
  },
  "contentEditorAutomationInteraction": {
    "name": "contextual-automation interaction",
    "class": "interaction",
    "namespace": "contextual-automation",
    "properties": {
      "sourceApp": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "event": {
        "type": "string"
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "exitPage": {
    "name": "ANALYTICS_INTERACTION",
    "class": "interaction",
    "namespace": "email"
  },
  "probationBannerInteraction": {
    "name": "probation-banner-interaction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": [
          "LINK_CLICK",
          "CTA_CLICK",
          "COMPONENT_VIEW"
        ]
      },
      "overallState": {
        "type": [
          "PROBATION_1",
          "PROBATION_2",
          "PROBATION_3"
        ]
      },
      "emailTab": {
        "type": [
          "MANAGE",
          "ANALYZE"
        ]
      },
      "component": {
        "type": [
          "LowRiskProbationBanner",
          "MidRiskProbationBanner",
          "PostReviewMidRiskProbationBanner"
        ]
      },
      "reason": {
        "type": [
          "BOUNCES",
          "UNSUBSCRIBES",
          "SPAM",
          "CANCELLATIONS",
          "OTHER"
        ]
      },
      "url": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "clickCreateEmail": {
    "name": "click-create-email",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen2": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "email"
  },
  "messagingPublishingInteraction": {
    "name": "messaging publishing lib interaction",
    "class": "interaction",
    "properties": {
      "applicationName": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "otherAction": {
        "type": "string",
        "isOptional": true
      },
      "emailType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "messaging-publishing-lib",
    "version": "1"
  },
  "insightsInteraction": {
    "name": "messaging publishing lib interaction",
    "class": "interaction",
    "properties": {
      "applicationName": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "insightsTab": {
        "type": [
          "TO_IMPROVE",
          "DOING_GOOD"
        ],
        "isOptional": true
      },
      "otherAction": {
        "type": "string",
        "isOptional": true
      },
      "insightType": {
        "type": [
          "GOOD",
          "BAD"
        ],
        "isOptional": true
      },
      "emailType": {
        "type": "string",
        "isOptional": true
      },
      "insightId": {
        "type": "number",
        "isOptional": true
      },
      "insightName": {
        "type": "string",
        "isOptional": true
      },
      "insightContext": {
        "type": "string",
        "isOptional": true
      },
      "insightFeedbackSentiment": {
        "type": [
          "HELPFUL",
          "NOT_HELPFUL"
        ],
        "isOptional": true
      },
      "insightFeedbackCategory": {
        "type": "string",
        "isOptional": true
      },
      "insightFeedbackCategories": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "messaging-publishing-lib",
    "version": "1"
  },
  "contentLabelLibInteraction": {
    "name": "content label lib interaction",
    "class": "interaction",
    "properties": {
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": [
          "warning-tag-loaded",
          "open-close-modal-click",
          "info-modal-loaded",
          "details-banner-loaded"
        ]
      }
    },
    "namespace": "content-label-lib"
  },
  "contentLabelLibInteractionPages": {
    "name": "content label lib interaction",
    "class": "interaction",
    "properties": {
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": [
          "warning-tag-loaded",
          "open-close-modal-click",
          "info-modal-loaded",
          "details-banner-loaded"
        ]
      }
    },
    "namespace": "content-label-lib"
  },
  "blogQuickCreateModalOpened": {
    "name": "blog quick create modal action",
    "class": "interaction",
    "namespace": "content-components"
  },
  "blogQuickCreateModalClickedCreate": {
    "name": "blog quick create modal action",
    "class": "interaction",
    "namespace": "content-components"
  },
  "audienceAccessInteraction": {
    "name": "audience access interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click-radio-all",
          "click-radio-password",
          "click-radio-sso",
          "click-radio-specificContacts",
          "click-radio-ssoWithSpecificContacts",
          "show-password-help",
          "show-sso-help",
          "show-specificContacts-help",
          "show-ssoWithSpecificContacts-help",
          "change-lists",
          "click-kb-article",
          "show-sso-disabled-tooltip",
          "show-registration-disabled-tooltip"
        ]
      }
    },
    "namespace": "content-components"
  },
  "partnerMatchingAppInteraction": {
    "name": "partner matching app interaction",
    "namespace": "partner-matching-app",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "app": {
        "type": "string"
      }
    }
  },
  "colorImportInteraction": {
    "name": "color import modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "submit-url",
          "website-colors-retrieved",
          "toggle-color-selection",
          "import-colors"
        ]
      }
    },
    "namespace": "content-components"
  },
  "setupCustomDomainModalInteraction": {
    "name": "setup custom domain modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "view",
          "clicked-add-custom-domain-button",
          "clicked-close-modal",
          "clicked-remind-me-tomorrow-button",
          "clicked-do-not-show-me-again-button"
        ]
      },
      "screen": [
        "detail"
      ]
    },
    "namespace": "content-components"
  },
  "postPublishContentRemixModalInteraction": {
    "name": "ContentComponents interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "view-modal",
          "close-modal-temporary",
          "close-modal-permanent",
          "click-remix"
        ]
      },
      "contentId": {
        "type": "number"
      }
    },
    "namespace": "content-components"
  },
  "ContentComponentsInteraction": {
    "name": "ContentComponents interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "custom-fonts-upload-modal-opened",
          "custom-fonts-upload-modal-closed",
          "custom-fonts-create-font",
          "custom-fonts-edit-font",
          "custom-fonts-delete-font"
        ]
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionUsage": {
    "name": "content-create-autotranslation-action",
    "class": "usage",
    "properties": {
      "action": [
        "create-multi-language-variation",
        "direct-translate"
      ],
      "status": [
        "success",
        "fail"
      ],
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionActivation": {
    "name": "content-create-autotranslation-action",
    "class": "activation",
    "properties": {
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionInteraction": {
    "name": "content-create-autotranslation-action",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-submit-create-multi-language-variation",
        "clicked-ai-translate-toggle"
      ],
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "isAutoTranslateEnabled": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  }
};