import http from 'hub-http/clients/apiClient';
import { checkout as createCheckoutUrl } from 'self-service-api/core/utilities/links';
import { createRpcClientV2 } from 'rpc-client-utils';
import { createPurchaseOrderRpc } from '../../chirp/monetization/rpc/PurchaseChirpService';
import { trackPurchaseOrderGenerated, trackPurchaseOrderGenerationFailed } from './buy-now-trackers';
import logPurchaseOrderSentry from '../../_core/common/reliability/logCheckoutError';
const rpcClient = createRpcClientV2({
  hubHttpClient: http
});
export const generateCheckoutUrl = (apiNames, buyNowUsageTrackingProperties) => {
  const requestedProductInput = apiNames.map(apiName => {
    return {
      productApiName: apiName,
      quantity: null,
      quantityPacks: []
    };
  });
  return rpcClient.call(createPurchaseOrderRpc, {
    request: {
      commitmentTerm: 'ANNUAL',
      paymentTerm: 'ANNUAL',
      products: requestedProductInput
    }
  }).then(purchaseOrderResponse => {
    let upgradeSource;
    if (buyNowUsageTrackingProperties) {
      trackPurchaseOrderGenerated(buyNowUsageTrackingProperties);
      upgradeSource = buyNowUsageTrackingProperties.upgradeSource;
    }
    return createCheckoutUrl(
    // @ts-expect-error checkout expects the id as a string, but the api
    // returns number
    purchaseOrderResponse.purchaseOrderId, upgradeSource);
  }, error => {
    console.error(error);
    let sentryDetails = {
      apiNames,
      buttonName: 'UIBuyNowButton'
    };
    if (buyNowUsageTrackingProperties) {
      trackPurchaseOrderGenerationFailed(buyNowUsageTrackingProperties);
      sentryDetails = Object.assign({}, sentryDetails, buyNowUsageTrackingProperties);
    }
    logPurchaseOrderSentry('generatePurchaseOrder', error, sentryDetails);
    throw error;
  });
};