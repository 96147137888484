import { registerQuery } from 'data-fetching-client';
import { HubsSettingClient } from 'frontend-preferences-client';
export const hubSettingsClient = HubsSettingClient.forCaller('messaging-management-lib');
export const GET_PORTAL_SETTING = registerQuery({
  fieldName: 'portalSetting',
  args: ['settingName', 'defaultValue'],
  fetcher({
    settingName,
    defaultValue
  }) {
    // Note: Frontend preferences client doesnt surface errors so this will never reject
    return hubSettingsClient.fetch(settingName, defaultValue);
  }
});