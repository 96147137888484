// App-level actions
export const INIT_QUERY_PARAMS = 'INIT_QUERY_PARAMS';
export const UPDATE_QUERY_PARAMS = 'UPDATE_QUERY_PARAMS';
export const INIT_PATH_PARAMS = 'INIT_PATH_PARAMS';
export const UPDATE_PATH_PARAMS = 'UPDATE_PATH_PARAMS';
export const FETCH_PORTAL_EMAIL_SEND_CREDITS_START = 'FETCH_PORTAL_EMAIL_SEND_CREDITS_START';
export const FETCH_PORTAL_EMAIL_SEND_CREDITS_SUCCESS = 'FETCH_PORTAL_EMAIL_SEND_CREDITS_SUCCESS';
export const FETCH_PORTAL_EMAIL_SEND_CREDITS_FAIL = 'FETCH_PORTAL_EMAIL_SEND_CREDITS_FAIL';

// PORTAL TRIALS STATUS
export const FETCH_PORTAL_TRIALS_START = 'FETCH_PORTAL_TRIALS_START';
export const FETCH_PORTAL_TRIALS_SUCCESS = 'FETCH_PORTAL_TRIALS_SUCCESS';
export const FETCH_PORTAL_TRIALS_FAIL = 'FETCH_PORTAL_TRIALS_FAIL';

// PORTAL SEND LIMIT UI
export const REQUEST_SENDING_LIMIT_EMAILS = 'REQUEST_SENDING_LIMIT_EMAILS';
export const RECEIVE_SENDING_LIMIT_EMAILS = 'RECEIVE_SENDING_LIMIT_EMAILS';
export const REQUEST_SENDING_LIMIT_CREDITS = 'REQUEST_SENDING_LIMIT_CREDITS';
export const RECEIVE_SENDING_LIMIT_CREDITS = 'RECEIVE_SENDING_LIMIT_CREDITS';

// PORTAL STATUS like suspension status
export const REQUEST_PORTAL_STATUS = 'REQUEST_PORTAL_STATUS';
export const RECEIVE_PORTAL_STATUS = 'RECEIVE_PORTAL_STATUS';
export const REQUEST_PORTAL_DISMISS_STATUS = 'REQUEST_PORTAL_DISMISS_STATUS';
export const RECEIVE_PORTAL_DISMISS_STATUS = 'RECEIVE_PORTAL_DISMISS_STATUS';
export const REQUEST_SUSPENSION_APPEAL = 'REQUEST_SUSPENSION_APPEAL';
export const RECEIVE_SUSPENSION_APPEAL = 'RECEIVE_SUSPENSION_APPEAL';
export const RECEIVE_UPDATE_PORTAL_DISMISS_STATUS = 'RECEIVE_UPDATE_PORTAL_DISMISS_STATUS';
export const REQUEST_UPDATE_PORTAL_DISMISS_STATUS = 'REQUEST_UPDATE_PORTAL_DISMISS_STATUS';

// RSS URL FEED VALIDATION
export const FETCH_RSS_URL_FEED_VALIDATION_START = 'FETCH_RSS_URL_FEED_VALIDATION_START';
export const FETCH_RSS_URL_FEED_VALIDATION_SUCCESS = 'FETCH_RSS_URL_FEED_VALIDATION_SUCCESS';
export const FETCH_RSS_URL_FEED_VALIDATION_FAIL = 'FETCH_RSS_URL_FEED_VALIDATION_FAIL';
export const RESET_RSS_FEED_URL_VALIDATION = 'RESET_RSS_FEED_URL_VALIDATION';

// BLOG EMAILS
export const FETCH_BLOG_EMAILS_START = 'FETCH_BLOG_EMAILS_START';
export const FETCH_BLOG_EMAILS_SUCCESS = 'FETCH_BLOG_EMAILS_SUCCESS';
export const FETCH_BLOG_EMAILS_FAIL = 'FETCH_BLOG_EMAILS_FAIL';
export const RESET_BLOG_EMAILS = 'RESET_BLOG_EMAILS';

// EMAIL ADDRESS OWNERSHIP
export const FETCH_FROM_ADDRESSES_START = 'FETCH_FROM_ADDRESSES_START';
export const FETCH_FROM_ADDRESSES_SUCCESS = 'FETCH_FROM_ADDRESSES_SUCCESS';
export const FETCH_FROM_ADDRESSES_FAIL = 'FETCH_FROM_ADDRESSES_FAIL';
export const ADD_EMAIL_ADDRESS_STARTED = 'ADD_EMAIL_ADDRESS_STARTED';
export const ADD_EMAIL_ADDRESS_SUCCEEDED = 'ADD_EMAIL_ADDRESS_SUCCEEDED';
export const ADD_EMAIL_ADDRESS_FAILED = 'ADD_EMAIL_ADDRESS_FAILED';
export const ADD_EMAIL_ADDRESS_RESET = 'ADD_EMAIL_ADDRESS_RESET';
export const REMOVE_EMAIL_ADDRESS_STARTED = 'REMOVE_EMAIL_ADDRESS_STARTED';
export const REMOVE_EMAIL_ADDRESS_SUCCEEDED = 'REMOVE_EMAIL_ADDRESS_SUCCEEDED';
export const REMOVE_EMAIL_ADDRESS_FAILED = 'REMOVE_EMAIL_ADDRESS_FAILED';
export const REMOVE_EMAIL_ADDRESS_RESET = 'REMOVE_EMAIL_ADDRESS_RESET';
export const FROM_ADDRESS_VERIFICATION_EMAIL_SENT = 'FROM_ADDRESS_VERIFICATION_EMAIL_SENT';
export const FROM_ADDRESS_VERIFIED = 'FROM_ADDRESS_VERIFIED';
export const START_POLLING_FROM_ADDRESSES = 'START_POLLING_FROM_ADDRESSES';
export const STOP_POLLING_FROM_ADDRESSES = 'STOP_POLLING_FROM_ADDRESSES';
export const UPDATE_CUSTOM_REPLY_TO_FAIL = 'UPDATE_CUSTOM_REPLY_TO_FAIL';

// SMART INSIGHTS
export const FETCH_SMART_INSIGHTS_START = 'FETCH_SMART_INSIGHTS_START';
export const FETCH_SMART_INSIGHTS_SUCCESS = 'FETCH_SMART_INSIGHTS_SUCCESS';
export const FETCH_SMART_INSIGHTS_FAIL = 'FETCH_SMART_INSIGHTS_FAIL';
export const FETCH_SMART_INSIGHTS_RESET = 'FETCH_SMART_INSIGHTS_RESET';

// TEST SEND
export const ADD_TEST_SEND_ADDRESS = 'ADD_TEST_SEND_ADDRESS';
export const CLOSED_PANEL = 'CLOSED_PANEL';
export const TEST_SEND_ITEM_SUCCESS = 'TEST_SEND_ITEM_SUCCESS';
export const TEST_SEND_ITEM_FAIL = 'TEST_SEND_ITEM_FAIL';
export const TEST_SEND_RESET = 'TEST_SEND_RESET';
export const TEST_SEND_MODAL_TOGGLE = 'TEST_SEND_MODAL_TOGGLE';
export const TEST_SEND_FINISHED = 'TEST_SEND_FINISHED';
export const TEST_EMAIL_SEND_START = 'TEST_EMAIL_SEND_START';
export const TEST_EMAIL_RENDER_START = 'TEST_EMAIL_RENDER_START';
export const TEST_EMAIL_RENDER_FAIL = 'TEST_EMAIL_RENDER_FAIL';
export const SET_TEST_SEND_CONFIG = 'SET_TEST_SEND_CONFIG';

// EMAIL RECIPIENTS
export const FETCH_ALL_RECIPIENT_COUNTS_START = 'FETCH_ALL_RECIPIENT_COUNTS_START';
export const FETCH_ALL_RECIPIENT_COUNTS_SUCCESS = 'FETCH_ALL_RECIPIENT_COUNTS_SUCCESS';
export const FETCH_ALL_RECIPIENT_COUNTS_FAIL = 'FETCH_ALL_RECIPIENT_COUNTS_FAIL';
export const FETCH_GRAYMAIL_START = 'FETCH_GRAYMAIL_START';
export const FETCH_GRAYMAIL_SUCCESS = 'FETCH_GRAYMAIL_SUCCESS';
export const FETCH_GRAYMAIL_FAIL = 'FETCH_GRAYMAIL_FAIL';
export const FETCH_FATIGUE_START = 'FETCH_FATIGUE_START';
export const FETCH_FATIGUE_SUCCESS = 'FETCH_FATIGUE_SUCCESS';
export const FETCH_FATIGUE_FAIL = 'FETCH_FATIGUE_FAIL';
export const RESET_ALL_RECIPIENT_COUNTS = 'RESET_ALL_RECIPIENT_COUNTS';
export const LOAD_FEEDBACK_CLIENT = 'LOAD_FEEDBACK_CLIENT';
export const LOAD_FEEDBACK_CONTACT = 'LOAD_FEEDBACK_CONTACT';
export const LOAD_FEEDBACK_SURVEY = 'LOAD_FEEDBACK_SURVEY';
export const DISMISS_ZERO_STATE = 'DISMISS_ZERO_STATE';
export const FETCH_ACTIVE_LIST_LIMIT_SUCCESS = 'FETCH_ACTIVE_LIST_LIMIT_SUCCESS';
export const FETCH_STATIC_LIST_LIMIT_SUCCESS = 'FETCH_STATIC_LIST_LIMIT_SUCCESS';
// PORTAL MIGRATION STATUS
export const FETCH_PORTAL_MIGRATION_START = 'FETCH_PORTAL_MIGRATION_START';
export const FETCH_PORTAL_MIGRATION_SUCCESS = 'FETCH_PORTAL_MIGRATION_SUCCESS';
export const FETCH_PORTAL_MIGRATION_FAIL = 'FETCH_PORTAL_MIGRATION_FAIL';
export const REHYDRATE_FROM_USER_SETTINGS = 'REHYDRATE_FROM_USER_SETTINGS';
export const REHYDRATE_FROM_USER_ATTRIBUTES = 'REHYDRATE_FROM_USER_ATTRIBUTES';

// Analyze stats
export const ANALYZE_REFRESH_STATS = 'ANALYZE_REFRESH_STATS';
export const FETCH_TEAM_HUB_SETTINGS = 'FETCH_TEAM_HUB_SETTINGS';

// Details / Post-send
export const RESET_EMAIL_DETAILS = 'RESET_EMAIL_DETAILS';

// Rollup limit
export const FETCH_ROLLUP_LIMIT_REQUEST = 'FETCH_ROLLUP_LIMIT_REQUEST';
export const FETCH_ROLLUP_LIMIT = 'FETCH_ROLLUP_LIMIT';
export const FETCH_LISTS_REQUEST = 'FETCH_LISTS_REQUEST';
export const FETCH_LISTS = 'FETCH_LISTS';

// Email Actions (clone, delete etc)
export const REQUEST_ARCHIVE_EMAIL = 'REQUEST_ARCHIVE_EMAIL';
export const ARCHIVE_EMAIL = 'ARCHIVE_EMAIL';
export const RECEIVE_HAS_ARCHIVED = 'RECEIVE_HAS_ARCHIVED';
export const REQUEST_CANCEL_EMAIL = 'REQUEST_CANCEL_EMAIL';
export const CANCEL_EMAIL = 'CANCEL_EMAIL';
export const CLONE_EMAIL = 'CLONE_EMAIL';
export const CLONE_EMAIL_CONFIRM = 'CLONE_EMAIL_CONFIRM';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const REQUEST_MOVE_TO_FOLDER = 'REQUEST_MOVE_TO_FOLDER';
export const MOVE_TO_FOLDER = 'MOVE_TO_FOLDER';
export const REQUEST_UNARCHIVE_EMAIL = 'REQUEST_UNARCHIVE_EMAIL';
export const UNARCHIVE_EMAIL = 'UNARCHIVE_EMAIL';

// Email Details
export const FETCH_EMAIL_DETAILS_WORKFLOWS_REQUEST = 'FETCH_EMAIL_DETAILS_WORKFLOWS_REQUEST';
export const FETCH_EMAIL_DETAILS_WORKFLOWS = 'FETCH_EMAIL_DETAILS_WORKFLOWS';
export const FETCH_SIMPLE_WORKFLOWS = 'FETCH_SIMPLE_WORKFLOWS';
export const FETCH_SIMPLE_WORKFLOWS_REQUEST = 'FETCH_SIMPLE_WORKFLOWS_REQUEST';
export const FETCH_EMAIL_DETAILS_REQUEST = 'FETCH_EMAIL_DETAILS_REQUEST';
export const FETCH_EMAIL_DETAILS = 'FETCH_EMAIL_DETAILS';
export const DISMISS_DETAILS_ACTION_MODAL = 'DISMISS_DETAILS_ACTION_MODAL';

// Approvals
export const FETCH_APPROVALS_CONFIG_START = 'FETCH_APPROVALS_CONFIG_START';
export const FETCH_APPROVALS_CONFIG_SUCCESS = 'FETCH_APPROVALS_CONFIG_SUCCESS';
export const FETCH_APPROVALS_CONFIG_FAIL = 'FETCH_APPROVALS_CONFIG_FAIL';