/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { fetchTypeMetadataEntries } from './frameworkBuilderReadApi';
import quickFetch from 'quick-fetch';
import { keyEntriesByAppSettings, keyEntriesByFQN } from './keyEntriesByAppSettings';
import { makeTypeMetadataEntriesFetchKey, makeTypeMetadataEntryFetchKey, readSmuggledData } from 'framework-data-schema-quick-fetch';
import { withQuickFetch } from 'framework-data-schema-quick-fetch/withQuickFetch';
import { createInMemoryCache } from '../cache/createInMemoryCache';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { typeMetadataEntriesQuickFetchSmuggleKey } from 'framework-data-schema-quick-fetch';
const defaultToRequestCacheKey = makeTypeMetadataEntryFetchKey;
const defaultToOperationCacheKey = ({
  appSettingNames
}) => makeTypeMetadataEntriesFetchKey({
  appSettingNames
});

// NOTE: Empty is distinct from "null"
export const EMPTY = 'EMPTY';
const defaultAsyncTypeMetadataRequestCache = createPersistedAsyncCache({
  cacheName: 'typeMetadata',
  segmentKey: key => {
    const match = key.match(defaultToRequestCacheKey({
      appSettingName: '(.*)'
    }));
    return match && match[1] || null;
  }
});
const defaultTypeMetadataOperationCache = createInMemoryCache({
  cacheName: 'typeMetadata-ops'
});
export const makeTypeMetadataClient = ({
  httpClient,
  requestCache = defaultAsyncTypeMetadataRequestCache,
  operationCache = defaultTypeMetadataOperationCache,
  toRequestCacheKey = defaultToRequestCacheKey,
  toOperationCacheKey = defaultToOperationCacheKey
}) => {
  const smuggledQuickFetches = readSmuggledData(typeMetadataEntriesQuickFetchSmuggleKey) || {};
  Object.entries(smuggledQuickFetches).forEach(([quickFetchName, requestedAppSettings]) => {
    // Prepopulates the cache with all quick-fetched app setting names, if possible
    try {
      const request = quickFetch.getRequestStateByName(quickFetchName);
      if (request && requestedAppSettings) {
        const promise = withQuickFetch({
          requestName: quickFetchName,
          baseFetch: () => fetchTypeMetadataEntries({
            appSettingNames: requestedAppSettings,
            httpClient
          })
        }).then(entries => keyEntriesByAppSettings(entries));
        requestedAppSettings.forEach(appSettingName => {
          const cachedValue = requestCache.readThrough({
            cacheKey: toRequestCacheKey({
              appSettingName
            }),
            loadValue: () => promise.then(results => results[appSettingName] || EMPTY)
          });
          return cachedValue === null ? makeLoadFailed() : cachedValue;
        });
      }
    } catch (e) {
      // This means there was an error populating cache from quick-fetch.
      // Do nothing, we will simply hit the api again in client code below.
    }
  });
  const resolveAppSettings = async ({
    appSettingNames
  }) => {
    // Map each app setting to its cache key
    const withCacheState = appSettingNames.map(appSettingName => ({
      appSettingName,
      key: toRequestCacheKey({
        appSettingName
      })
    }));

    // Determine if there are any not currently in the cache
    const unfetchedAppSettingNames = withCacheState.filter(({
      key
    }) => !requestCache.has(key)).map(({
      appSettingName
    }) => appSettingName);

    // Build the combined promise to resolve all unfetched app settings.

    // Note: We invert the result (from typeName.appSettingName.metadata to
    // appSettingName.typeName.metadata, swapping the type FQN and app setting name),
    // because our caching is done per-app-setting but the endpoint responds per-object-type.
    const fetchPromise = unfetchedAppSettingNames.length ? fetchTypeMetadataEntries({
      appSettingNames: unfetchedAppSettingNames,
      httpClient
    }).then(keyEntriesByAppSettings) : Promise.resolve(null);

    // Map each requested app setting to a promise that will resolve to either the entries or null.
    const combinedRequests = withCacheState.map(({
      appSettingName,
      key
    }) => {
      const cachedValue = requestCache.readThrough({
        cacheKey: key,
        loadValue: () => fetchPromise.then(result => result && result[appSettingName] || EMPTY)
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    });

    // Wait for all the results to arrive (can be a mix of those from the cache and those from
    // the batch api call)
    const allResults = await Promise.all(combinedRequests);

    // Merge all results down into an accumulator keyed by app setting name
    const mergedResults = allResults.reduce((collectedEntries, entries, index) => {
      // Empty results are omitted
      if (entries && entries !== EMPTY) {
        collectedEntries[appSettingNames[index]] = entries;
      }
      return collectedEntries;
    }, {});

    // Finally, invert the result again to key everything by framework type name,
    // resulting in the expected structure of the response from the BE.
    return keyEntriesByFQN(mergedResults);
  };
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      requestCache.printDebug();
      operationCache.printDebug();
    },
    /**
     * Clears internal cache state.
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([requestCache.clear(), operationCache.clear()]);
    },
    /**
     * Gets all type metadata entries for the requested app settings.
     *
     * @param options.appSettingNames An array of app settings to get type metadata entries for
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to the type metadata entries, or null if the data could not be found.
     */
    get: ({
      appSettingNames,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('typeMetadata.get').increment();
      }
      const cachedValue = operationCache.readThrough({
        cacheKey: toOperationCacheKey({
          appSettingNames
        }),
        loadValue: () => resolveAppSettings({
          appSettingNames
        })
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    }
  };
  return Promise.resolve(client);
};