import hubHttpClient from 'hub-http/clients/apiClient';
import noAuthHttp from 'hub-http/clients/noAuthApiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { createRpcClientV2 } from 'rpc-client-utils';
import I18n from 'I18n';
import getLangLocale from 'I18n/utils/getLangLocale';
import { getTranslatedGrowthFeatureRpc } from 'feature-store-service-types';
import * as Languages from 'self-service-api/constants/Languages';
import { getIsQAProxy } from 'self-service-api/core/utilities/proxyUtils';
const rpcClient = createRpcClientV2({
  hubHttpClient
});
export const getFeatures = ({
  highlightedProducts,
  productLine
}) => {
  const i18nLangLocale =
  // @ts-expect-error dependency missing types
  I18n.loaded && I18n.locale ? getLangLocale() : `${Languages.EN}-us`;
  return noAuthHttp.get(`${getFullUrl('app-api', getIsQAProxy() ? {
    envOverride: 'qa'
  } : {})}/feature/store/v1/features/by-api-names/translated`, {
    query: {
      productApiNames: highlightedProducts,
      i18nLangLocale
    }
  }).then(res => {
    return {
      [productLine]: res
    };
  }).catch(error => {
    console.log('error ~~~~>', error);
  });
};
export const getFeatureWithRPC = ({
  shortName
}) => {
  const i18nLangLocale =
  // @ts-expect-error dependency missing types
  I18n.loaded && I18n.locale ? getLangLocale() : `${Languages.EN}-us`;
  return rpcClient.call(getTranslatedGrowthFeatureRpc, {
    queryParameters: {
      i18nLangLocale
    },
    pathParameters: {
      shortName
    }
  });
};