import { useMemo } from 'react';
import { cloneTracker } from '../utils/UsageTracker';
export const NOT_USING_TRACKER = 'WE HAVE CUSTOM TRACKING IN PLACE AND AI-EXTENSIBILITY TEAM IS AWARE';
export const useTracker = (trackerInstance, usageTrackerLocation) => {
  const aiTracker = useMemo(() => {
    if (trackerInstance === NOT_USING_TRACKER) {
      return undefined;
    }
    return cloneTracker(trackerInstance, usageTrackerLocation);
  }, [trackerInstance, usageTrackerLocation]);
  return {
    aiTracker
  };
};

/** Prefer useTracker over making the tracker optional */
export const useOptionalTracker = (trackerInstance, appName) => {
  const aiTracker = useMemo(() => {
    if (trackerInstance === NOT_USING_TRACKER || !trackerInstance) {
      return undefined;
    }
    return cloneTracker(trackerInstance, appName);
  }, [trackerInstance, appName]);
  return {
    aiTracker
  };
};