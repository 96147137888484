import { useCallback } from 'react';
import useUserInfo from './useUserInfo';
export default function useHasAllScopes() {
  const {
    hasScope
  } = useUserInfo();
  const hasAllScopes = useCallback(scopeOrScopes => {
    if (scopeOrScopes === undefined || Array.isArray(scopeOrScopes) && scopeOrScopes.length === 0) {
      return true;
    }
    if (Array.isArray(scopeOrScopes)) {
      return scopeOrScopes.every(scope => hasScope(scope));
    }
    return hasScope(scopeOrScopes);
  }, [hasScope]);
  return hasAllScopes;
}