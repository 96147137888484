import I18n from 'I18n';
import { getIsAutomatedLike, getIsFollowupEmail } from 'EmailData/utils/emailStateUtil';
import { buildKbURL } from 'EmailData/utils/url';
export const DETAIL_SELECT_ALL = 'DETAIL_SELECT_ALL';
export const AB_EMAIL_WINNER = 'AB_EMAIL_WINNER';
export const NO_ACTION_MODAL = 'NO_ACTION_MODAL';
export const ALL_LISTS = 'ALL_LISTS';
export const ALL_WORKFLOWS = 'ALL_WORKFLOWS';
export const CHARTS = {
  COVER_PAGE: 'COVER_PAGE',
  EMAIL_DETAILS: 'EMAIL_DETAILS',
  OPEN_RATE: 'OPEN_RATE',
  CLICK_RATE: 'CLICK_RATE',
  REPLY_RATE: 'REPLY_RATE',
  PERFORMANCE_DATA_WELL: 'PERFORMANCE_DATA_WELL',
  REVENUE_DATA_WELL: 'REVENUE_DATA_WELL',
  TIME_SPENT_VIEWING: 'TIME_SPENT_VIEWING',
  TOP_CLICKED_LINKS: 'TOP_CLICKED_LINKS',
  TOP_ENGAGED_CONTACTS: 'TOP_ENGAGED_CONTACTS',
  ENGAGEMENT_OVER_TIME: 'ENGAGEMENT_OVER_TIME',
  ENGAGEMENT_BY_EMAIL_CLIENT: 'ENGAGEMENT_BY_EMAIL_CLIENT',
  CLICK_MAP: 'CLICK_MAP'
};
export const DETAIL_AB_TYPES = {
  PRIMARY: 'PRIMARY',
  VARIANT: 'VARIANT',
  WINNER: 'WINNER'
};
export const TIME_SPENT_VIEWING = {
  READ: 'READ',
  SKIMMED: 'SKIMMED',
  GLANCED: 'GLANCED'
};
export const OPEN_DURATION_FILTERS = {
  ALL: 'ALL',
  [TIME_SPENT_VIEWING.READ]: TIME_SPENT_VIEWING.READ,
  [TIME_SPENT_VIEWING.SKIMMED]: TIME_SPENT_VIEWING.SKIMMED,
  [TIME_SPENT_VIEWING.GLANCED]: TIME_SPENT_VIEWING.GLANCED
};
export const NOT_SENT_CRITSIT_FILTER = 'CRITSIT_DROPPED';
export const NOT_SENT_FILTERS = {
  ALL: 'ALL',
  PREVIOUSLY_UNSUBSCRIBED: 'PREVIOUSLY_UNSUBSCRIBED',
  PREVIOUSLY_HARD_BOUNCED: 'PREVIOUSLY_HARD_BOUNCED',
  PREVIOUSLY_MARKED_AS_SPAM: 'PREVIOUSLY_MARKED_AS_SPAM',
  UNENGAGED_CONTACT: 'UNENGAGED_CONTACT',
  RECIPIENT_FATIGUED: 'RECIPIENT_FATIGUED',
  [NOT_SENT_CRITSIT_FILTER]: NOT_SENT_CRITSIT_FILTER,
  OTHER_NOT_SENT: 'OTHER_NOT_SENT'
};
export const ALL_TIME_FILTER = {
  presetId: 'ALL',
  getText: () => I18n.text('EmailData.details.select.timeRange.ALL'),
  getValue: () => ({
    startDate: 0,
    endDate: I18n.moment().valueOf()
  })
};
export const EMAIL_EVENTS = {
  SENT: 'SENT',
  DROP: 'DROP',
  DROPPED: 'DROPPED',
  DEFERRED: 'DEFERRED',
  BOUNCE: 'BOUNCE',
  BOUNCED: 'BOUNCED',
  DELIVERED: 'DELIVERED',
  OPEN: 'OPEN',
  CLICK: 'CLICK',
  REPLY: 'REPLY',
  STATUSCHANGE: 'STATUSCHANGE',
  UNSUBSCRIBED: 'UNSUBSCRIBED',
  SPAMREPORT: 'SPAMREPORT',
  PROCESSED: 'PROCESSED',
  FORWARD: 'FORWARD',
  PRINT: 'PRINT',
  ERROR: 'ERROR',
  SUPPRESSED: 'SUPPRESSED'
};
export const isSent = emailEvent => emailEvent === 'DELIVERED' || emailEvent === 'BOUNCED' || emailEvent === 'SENT';
export const isNotSent = emailEvent => emailEvent === 'DROPPED' || emailEvent === 'SUPPRESSED';
export const RECIPIENT_QUERY_FILTERS = {
  ALL: 'ALL',
  SENT: 'SENT',
  DELIVERED: 'DELIVERED',
  OPENED: 'OPENED',
  CLICKED: 'CLICKED',
  REPLIED: 'REPLIED',
  ORDERED: 'ORDERED',
  BOUNCED: 'BOUNCED',
  HARD_BOUNCED: 'HARD_BOUNCED',
  SOFT_BOUNCED: 'SOFT_BOUNCED',
  UNSUBSCRIBED: 'UNSUBSCRIBED',
  SPAM: 'SPAM',
  NOT_SENT: 'NOT_SENT',
  PENDING: 'PENDING'
};
export const RECIPIENT_QUERY_TABS = {
  [RECIPIENT_QUERY_FILTERS.SENT]: 'sent',
  [RECIPIENT_QUERY_FILTERS.NOT_SENT]: 'not-sent',
  [RECIPIENT_QUERY_FILTERS.DELIVERED]: 'delivered',
  [RECIPIENT_QUERY_FILTERS.OPENED]: 'opened',
  [RECIPIENT_QUERY_FILTERS.ORDERED]: 'ordered',
  [RECIPIENT_QUERY_FILTERS.CLICKED]: 'clicked',
  [RECIPIENT_QUERY_FILTERS.REPLIED]: 'replied',
  [RECIPIENT_QUERY_FILTERS.UNSUBSCRIBED]: 'unsubscribed',
  [RECIPIENT_QUERY_FILTERS.SPAM]: 'marked-spam',
  [RECIPIENT_QUERY_FILTERS.BOUNCED]: 'bounced'
};
export const RECIPIENT_QUERIES = Object.keys(RECIPIENT_QUERY_TABS).reduce((acc, queryTabKey) => {
  const key = RECIPIENT_QUERY_TABS[queryTabKey];
  acc[key] = queryTabKey;
  return acc;
}, {});
export const PERFORMANCE_CHARTS = {
  COVER_PAGE: 'COVER_PAGE',
  EMAIL_DETAILS: 'EMAIL_DETAILS',
  OPEN_RATE: 'OPEN_RATE',
  CLICK_RATE: 'CLICK_RATE',
  REPLY_RATE: 'REPLY_RATE',
  PERFORMANCE_DATA_WELL: 'PERFORMANCE_DATA_WELL',
  REVENUE_DATA_WELL: 'REVENUE_DATA_WELL',
  TIME_SPENT_VIEWING: 'TIME_SPENT_VIEWING',
  TOP_CLICKED_LINKS: 'TOP_CLICKED_LINKS',
  TOP_ENGAGED_CONTACTS: 'TOP_ENGAGED_CONTACTS',
  ENGAGEMENT_OVER_TIME: 'ENGAGEMENT_OVER_TIME',
  ENGAGEMENT_BY_EMAIL_CLIENT: 'ENGAGEMENT_BY_EMAIL_CLIENT',
  CLICK_MAP: 'CLICK_MAP'
};
export const ADVANCED_PERFORMANCE_CHARTS = [PERFORMANCE_CHARTS.TIME_SPENT_VIEWING, PERFORMANCE_CHARTS.ENGAGEMENT_OVER_TIME, PERFORMANCE_CHARTS.ENGAGEMENT_BY_EMAIL_CLIENT];
export const RECIPIENT_INFORMATION_LINKS = {
  [RECIPIENT_QUERY_TABS.NOT_SENT]: buildKbURL('email/analyze-your-marketing-email-campaign-performance#not-sent'),
  [RECIPIENT_QUERY_TABS.DELIVERED]: buildKbURL('email/email-deliverability-best-practices'),
  [RECIPIENT_QUERY_TABS.UNSUBSCRIBED]: buildKbURL('contacts/how-do-subscription-preferences-and-types-work'),
  [RECIPIENT_QUERY_TABS.SPAM]: buildKbURL('email/analyze-your-marketing-email-campaign-performance#not-sent'),
  [RECIPIENT_QUERY_TABS.BOUNCED]: buildKbURL('email/what-is-the-difference-between-a-hard-bounce-and-a-soft-bounce')
};
export const ENGAGEMENT_TYPE = {
  OPEN: 'OPEN',
  CLICK: 'CLICK',
  REPLY: 'REPLY',
  CLICK_THROUGH: 'CLICK_THROUGH',
  ORDER: 'ORDER'
};
export const DEVICE_BREAKDOWN = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  OTHER: 'OTHER',
  UNKNOWN: 'UNKNOWN'
};
export const OVER_TIME_TIMEZONE_TYPE = {
  LOCAL_TIMES: 'LOCAL_TIMES',
  TRUE_TIMES: 'TRUE_TIMES'
};
export const OVER_TIME_PERIOD = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  TWO_DAYS: 'TWO_DAYS',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
};
export const LOCAL_TIME_COLUMNS = {
  sent: 'sent',
  delivered: 'delivered',
  open: 'open',
  click: 'click',
  unsubscribed: 'unsubscribed',
  spamreport: 'spamreport',
  hardbounced: 'hardbounced',
  softbounced: 'softbounced'
};
export const LOCAL_TIME_CHILD_STATES = {
  INITIAL_STATE: 'INITIAL_STATE',
  INITIAL_CANCELED: 'INITIAL_CANCELED',
  CANCELED: 'CANCELED',
  SENT: 'SENT',
  SCHEDULED: 'SCHEDULED',
  ACTIVE_SEND: 'ACTIVE_SEND',
  SCHEDULED_FIRST: 'SCHEDULED_FIRST'
};
export const MAX_ENGAGEMENT_ROWS = 9;
export const TOP_ENGAGED_CONTACTS_TABS = {
  OPENED: 'OPENED',
  CLICKED: 'CLICKED'
};
export const TOP_CLICKED_LINKS_TABS = {
  COMBINED: 'COMBINED',
  HTML: 'HTML',
  PLAINTEXT: 'PLAINTEXT'
};
export const deviceTypeToConstant = (deviceType, browserType, browser) => {
  switch (browserType) {
    case 'Browser':
      return DEVICE_BREAKDOWN.DESKTOP;
    case 'Email client':
      if (deviceType === 'COMPUTER') {
        return DEVICE_BREAKDOWN.DESKTOP;
      } else if (deviceType === 'MOBILE' || deviceType === 'TABLET') {
        return DEVICE_BREAKDOWN.MOBILE;
      }
      return browser && browser.toLowerCase().indexOf('mobile') >= 0 ? DEVICE_BREAKDOWN.MOBILE : DEVICE_BREAKDOWN.DESKTOP;
    case 'Mobile Browser':
      return DEVICE_BREAKDOWN.MOBILE;
    case 'Proxy':
    default:
      return DEVICE_BREAKDOWN.OTHER;
  }
};
export const deviceMobileName = (name, key) => {
  if (key === ENGAGEMENT_TYPE.OPEN) {
    switch (name) {
      case 'Safari Mobile':
      case 'Safari mobile':
      case 'Mobile Safari':
        return 'Apple Mail (iOS)';
      case 'Android Browser':
      case 'Android Webkit':
        return 'Android OS';
      case 'BlackBerry Browser':
      case 'BlackBerry OS':
        return 'BlackBerry';
      default:
        return name;
    }
  } else {
    switch (name) {
      case 'Safari Mobile':
      case 'Safari mobile':
      case 'Mobile Safari':
        return 'Safari (iOS)';
      case 'Android Browser':
      case 'Android Webkit':
        return 'Android';
      case 'BlackBerry Browser':
      case 'BlackBerry OS':
        return 'BlackBerry';
      default:
        return name;
    }
  }
};
export const isGmail = browserName => {
  return browserName === 'Google Image Cache';
};
const isAppleMail = browserName => {
  return browserName === 'Apple Mail';
};
export const getDeviceName = (deviceType, name, key = ENGAGEMENT_TYPE.OPEN) => {
  if (isGmail(name)) {
    return 'Gmail';
  }
  if (isAppleMail(name)) {
    return 'Email client on MacBook';
  }
  switch (deviceType) {
    case DEVICE_BREAKDOWN.MOBILE:
      return deviceMobileName(name, key);
    default:
      return name;
  }
};
export function getTypeFromRecipientDigest(digest) {
  // Todo: after migrate recipients in post send page
  if (isNotSent(digest.deliveryStatus)) {
    return RECIPIENT_QUERY_TABS.NOT_SENT;
  } else if (digest.deliveryStatus === EMAIL_EVENTS.BOUNCED) {
    return RECIPIENT_QUERY_TABS.BOUNCED;
  } else if (digest.spam) {
    return RECIPIENT_QUERY_TABS.SPAM;
  } else if (digest.unsubscribed) {
    return RECIPIENT_QUERY_TABS.UNSUBSCRIBED;
  } else if (digest.dealInfo) {
    return RECIPIENT_QUERY_TABS.ORDERED;
  } else if (digest.replied) {
    return RECIPIENT_QUERY_TABS.REPLIED;
  } else if (digest.clicked) {
    return RECIPIENT_QUERY_TABS.CLICKED;
  } else if (digest.opened) {
    return RECIPIENT_QUERY_TABS.OPENED;
  } else if (digest.deliveryStatus === EMAIL_EVENTS.DELIVERED) {
    return RECIPIENT_QUERY_TABS.DELIVERED;
  }
  return RECIPIENT_QUERY_TABS.SENT;
}
export const getCanCloneToAutomated = (email, hasAutomatedAccess) => {
  // make an exception for followup emails as they can be created via pages
  return hasAutomatedAccess || !getIsAutomatedLike(email) || getIsFollowupEmail(email);
};