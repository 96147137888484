import { GREAT_WHITE } from 'HubStyleTokens/colors';
export const baseWrapperStyle = {
  // Ensures we fill the whole `th` and line our contents up properly
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center'
};
export const draggingWrapperStyle = Object.assign({}, baseWrapperStyle, {
  opacity: 0.5,
  background: GREAT_WHITE
});