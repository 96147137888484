import { useQuery } from 'data-fetching-client';
import { userInfoSync } from 'hub-http/userInfo';
import { useCallback, useMemo } from 'react';
import { GET_USER_INFO } from 'messaging-management-lib/user/queries/userInfo';
export default function useUserInfo() {
  var _parsedData$user2;
  const cachedUserInfo = useMemo(() => {
    try {
      return userInfoSync();
    } catch (_unused) {
      return undefined;
    }
  }, []);
  const {
    data,
    error,
    loading
  } = useQuery(GET_USER_INFO, {
    skip: !!cachedUserInfo
  });
  const parsedData = useMemo(() => {
    return cachedUserInfo || (data === null || data === void 0 ? void 0 : data.userInfo);
  }, [cachedUserInfo, data === null || data === void 0 ? void 0 : data.userInfo]);
  const isUngatedFor = useCallback(gateKey => !!(parsedData !== null && parsedData !== void 0 && parsedData.gates.includes(gateKey)), [parsedData === null || parsedData === void 0 ? void 0 : parsedData.gates]);
  const hasScope = useCallback(scope => {
    var _parsedData$user;
    return !!(parsedData !== null && parsedData !== void 0 && (_parsedData$user = parsedData.user) !== null && _parsedData$user !== void 0 && _parsedData$user.scopes.includes(scope));
  }, [parsedData === null || parsedData === void 0 || (_parsedData$user2 = parsedData.user) === null || _parsedData$user2 === void 0 ? void 0 : _parsedData$user2.scopes]);
  return useMemo(() => ({
    userInfo: parsedData,
    userInfoError: error,
    isLoadingUserInfo: loading,
    loading,
    hasScope,
    isUngatedFor
  }), [error, hasScope, isUngatedFor, loading, parsedData]);
}