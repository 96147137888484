import { useQuery, registerQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import http from 'hub-http/clients/apiClient';
export function fetchCloneInfoRequest(emailId, checkIfConvertibleToDragAndDrop) {
  const url = `cosemail/v1/emails/${emailId}/clone-information`;
  return http.get(url, {
    query: {
      checkIfConvertibleToDragAndDrop
    }
  });
}
export const FETCH_CLONE_INFO = registerQuery({
  fieldName: 'cloneInfo',
  args: ['emailId', 'checkIfConvertibleToDragAndDrop'],
  fetcher({
    emailId,
    checkIfConvertibleToDragAndDrop
  }) {
    return fetchCloneInfoRequest(emailId, checkIfConvertibleToDragAndDrop);
  }
});
export const FETCH_VARIANT_CLONE_INFO = registerQuery({
  fieldName: 'cloneInfo',
  args: ['variantId', 'checkIfConvertibleToDragAndDrop'],
  fetcher({
    variantId,
    checkIfConvertibleToDragAndDrop
  }) {
    return fetchCloneInfoRequest(variantId, checkIfConvertibleToDragAndDrop);
  }
});
function useEmailCloneInfo({
  emailId,
  variantId,
  checkIfConvertibleToDragAndDrop
}) {
  const {
    data,
    loading: isFetchingCloneInfo,
    error
  } = useQuery(FETCH_CLONE_INFO, {
    variables: {
      emailId,
      checkIfConvertibleToDragAndDrop
    }
  });
  const {
    data: variantData,
    loading: isFetchingVariantCloneInfo,
    error: variantError
  } = useQuery(FETCH_VARIANT_CLONE_INFO, {
    variables: {
      variantId: variantId,
      checkIfConvertibleToDragAndDrop
    },
    skip: !variantId
  });
  return useMemo(() => {
    var _data$cloneInfo$conve, _data$cloneInfo$conve2, _data$cloneInfo$smart, _data$cloneInfo$hasCu, _variantData$cloneInf, _variantData$cloneInf2;
    return {
      isConvertible: (_data$cloneInfo$conve = data === null || data === void 0 || (_data$cloneInfo$conve2 = data.cloneInfo.converted) === null || _data$cloneInfo$conve2 === void 0 ? void 0 : _data$cloneInfo$conve2.isConvertible) !== null && _data$cloneInfo$conve !== void 0 ? _data$cloneInfo$conve : false,
      smartContentLocations: (_data$cloneInfo$smart = data === null || data === void 0 ? void 0 : data.cloneInfo.smartContentLocations) !== null && _data$cloneInfo$smart !== void 0 ? _data$cloneInfo$smart : [],
      hasCustomPlaintext: (_data$cloneInfo$hasCu = data === null || data === void 0 ? void 0 : data.cloneInfo.hasCustomPlaintext) !== null && _data$cloneInfo$hasCu !== void 0 ? _data$cloneInfo$hasCu : false,
      variantSmartContentLocations: (_variantData$cloneInf = variantData === null || variantData === void 0 ? void 0 : variantData.cloneInfo.smartContentLocations) !== null && _variantData$cloneInf !== void 0 ? _variantData$cloneInf : [],
      variantHasCustomPlaintext: (_variantData$cloneInf2 = variantData === null || variantData === void 0 ? void 0 : variantData.cloneInfo.hasCustomPlaintext) !== null && _variantData$cloneInf2 !== void 0 ? _variantData$cloneInf2 : false,
      isCloneDataReady: !(isFetchingCloneInfo || isFetchingVariantCloneInfo) && !!data,
      isCloneDataErrored: !!error || !!variantError
    };
  }, [data, error, isFetchingCloneInfo, isFetchingVariantCloneInfo, variantData === null || variantData === void 0 ? void 0 : variantData.cloneInfo.hasCustomPlaintext, variantData === null || variantData === void 0 ? void 0 : variantData.cloneInfo.smartContentLocations, variantError]);
}
export default useEmailCloneInfo;