import makeGlobalStateHook from 'messaging-management-lib/data/makeGlobalStateHook';
import { useCallback, useMemo } from 'react';
let useBlogIDState = makeGlobalStateHook();
let useFrequencyState = makeGlobalStateHook();
let useRssFeedUrlState = makeGlobalStateHook();
export default function useBlogRSSFeedDataState() {
  const [_blogID, _setBlogID] = useBlogIDState(null);
  const [_frequency, _setFrequency] = useFrequencyState(null);
  const [_rssFeedUrl, _setRssFeedUrl] = useRssFeedUrlState(null);
  const setBlogID = useCallback(blogId => {
    _setBlogID(blogId);
  }, [_setBlogID]);
  const setFrequency = useCallback(frequency => {
    _setFrequency(frequency);
  }, [_setFrequency]);
  const setRssFeedUrl = useCallback(rssFeedUrl => {
    _setRssFeedUrl(rssFeedUrl);
  }, [_setRssFeedUrl]);
  return useMemo(() => ({
    blogID: _blogID,
    frequency: _frequency,
    rssFeedUrl: _rssFeedUrl,
    setBlogID,
    setFrequency,
    setRssFeedUrl
  }), [_blogID, _frequency, _rssFeedUrl, setBlogID, setFrequency, setRssFeedUrl]);
}

// This is for use within tests
export function resetBlogRSSFeedDataState() {
  useBlogIDState = makeGlobalStateHook();
  useFrequencyState = makeGlobalStateHook();
  useRssFeedUrlState = makeGlobalStateHook();
}