import enviro from 'enviro';
export const VALIDATION_DEBOUNCE = 500;
export const VALIDATION_DEBOUNCE_KEY = 'VALIDATION_DEBOUNCE';
export const TIME_TOKENS = {
  [VALIDATION_DEBOUNCE_KEY]: VALIDATION_DEBOUNCE
};

/**
 * Exposed for testing purposes only. Will not work in production.
 */
export const setTimeToken = (key, duration) => {
  if (enviro.deployed()) {
    return;
  }
  TIME_TOKENS[key] = duration;
};

/**
 * Exposed for testing purposes only. Will not work in production.
 */
export const setAllTimeTokensToZero = () => {
  if (enviro.deployed()) {
    return;
  }
  Object.keys(TIME_TOKENS).forEach(key => {
    TIME_TOKENS[key] = 0;
  });
};