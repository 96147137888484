// App-level actions
export const DISMISS_CRITSIT_DASHBOARD_BANNER = 'DISMISS_CRITSIT_DASHBOARD_BANNER';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';

// Sidebar nav
export const SELECT_STATE = 'SELECT_STATE';
export const DISMISS_SIDEBAR_TOOLS_CAROUSEL = 'DISMISS_SIDEBAR_TOOLS_CAROUSEL';

// Onboarding
export const DISMISS_ONBOARDING_TOUR_ENTRY = 'DISMISS_ONBOARDING_TOUR_ENTRY';
export const MAYBE_LATER_DISMISS_ONBOARDING_TOUR_ENTRY = 'MAYBE_LATER_DISMISS_ONBOARDING_TOUR_ENTRY';
export const UPDATE_USER_SHOULD_SEE_EMAIL_FRAMEWORK_ONBOARDING = 'UPDATE_USER_SHOULD_SEE_EMAIL_FRAMEWORK_ONBOARDING';

// View filters
export const BULK_EMAIL_SELECTION = 'BULK_EMAIL_SELECTION';
export const CLEAR_BULK_EMAIL_SELECTION = 'CLEAR_BULK_EMAIL_SELECTION';
export const SELECT_EMAIL_TYPE = 'SELECT_EMAIL_TYPE';
export const SELECT_BUSINESS_UNIT = 'SELECT_BUSINESS_UNIT';
export const SELECT_STATE_FILTER = 'SELECT_STATE_FILTER';
export const SELECT_CAMPAIGN = 'SELECT_CAMPAIGN';
export const SELECT_SUBSCRIPTION = 'SELECT_SUBSCRIPTION';

// Table navigation/sorting
export const SAVE_CURRENT_DASHBOARD_URL_STATE = 'SAVE_CURRENT_DASHBOARD_URL_STATE';
export const CLOSE_PREVIEW = 'CLOSE_PREVIEW';
export const BEGIN_COLUMN_CUSTOMIZATION = 'BEGIN_COLUMN_CUSTOMIZATION';
export const SAVE_COLUMN_CUSTOMIZATION = 'SAVE_COLUMN_CUSTOMIZATION';
export const SELECT_EMAIL_ROW = 'SELECT_EMAIL_ROW';
export const SELECT_FOLDER_ROW = 'SELECT_FOLDER_ROW';
export const SET_TABLE_OFFSET = 'SET_TABLE_OFFSET';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SORT_TABLE_BY_COLUMN = 'SORT_TABLE_BY_COLUMN';
export const TOGGLE_SELECT_EMAIL = 'TOGGLE_SELECT_EMAIL';
export const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';
export const UNSELECT_EMAILS = 'UNSELECT_EMAILS';
export const SELECT_USERS_TEAMS_EMAILS = 'SELECT_USERS_TEAMS_EMAILS';
export const SELECT_TEAM = 'SELECT_TEAM';

// Deliverability Actions
export const FETCH_BOUNCE_DATA = 'FETCH_BOUNCE_DATA';
export const REQUEST_PORTAL_SENDING_IPS = 'REQUEST_PORTAL_SENDING_IPS';
export const RECEIVE_PORTAL_SENDING_IPS = 'RECEIVE_PORTAL_SENDING_IPS';
export const REQUEST_ADDON_SENDING_IPS = 'REQUEST_ADDON_SENDING_IPS';
export const RECEIVE_ADDON_SENDING_IPS = 'RECEIVE_ADDON_SENDING_IPS';

// Email Actions (clone, delete etc)
export const ARCHIVE_EMAIL = 'ARCHIVE_EMAIL';
export const CLONE_EMAIL = 'CLONE_EMAIL';
export const DELETE_EMAIL = 'DELETE_EMAIL';
export const MOVE_TO_FOLDER = 'MOVE_TO_FOLDER';
export const MULTI_ARCHIVE = 'MULTI_ARCHIVE';
export const MULTI_ARCHIVE_CANCEL = 'MULTI_ARCHIVE_CANCEL';
export const MULTI_DELETE = 'MULTI_DELETE';
export const MULTI_MOVE_TO_FOLDER = 'MULTI_MOVE_TO_FOLDER';
export const MULTI_UNARCHIVE = 'MULTI_UNARCHIVE';
export const REQUEST_CANCEL_EMAIL = 'REQUEST_CANCEL_EMAIL';
export const UNARCHIVE_EMAIL = 'UNARCHIVE_EMAIL';
export const VIEW_EMAIL_PERFORMANCE = 'VIEW_EMAIL_PERFORMANCE';
export const VIEW_EMAIL = 'VIEW_EMAIL';
export const REQUEST_DELETE_EMAIL = 'REQUEST_DELETE_EMAIL';
export const CANCEL_EMAIL = 'CANCEL_EMAIL';
export const EMAIL_UPDATE_REQUEST = 'EMAIL_UPDATE_REQUEST';
export const EMAIL_UPDATE = 'EMAIL_UPDATE';
export const REQUEST_ASSIGN_USERS_TEAMS_EMAILS = 'REQUEST_ASSIGN_USERS_TEAMS_EMAILS';
export const ASSIGN_USERS_TEAMS_EMAILS = 'ASSIGN_USERS_TEAMS_EMAILS';

// framework
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const SELECT_EMAIL_CRM_OBJECT = 'SELECT_EMAIL_CRM_OBJECT';
export const SELECT_MULTI_EMAIL_CRM_OBJECTS = 'SELECT_MULTI_EMAIL_CRM_OBJECTS';
export const SET_LAST_SELECTED_ACTION_POPOVER_ID = 'SET_LAST_SELECTED_ACTION_POPOVER_ID';
export const SELECT_FRAMEWORK_DETAILS_PANEL_TAB = 'SELECT_FRAMEWORK_DETAILS_PANEL_TAB';
export const UPDATE_IS_PRIMARY_ACTION_TOOLTIP_OPEN = 'UPDATE_IS_PRIMARY_ACTION_TOOLTIP_OPEN';

// API Requests
export const REQUEST_CAMPAIGN = 'REQUEST_CAMPAIGN';
export const RECEIVE_CAMPAIGN = 'RECEIVE_CAMPAIGN';
export const REQUEST_CAMPAIGN_ID = 'REQUEST_CAMPAIGN_ID';
export const RECEIVE_CAMPAIGN_ID = 'RECEIVE_CAMPAIGN_ID';
export const REQUEST_HAS_ARCHIVED = 'REQUEST_HAS_ARCHIVED';
export const RECEIVE_HAS_ARCHIVED = 'RECEIVE_HAS_ARCHIVED';
export const RECEIVE_EARLY_MINIMAL_EMAILS = 'RECEIVE_EARLY_MINIMAL_EMAILS';
export const REQUEST_EMAILS = 'REQUEST_EMAILS';
export const RECEIVE_EMAILS = 'RECEIVE_EMAILS';
export const REQUEST_HAS_EMAIL = 'REQUEST_HAS_EMAIL';
export const RECEIVE_HAS_EMAIL = 'RECEIVE_HAS_EMAIL';
export const REQUEST_FOLDERS = 'REQUEST_FOLDERS';
export const RECEIVE_FOLDERS = 'RECEIVE_FOLDERS';
export const REQUEST_CREATE_FOLDER = 'REQUEST_CREATE_FOLDER';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const REQUEST_DELETE_FOLDER = 'REQUEST_DELETE_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const REQUEST_RENAME_FOLDER = 'REQUEST_RENAME_FOLDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const REQUEST_MOVE_FOLDER = 'REQUEST_MOVE_FOLDER';
export const MOVE_FOLDER = 'MOVE_FOLDER';
export const REQUEST_SUBSCRIPTIONS = 'REQUEST_SUBSCRIPTIONS';
export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';
export const FETCH_HUB_USERS_REQUEST = 'FETCH_HUB_USERS_REQUEST';
export const FETCH_HUB_USERS = 'FETCH_HUB_USERS';
export const RECEIVE_USER_ATTRIBUTES_REQUEST = 'RECEIVE_USER_ATTRIBUTES_REQUEST';
export const RECEIVE_USER_ATTRIBUTES = 'RECEIVE_USER_ATTRIBUTES';
export const SET_USER_ATTRIBUTE_REQUEST = 'SET_USER_ATTRIBUTE_REQUEST';
export const SET_USER_ATTRIBUTE = 'SET_USER_ATTRIBUTE';
export const REQUEST_BLOGS = 'REQUEST_BLOGS';
export const RECEIVE_BLOGS = 'RECEIVE_BLOGS';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS = 'FETCH_USERS';

// Imports
export const REQUEST_HISTORICAL_IMPORTS = 'REQUEST_HISTORICAL_IMPORTS';
export const RECEIVE_HISTORICAL_IMPORTS = 'RECEIVE_HISTORICAL_IMPORTS';
export const RECEIVE_MAILCHIMP_IMPORT_STATUS = 'RECEIVE_MAILCHIMP_IMPORT_STATUS';

// Sent Emails
export const FETCH_SENT_EMAILS = 'FETCH_SENT_EMAILS';
export const REQUEST_SENT_EMAILS = 'REQUEST_SENT_EMAILS';

// Export
export const EXPORT_EMAILS = 'EXPORT_EMAILS';
export const EXPORT_UNSUBS_AND_BOUNCES = 'EXPORT_UNSUBS_AND_BOUNCES';
export const REQUEST_EXPORT_EMAILS = 'REQUEST_EXPORT_EMAILS';
export const REQUEST_EXPORT_UNSUBS_AND_BOUNCES = 'REQUEST_EXPORT_UNSUBS_AND_BOUNCES';
export const DISMISS_YEAR_IN_REVIEW = 'DISMISS_YEAR_IN_REVIEW';

// experiments
export const EXPERIMENTS_REQUESTING = 'EXPERIMENTS_REQUESTING';
export const EXPERIMENTS_SUCCESSFUL = 'EXPERIMENTS_SUCCESSFUL';

// Comparison Tool
export const SET_COMPARISON_TOOL_RETURN_LOCATION = 'SET_COMPARISON_TOOL_RETURN_LOCATION';

// Eligibilty
export const FETCH_DEDICATED_IP_ELIGIBILITY = 'FETCH_DEDICATED_IP_ELIGIBILITY';
export const FETCH_DEDICATED_IP_ELIGIBILITY_REQUEST = 'FETCH_DEDICATED_IP_ELIGIBILITY_REQUEST';

// Navigation/filtering
//   View filters
export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
export const CLEAR_DATES = 'CLEAR_DATES';

// API Requests
export const REQUEST_EXPORT_CHARTS = 'REQUEST_EXPORT_CHARTS';
export const EXPORT_CHARTS = 'EXPORT_CHARTS';
export const REQUEST_SUSPENDED_CONTACTS = 'REQUEST_SUSPENDED_CONTACTS';
export const RECEIVE_SUSPENDED_CONTACTS = 'RECEIVE_SUSPENDED_CONTACTS';
export const REQUEST_CAN_SPAM_SETTINGS = 'REQUEST_CAN_SPAM_SETTINGS';
export const RECEIVE_CAN_SPAM_SETTINGS = 'RECEIVE_CAN_SPAM_SETTINGS';
export const RECEIVE_HAS_CANCEL_ARCHIVED = 'RECEIVE_HAS_CANCEL_ARCHIVED';

// Email Actions (clone, delete etc)
export const REQUEST_ARCHIVE_EMAIL = 'REQUEST_ARCHIVE_EMAIL';
export const CLONE_EMAIL_CONFIRM = 'CLONE_EMAIL_CONFIRM';
export const REQUEST_UNARCHIVE_EMAIL = 'REQUEST_UNARCHIVE_EMAIL';
export const REQUEST_EDIT_SCHEDULED = 'REQUEST_EDIT_SCHEDULED';
export const EDIT_SCHEDULED = 'EDIT_SCHEDULED';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const REQUEST_PORTAL_DISMISS_STATUS = 'REQUEST_PORTAL_DISMISS_STATUS';
export const RECEIVE_PORTAL_DISMISS_STATUS = 'RECEIVE_PORTAL_DISMISS_STATUS';
export const REQUEST_RECIPIENTS_EXPORT = 'REQUEST_RECIPIENTS_EXPORT';
export const RECEIVE_RECIPIENTS_EXPORT = 'RECEIVE_RECIPIENTS_EXPORT';
export const REQUEST_PERFORMANCE_EXPORT = 'REQUEST_PERFORMANCE_EXPORT';
export const RECEIVE_PERFORMANCE_EXPORT = 'RECEIVE_PERFORMANCE_EXPORT';
export const REQUEST_EMAIL_CLONE_DATA = 'REQUEST_EMAIL_CLONE_DATA';
export const RECEIVED_EMAIL_CLONE_DATA = 'RECEIVED_EMAIL_CLONE_DATA';
export const TOGGLE_CREDIT_LIMIT_REACHED_MODAL = 'TOGGLE_CREDIT_LIMIT_REACHED_MODAL';

// Analyze stats
export const ANALYZE_REFRESH_STATS_REQUEST = 'ANALYZE_REFRESH_STATS_REQUEST';
export const ANALYZE_REFRESH_RELATIVE_STATS_REQUEST = 'ANALYZE_REFRESH_RELATIVE_STATS_REQUEST';
export const ANALYZE_REFRESH_RELATIVE_STATS = 'ANALYZE_REFRESH_RELATIVE_STATS';
export const ANALYZE_REFRESH_INTERVAL_STATS = 'ANALYZE_REFRESH_INTERVAL_STATS';
export const ANALYZE_REFRESH_INTERVAL_STATS_REQUEST = 'ANALYZE_REFRESH_INTERVAL_STATS_REQUEST';
export const ANALYZE_FETCH_EMAILS_BY_STAT_REQUEST = 'ANALYZE_FETCH_EMAILS_BY_STAT_REQUEST';
export const ANALYZE_FETCH_EMAILS_BY_STAT = 'ANALYZE_FETCH_EMAILS_BY_STAT';
export const ANALYZE_SHOW_MORE_EMAILS_BY_STAT = 'ANALYZE_SHOW_MORE_EMAILS_BY_STAT';
export const ANALYZE_PAGINATE_EMAILS_BY_STAT = 'ANALYZE_PAGINATE_EMAILS_BY_STAT';
export const SEARCH_FOR_EMAILS = 'SEARCH_FOR_EMAILS';
export const FETCH_BENCHMARKS = 'FETCH_BENCHMARKS';
export const FETCH_BENCHMARKS_REQUEST = 'FETCH_BENCHMARKS_REQUEST';

// Analyze
export const ANALYZE_TABLE_BEGIN_COLUMN_CUSTOMIZATION = 'ANALYZE_TABLE_BEGIN_COLUMN_CUSTOMIZATION';
export const ANALYZE_TABLE_EXPORT_EMAILS = 'ANALYZE_TABLE_EXPORT_EMAILS';
export const ANALYZE_TABLE_SAVE_COLUMN_CUSTOMIZATION = 'ANALYZE_TABLE_SAVE_COLUMN_CUSTOMIZATION';
export const ANALYZE_TABLE_SET_COLUMNS = 'ANALYZE_TABLE_SET_COLUMNS';
export const ANALYZE_TABLE_SET_ITEMS_TYPE = 'ANALYZE_TABLE_SET_ITEMS_TYPE';
export const ANALYZE_TABLE_SET_SEARCH_TERM = 'ANALYZE_TABLE_SET_SEARCH_TERM';
export const ANALYZE_TABLE_SORT = 'ANALYZE_TABLE_SORT';
export const CLEAR_ANALYZE_FILTER = 'CLEAR_ANALYZE_FILTER';
export const SET_ANALYZE_FILTER = 'SET_ANALYZE_FILTER';

// Details / Post-send
export const FETCH_EMAIL_DETAILS_REQUEST = 'FETCH_EMAIL_DETAILS_REQUEST';
export const FETCH_EMAIL_DETAILS = 'FETCH_EMAIL_DETAILS';
export const FETCH_SMTP_DETAILS_REQUEST = 'FETCH_SMTP_DETAILS_REQUEST';
export const FETCH_SMTP_DETAILS = 'FETCH_SMTP_DETAILS';
export const FETCH_EMAIL_DETAILS_STATISTICS_REQUEST = 'FETCH_EMAIL_DETAILS_STATISTICS_REQUEST';
export const FETCH_EMAIL_DETAILS_STATISTICS = 'FETCH_EMAIL_DETAILS_STATISTICS';
export const FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN = 'FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN';
export const FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN_REQUEST = 'FETCH_EMAIL_STATISTICS_WITH_BOT_OPEN_REQUEST';
export const FETCH_OVER_TIME_STATISTICS_REQUEST = 'FETCH_OVER_TIME_STATISTICS_REQUEST';
export const FETCH_OVER_TIME_STATISTICS = 'FETCH_OVER_TIME_STATISTICS';
export const FETCH_USER_INTERACTION_STATISTICS_REQUEST = 'FETCH_USER_INTERACTION_STATISTICS_REQUEST';
export const FETCH_USER_INTERACTION_STATISTICS = 'FETCH_USER_INTERACTION_STATISTICS';
export const FETCH_EMAIL_OPEN_DURATION_REQUEST = 'FETCH_EMAIL_OPEN_DURATION_REQUEST';
export const FETCH_EMAIL_OPEN_DURATION = 'FETCH_EMAIL_OPEN_DURATION';
export const FETCH_TOP_ENGAGED_CLICKED_CONTACTS_REQUEST = 'FETCH_TOP_ENGAGED_CLICKED_CONTACTS_REQUEST';
export const FETCH_TOP_ENGAGED_CLICKED_CONTACTS = 'FETCH_TOP_ENGAGED_CLICKED_CONTACTS';
export const FETCH_TOP_ENGAGED_OPENED_CONTACTS_REQUEST = 'FETCH_TOP_ENGAGED_OPENED_CONTACTS_REQUEST';
export const FETCH_TOP_ENGAGED_OPENED_CONTACTS = 'FETCH_TOP_ENGAGED_OPENED_CONTACTS';
export const SELECT_TOP_ENGAGED_CONTACTS_TAB = 'SELECT_TOP_ENGAGED_CONTACTS_TAB';
export const SELECT_TOP_CLICKED_LINKS_TAB = 'SELECT_TOP_CLICKED_LINKS_TAB';
export const FETCH_CTA_REQUEST = 'FETCH_CTA_REQUEST';
export const FETCH_ALL_CTA = 'FETCH_ALL_CTA';
export const FETCH_CTA = 'FETCH_CTA';
export const FETCH_INTERACTIVE_REQUEST = 'FETCH_INTERACTIVE_REQUEST';
export const FETCH_INTERACTIVES = 'FETCH_INTERACTIVES';
export const FETCH_EMAIL_CAMPAIGN_CLICKS_REQUEST = 'FETCH_EMAIL_CAMPAIGN_CLICKS_REQUEST';
export const FETCH_EMAIL_CAMPAIGN_CLICKS = 'FETCH_EMAIL_CAMPAIGN_CLICKS';
export const CHOOSE_WINNER = 'CHOOSE_WINNER';
export const RERUN_AB_TEST = 'RERUN_AB_TEST';
export const NEW_AB_TEST = 'NEW_AB_TEST';
export const FETCH_AB_AUTOMATED_HISTORY = 'FETCH_AB_AUTOMATED_HISTORY';
export const SELECT_EMAIL_CHILD = 'SELECT_EMAIL_CHILD';
export const SELECT_TIME_RANGE = 'SELECT_TIME_RANGE';
export const SELECT_LIST_ID = 'SELECT_LIST_ID';
export const SELECT_WORKFLOW_ID = 'SELECT_WORKFLOW_ID';
export const FETCH_EMAIL_DETAILS_RECIPIENTS_REQUEST = 'FETCH_EMAIL_DETAILS_RECIPIENTS_REQUEST';
export const FETCH_EMAIL_DETAILS_RECIPIENTS = 'FETCH_EMAIL_DETAILS_RECIPIENTS';
export const FETCH_EMAIL_DETAILS_WORKFLOWS_REQUEST = 'FETCH_EMAIL_DETAILS_WORKFLOWS_REQUEST';
export const FETCH_EMAIL_DETAILS_WORKFLOWS = 'FETCH_EMAIL_DETAILS_WORKFLOWS';
export const FETCH_SIMPLE_WORKFLOWS = 'FETCH_SIMPLE_WORKFLOWS';
export const FETCH_SIMPLE_WORKFLOWS_REQUEST = 'FETCH_SIMPLE_WORKFLOWS_REQUEST';
export const TOGGLE_RECIPIENT_OPEN_STATE = 'TOGGLE_RECIPIENT_OPEN_STATE';
export const FETCH_RECIPIENTS_EVENTS_REQUEST = 'FETCH_RECIPIENTS_EVENTS_REQUEST';
export const FETCH_RECIPIENTS_EVENTS = 'FETCH_RECIPIENTS_EVENTS';
export const SELECT_OVER_TIME_TIMEZONE = 'SELECT_OVER_TIME_TIMEZONE';
export const SELECT_OVER_TIME_PERIOD = 'SELECT_OVER_TIME_PERIOD';
export const FETCH_AGATHA_CANCELATION_REASON_REQUEST = 'FETCH_AGATHA_CANCELATION_REASON_REQUEST';
export const FETCH_AGATHA_CANCELATION_REASON = 'FETCH_AGATHA_CANCELATION_REASON';
export const FETCH_LANDING_PAGES_REQUEST = 'FETCH_LANDING_PAGES_REQUEST';
export const FETCH_LANDING_PAGES = 'FETCH_LANDING_PAGES';
export const SEND_TO_MORE_CONTACTS_FETCH_REQUEST = 'SEND_TO_MORE_CONTACTS_FETCH_REQUEST';
export const SEND_TO_MORE_CONTACTS_FETCH = 'SEND_TO_MORE_CONTACTS_FETCH';
export const SEND_TO_MORE_CONTACTS_SEARCH_REQUEST = 'SEND_TO_MORE_CONTACTS_SEARCH_REQUEST';
export const SEND_TO_MORE_CONTACTS_SEARCH = 'SEND_TO_MORE_CONTACTS_SEARCH';
export const SEND_TO_MORE_INCLUDE_CONTACT = 'SEND_TO_MORE_INCLUDE_CONTACT';
export const SEND_TO_MORE_EXCLUDE_CONTACT = 'SEND_TO_MORE_EXCLUDE_CONTACT';
export const SEND_TO_MORE_LISTS_FETCH_REQUEST = 'SEND_TO_MORE_LISTS_FETCH_REQUEST';
export const SEND_TO_MORE_LISTS_FETCH = 'SEND_TO_MORE_LISTS_FETCH';
export const SEND_TO_MORE_LISTS_SEARCH_REQUEST = 'SEND_TO_MORE_LISTS_SEARCH_REQUEST';
export const SEND_TO_MORE_LISTS_SEARCH = 'SEND_TO_MORE_LISTS_SEARCH';
export const SEND_TO_MORE_INCLUDE_LIST = 'SEND_TO_MORE_INCLUDE_LIST';
export const SEND_TO_MORE_EXCLUDE_LIST = 'SEND_TO_MORE_EXCLUDE_LIST';
export const RESET_SMTP_PASSWORD_REQUEST = 'RESET_SMTP_PASSWORD_REQUEST';
export const RESET_SMTP_PASSWORD = 'RESET_SMTP_PASSWORD';
export const CLEAR_SMTP_PASSWORD_DETAILS = 'CLEAR_SMTP_PASSWORD_DETAILS';
export const FETCH_BLOG_RSS_HISTORY_REQUEST = 'FETCH_BLOG_RSS_HISTORY_REQUEST';
export const FETCH_BLOG_RSS_HISTORY = 'FETCH_BLOG_RSS_HISTORY';
export const SET_LAGGING_STATS_TIMED_OUT = 'SET_LAGGING_STATS_TIMED_OUT';
export const BLOG_RSS_BEGIN_COLUMN_CUSTOMIZATION = 'BLOG_RSS_BEGIN_COLUMN_CUSTOMIZATION';
export const BLOG_RSS_SAVE_COLUMN_CUSTOMIZATION = 'BLOG_RSS_SAVE_COLUMN_CUSTOMIZATION';
export const BLOG_RSS_SET_COLUMNS = 'BLOG_RSS_SET_COLUMNS';
export const BLOG_RSS_SET_SHOW_SKIPPED = 'BLOG_RSS_SET_SHOW_SKIPPED';
export const BLOG_RSS_SET_TABLE_SORT = 'BLOG_RSS_SET_TABLE_SORT';
export const SAVE_IS_NEW_TO_TEST_RESULTS_TAB = 'SAVE_IS_NEW_TO_TEST_RESULTS_TAB';

// Revenue reporting
export const FETCH_REVENUE_REPORTING_REQUEST = 'FETCH_REVENUE_REPORTING_REQUEST';
export const FETCH_REVENUE_REPORTING_RESULT = 'FETCH_REVENUE_REPORTING_RESULT';
export const FETCH_REVENUE_OVER_TIME_REQUEST = 'FETCH_REVENUE_OVER_TIME_REQUEST';
export const FETCH_REVENUE_OVER_TIME_RESULT = 'FETCH_REVENUE_OVER_TIME_RESULT';

// Contact / List Info
export const FETCH_CONTACT_RECORDS_REQUEST = 'FETCH_CONTACT_RECORDS_REQUEST';
export const FETCH_CONTACT_RECORDS = 'FETCH_CONTACT_RECORDS';
export const FETCH_CONTACT_SEARCH_RECORDS_REQUEST = 'FETCH_CONTACT_SEARCH_RECORDS_REQUEST';
export const FETCH_CONTACT_SEARCH_RECORDS = 'FETCH_CONTACT_SEARCH_RECORDS';
export const RESET_CONTACT_SEARCH_RECORDS = 'RESET_CONTACT_SEARCH_RECORDS';
export const FETCH_CAMPAIGN_STATUS_REQUEST = 'FETCH_CAMPAIGN_STATUS_REQUEST';
export const FETCH_CAMPAIGN_STATUS = 'FETCH_CAMPAIGN_STATUS';
export const DETAILS_STATE_UPDATE_REQUEST = 'DETAILS_STATE_UPDATE_REQUEST';
export const DETAILS_STATE_UPDATE_AB = 'DETAILS_STATE_UPDATE_AB';
export const DETAILS_STATE_UPDATE_BATCH = 'DETAILS_STATE_UPDATE_BATCH';
export const CONDITIONALLY_PUBLISH_REQUEST = 'CONDITIONALLY_PUBLISH_REQUEST';
export const CONDITIONALLY_PUBLISH = 'CONDITIONALLY_PUBLISH';
export const RENDER_DETAILS_ACTION_MODAL = 'RENDER_DETAILS_ACTION_MODAL';
export const DISMISS_DETAILS_ACTION_MODAL = 'DISMISS_DETAILS_ACTION_MODAL';
export const FETCH_UNBOUNCE_STATUS_REQUEST = 'FETCH_UNBOUNCE_STATUS_REQUEST';
export const FETCH_UNBOUNCE_STATUS = 'FETCH_UNBOUNCE_STATUS';
export const UNBOUNCE_CONTACT_REQUEST = 'UNBOUNCE_CONTACT_REQUEST';
export const UNBOUNCE_CONTACT = 'UNBOUNCE_CONTACT';
export const CREATE_LIST_REQUEST = 'CREATE_LIST_REQUEST';
export const CREATE_LIST = 'CREATE_LIST';
export const APPEND_LIST_REQUEST = 'APPEND_LIST_REQUEST';
export const APPEND_LIST = 'APPEND_LIST';
export const FETCH_STATIC_LISTS_REQUEST = 'FETCH_STATIC_LISTS_REQUEST';
export const FETCH_STATIC_LISTS = 'FETCH_STATIC_LISTS';
export const UPDATE_CAMPAIGN_REQUEST = 'UPDATE_CAMPAIGN_REQUEST';
export const SELECT_SEARCH_RECIPIENT = 'SELECT_SEARCH_RECIPIENT';
export const SET_RECIPIENT_SEARCH_TERM = 'SET_RECIPIENT_SEARCH_TERM';
export const EDIT_WEB_VERSION_REQUEST = 'EDIT_WEB_VERSION_REQUEST';
export const REDIRECT_WEB_VERSION = 'REDIRECT_WEB_VERSION';
export const REDIRECT_WEB_VERSION_REQUEST = 'REDIRECT_WEB_VERSION_REQUEST';
export const FETCH_SEND_TLDR_SUMMARY_REQUEST = 'FETCH_SEND_TLDR_SUMMARY_REQUEST';
export const FETCH_SEND_TLDR_SUMMARY = 'FETCH_SEND_TLDR_SUMMARY';
export const DISMISS_TLDR = 'DISMISS_TLDR';
export const FETCH_HEALTH_THRESHOLDS_REQUEST = 'FETCH_HEALTH_THRESHOLDS_REQUEST';
export const FETCH_HEALTH_THRESHOLDS_SUCCESS = 'FETCH_HEALTH_THRESHOLDS_SUCCESS';
export const FETCH_HEALTH_THRESHOLDS_ERROR = 'FETCH_HEALTH_THRESHOLDS_ERROR';
export const SAVE_PQL = 'SAVE_PQL';
export const FILTER_OLD_PQLS = 'FILTER_OLD_PQLS';
export const DETAILS_MARK_STATISTICS_DIRTY = 'DETAILS_MARK_STATISTICS_DIRTY';
export const RECEIVE_INTEGRATION_SETTINGS_REQUEST = 'RECEIVE_INTEGRATION_SETTINGS_REQUEST';
export const RECEIVE_INTEGRATION_SETTINGS = 'RECEIVE_INTEGRATION_SETTINGS';
export const RECEIVE_NATIVE_STATUSES_REQUEST = 'RECEIVE_NATIVE_STATUSES_REQUEST';
export const RECEIVE_NATIVE_STATUSES = 'RECEIVE_NATIVE_STATUSES';

// SLACK
export const FETCH_SLACK_CHANNELS_START = 'FETCH_SLACK_CHANNELS_START';
export const FETCH_SLACK_CHANNELS_SUCCESS = 'FETCH_SLACK_CHANNELS_SUCCESS';
export const FETCH_SLACK_CHANNELS_FAIL = 'FETCH_SLACK_CHANNELS_FAIL';
export const POST_SLACK_MESSAGE_START = 'POST_SLACK_MESSAGE_START';
export const POST_SLACK_MESSAGE_SUCCESS = 'POST_SLACK_MESSAGE_SUCCESS';
export const POST_SLACK_MESSAGE_FAIL = 'POST_SLACK_MESSAGE_FAIL';

//Reliable open
export const FETCH_RELIABLE_OPEN_START = 'FETCH_RELIABLE_OPEN_START';
export const FETCH_RELIABLE_OPEN_SUCCESS = 'FETCH_RELIABLE_OPEN_SUCCESS';
export const FETCH_RELIABLE_OPEN_FAIL = 'FETCH_RELIABLE_OPEN_FAIL';
export const FETCH_PORTAL_BOT_STATUS_START = 'FETCH_PORTAL_BOT_STATUS_START';
export const FETCH_PORTAL_BOT_STATUS_SUCCESS = 'FETCH_PORTAL_BOT_STATUS_SUCCESS';
export const FETCH_PORTAL_BOT_STATUS_FAIL = 'FETCH_PORTAL_BOT_STATUS_FAIL';

// inventory
export const INVENTORY_REQUESTED = 'INVENTORY_REQUESTED';
export const INVENTORY_RECEIVED = 'INVENTORY_RECEIVED';
export const INVENTORY_ERROR = 'INVENTORY_ERROR';

// site settings
export const FETCH_SITE_SETTINGS = 'FETCH_SITE_SETTINGS';