import env from 'enviro';
import events from '../../events.yaml';

/**
 * Clones @tracker from host applications to send events on framework-listing-lib's behalf.
 * @param tracker
 */
export function setupTracker(tracker) {
  if (!tracker) {
    if (env.isQa()) {
      console.error("[framework-listing-lib] tracker was not set by host application and we won't be able to track events.");
    }
    return undefined;
  }
  return tracker.clone({
    events,
    preserveTrackerProperties: false
  });
}