import { getFromIDB, setInIDB } from 'framework-data-schema-quick-fetch';
import { Metrics } from '../metrics';
export const trackDataChanges = ({
  cacheKey,
  cacheName,
  segment,
  patchSizeBytes,
  patchSizePct
}) => {
  if (!segment) {
    return Promise.resolve();
  }
  return getFromIDB(cacheKey, 'metrics').catch(() => 0).then(result => {
    if (patchSizeBytes === 0) {
      // Increment the identical request counter by one
      return setInIDB(cacheKey, result + 1, 'metrics');
    } else {
      // Report the count of identical requests that we reached
      Metrics.histogram(`${cacheName}-duplicate-data-request-count`, {
        segment
      }).update(result);

      // Report the size of the hypothetical json-patch operations (in bytes)
      Metrics.histogram(`${cacheName}-patch-size`, {
        segment
      }).update(patchSizeBytes);

      // Report the percentage size of the patch as compared to the data
      // (i.e how efficient would this patch have been)
      Metrics.histogram(`${cacheName}-patch-pct`, {
        segment
      }).update(patchSizePct);

      // Reset our count to 0
      return setInIDB(cacheKey, 0, 'metrics');
    }
  });
};