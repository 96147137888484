'use es6';

import asyncResource from 'EmailData/reducers/helpers/asyncResource';
import { RECEIVE_ADDON_SENDING_IPS, RECEIVE_PORTAL_SENDING_IPS, REQUEST_ADDON_SENDING_IPS, REQUEST_PORTAL_SENDING_IPS } from 'email-management-lib/refactor/actions/actionTypes';
import { Map as ImmutableMap } from 'immutable';
const INITIAL_STATE = ImmutableMap({
  default: [],
  transactional: [],
  dedicated: []
});
function filterIps(ips, type = 'MARKETING') {
  const filterFunction = type === 'TRANSACTIONAL' ? ip => ip.isTransactional : ip => !ip.isTransactional;
  return ips.filter(filterFunction).reduce( /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
  (ipList, ip) => ipList.concat(ip.sendingIps), []).filter((ip, index, ipList) => ipList.indexOf(ip) === index);
}
function sendingIps(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVE_PORTAL_SENDING_IPS:
      return state.set('default', action.payload);
    case RECEIVE_ADDON_SENDING_IPS:
      return state.set('transactional', filterIps(action.payload, 'TRANSACTIONAL')).set('dedicated', filterIps(action.payload, 'MARKETING'));
    default:
      return state;
  }
}
export default asyncResource(sendingIps, {
  requestActions: [REQUEST_PORTAL_SENDING_IPS, REQUEST_ADDON_SENDING_IPS],
  responseActions: [RECEIVE_PORTAL_SENDING_IPS, RECEIVE_ADDON_SENDING_IPS],
  invalidatingActions: []
});