import useHasAllGates from 'messaging-management-lib/user/hooks/useHasAllGates';
import { useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import useHasAllScopes from 'messaging-management-lib/user/hooks/useHasAllScopes';
import useSearchParams from './useSearchParams';
const DEFAULT_BU_ID = 0;

// Roughly copied from
// https://git.hubteam.com/HubSpot/Navigation/blob/f24efa7b8bd424a415db546b42e6616c6da6a280/unified-navigation-ui/static/js/utils/getCurrentBusinessUnit.ts
function getBusinessUnit(searchParams) {
  const portalId = PortalIdParser.get();
  const urlParamsBUID = searchParams.get('businessUnitId');

  // If we have a BU ID in the url, use that
  if (urlParamsBUID) {
    if (urlParamsBUID === 'ALL') {
      return DEFAULT_BU_ID;
    } else {
      return parseInt(urlParamsBUID, 10);
    }
  }

  // Otherwise check local storage
  let localStorageValue = {};
  try {
    const value = localStorage.getItem('SELECTED_BUSINESS_UNIT');
    if (value) localStorageValue = JSON.parse(value);
  } catch (_unused) {
    /* nothing */
  }
  const potentialBU = localStorageValue[portalId];
  if (!potentialBU || potentialBU === 'ALL') {
    return DEFAULT_BU_ID;
  }
  return typeof potentialBU === 'number' ? potentialBU : parseInt(potentialBU, 10);
}
export default function useGetCurrentBusinessUnit() {
  const searchParams = useSearchParams();
  const hasAllGates = useHasAllGates();
  const hasAllScopes = useHasAllScopes();
  const canAccessBusinessUnit = useMemo(() => {
    return hasAllGates('campaign-business-unit') && hasAllScopes('business-units-access');
  }, [hasAllGates, hasAllScopes]);
  return useMemo(() => {
    if (canAccessBusinessUnit) {
      return getBusinessUnit(searchParams);
    }
    return null;
  }, [canAccessBusinessUnit, searchParams]);
}