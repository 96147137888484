// @ts-expect-error dependency missing types
import { getProductPurchaseMotionData } from 'self-service-api/api/getProductPurchaseMotionData';
import logError from '../../reliability/logError';
export const getParsedProductPurchaseMotions = ({
  location,
  apiName
}) => {
  const purchaseMotionsRequest = getProductPurchaseMotionData({
    locations: [location],
    apiNames: [apiName]
  });
  return purchaseMotionsRequest({
    location,
    apiName
  }).then(response => {
    if (!response || !response.length) {
      const error = {
        message: `Response is empty for apiName ${apiName} at location ${location}`,
        data: {
          location,
          apiName,
          response
        }
      };
      logError('getProductPurchaseMotionData', error);
    }
    return response;
  }).catch(error => {
    if (error && error.status !== 0) {
      logError('getProductPurchaseMotionData', error);
    }
    return [];
  });
};