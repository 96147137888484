import { getReferenceTypeFromProperty } from '../utils/getReferenceTypeFromProperty';
// I know this seems like pointless indirection when we could just use getReferenceTypeFromProperty directly,
// but in the future if we ever need to add additional checks or logic to determine if a property is an external options property,
// we'll be glad this is here. If in the future I'm proven wrong and this is never anything more than a glorified wrapper,
// feel free to remove it!
/**
 * Returns the reference type of a property only if it is a valid external options (XO) property.
 * @param property The property to check
 * @param objectTypeId The objectTypeId of that property
 * @returns A reference type, or null if the property is not a valid XO property.
 */
export function getReferenceTypeFromExternalOptionsProperty(property, objectTypeId) {
  return getReferenceTypeFromProperty(property, objectTypeId);
}