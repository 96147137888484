import { useCallback, useEffect, useRef, useState } from 'react';
import emptyFunction from 'react-utils/emptyFunction';
const focus = elt => elt && elt.focus();

// The maximum number of cells we'll look ahead for keyboard navigation
const MAX_CELL_SEARCH_DISTANCE = 200;

// The amount of time to wait after compositionend before allowing the form to be submitted on Enter.
const COMPOSITION_END_RESET_DELAY = 250;
const focusNextCellButton = (initialCell, getNext) => {
  let current = getNext(initialCell);
  let loopCount = 0;
  while (current && current.getAttribute('role') !== 'button') {
    current = ++loopCount < MAX_CELL_SEARCH_DISTANCE ? getNext(current) : null;
  }
  focus(current);
};
const EDIT_EVENT_NAME = 'framework-data-table/enteredEditMode';
export const useManagePropertyEditMode = () => {
  const didEndEditWithKeyboard = useRef(false);
  const [inEditMode, setInEditMode] = useState(false);

  // This is used to prevent form submission on Enter if the user has just ended composition mode,
  // as the user may be in the habit of unintentionally pressing Enter again after completing IME input
  // without intending to submit the form, and we want to prevent accidental submission, e.g. after
  // Japanese Kanji input.
  // @see: https://git.hubteam.com/HubSpot/ProductSupport/issues/25966
  const shouldSubmitOnEnter = useRef(true);
  const compositionEndResetTimer = useRef();
  const disableEditMode = useCallback(args => {
    setInEditMode(false);
    //@ts-expect-error unknown is really hard to read out of type safely
    if (args && args.wasKeyboardExit) {
      didEndEditWithKeyboard.current = true;
    }
  }, []);
  const enableEditMode = useCallback(() => setInEditMode(true), []);

  // This is used to shut down any other cells in edit mode when a new cell is edited.
  // We cannot use onBlur because many of the dropdown inputs blur before their new value is set.
  useEffect(() => {
    if (inEditMode) {
      const event = new CustomEvent(EDIT_EVENT_NAME);
      document.dispatchEvent(event);
      document.addEventListener(EDIT_EVENT_NAME, disableEditMode);
      return () => document.removeEventListener(EDIT_EVENT_NAME, disableEditMode);
    }
    return emptyFunction;
  }, [inEditMode, disableEditMode]);

  // If the user ended an edit with the keyboard, we want to restore focus to
  // the cell after the edit so that they can continue using the keyboard.
  // If they used the mouse, focus does not matter.
  const handleButtonCellRef = useCallback(buttonCell => {
    if (didEndEditWithKeyboard.current) {
      didEndEditWithKeyboard.current = false;
      focus(buttonCell);
    }
  }, []);
  const handleKeyDown = useCallback(event => {
    const {
      key,
      target,
      shiftKey
    } = event;
    switch (key) {
      case 'ArrowRight':
        {
          if (target instanceof HTMLTableCellElement) {
            focusNextCellButton(target, current => current.nextElementSibling);
          }
          break;
        }
      case 'ArrowLeft':
        {
          if (target instanceof HTMLTableCellElement) {
            focusNextCellButton(target, current => current.previousElementSibling);
          }
          break;
        }
      case 'ArrowUp':
        {
          if (target instanceof HTMLTableCellElement) {
            focusNextCellButton(target, current => {
              const prevRow = current.parentElement.previousElementSibling;
              return prevRow ? prevRow.cells[target.cellIndex] : null;
            });
          }
          break;
        }
      case 'ArrowDown':
        {
          if (target instanceof HTMLTableCellElement) {
            focusNextCellButton(target, current => {
              const prevRow = current.parentElement.nextElementSibling;
              return prevRow ? prevRow.cells[target.cellIndex] : null;
            });
          }
          break;
        }
      case 'Escape':
        {
          disableEditMode({
            wasKeyboardExit: true
          });
          break;
        }
      case 'Enter':
        {
          if (!inEditMode) {
            event.preventDefault();
            enableEditMode();
          } else if (!shiftKey && shouldSubmitOnEnter.current) {
            event.preventDefault();
            disableEditMode({
              wasKeyboardExit: true
            });
          }
          shouldSubmitOnEnter.current = true;
          break;
        }
      case ' ':
        {
          if (!inEditMode) {
            event.preventDefault();
            enableEditMode();
          }
          break;
        }
      default:
        {
          // do nothing
        }
    }
  }, [disableEditMode, enableEditMode, inEditMode]);
  const handleCompositionEnd = useCallback(() => {
    shouldSubmitOnEnter.current = false;
    if (compositionEndResetTimer.current != null) {
      clearTimeout(compositionEndResetTimer.current);
    }
    compositionEndResetTimer.current = setTimeout(() => {
      shouldSubmitOnEnter.current = true;
    }, COMPOSITION_END_RESET_DELAY);
  }, []);
  return {
    inEditMode,
    handleButtonCellRef,
    handleKeyDown,
    enableEditMode,
    disableEditMode,
    handleCompositionEnd
  };
};