import { useMemo } from 'react';
import useHasAllScopes from 'messaging-management-lib/user/hooks/useHasAllScopes';
export const EMAIL_TYPES_TO_CHECK = {
  BATCH_EMAIL: 'email-type-batch-access',
  AB_EMAIL: 'email-type-ab-access',
  AUTOMATED_AB_EMAIL: 'email-type-ab-access',
  AUTOMATED_EMAIL: 'email-type-workflows-access',
  BLOG_EMAIL: 'email-type-blog-access',
  BLOG_EMAIL_CHILD: 'email-type-blog-access',
  FOLLOWUP_EMAIL: 'email-type-batch-access',
  LOCALTIME_EMAIL: 'email-type-localtime-access',
  OPTIN_EMAIL: 'email-type-batch-access',
  OPTIN_FOLLOWUP_EMAIL: 'email-type-batch-access',
  RESUBSCRIBE_EMAIL: ['email-type-batch-access', 'site-settings-write'],
  RSS_EMAIL: 'email-type-rss-access',
  RSS_EMAIL_CHILD: 'email-type-rss-access',
  SINGLE_SEND_API: 'transactional-email-api',
  MARKETING_SINGLE_SEND_API: 'marketing-email-api',
  LEADFLOW_EMAIL: 'email-type-batch-access',
  FEEDBACK_NPS_EMAIL: 'email-type-batch-access',
  FEEDBACK_CES_EMAIL: 'email-type-batch-access',
  FEEDBACK_CUSTOM_EMAIL: 'email-type-batch-access',
  FEEDBACK_CUSTOM_SURVEY_EMAIL: 'email-type-batch-access',
  TICKET_EMAIL: 'email-type-batch-access',
  SMTP_TOKEN: 'email-type-batch-access',
  MEMBERSHIP_REGISTRATION_EMAIL: 'email-type-batch-access',
  MEMBERSHIP_PASSWORD_SAVED_EMAIL: 'email-type-batch-access',
  MEMBERSHIP_PASSWORD_RESET_EMAIL: 'email-type-batch-access',
  MEMBERSHIP_FOLLOW_UP_EMAIL: 'email-type-batch-access',
  MEMBERSHIP_PASSWORDLESS_AUTH_EMAIL: 'email-type-batch-access',
  MEMBERSHIP_VERIFICATION_EMAIL: 'email-type-batch-access'
};
export default function useAvailableEmailTypes() {
  const hasAllScopes = useHasAllScopes();
  return useMemo(() => {
    return Object.keys(EMAIL_TYPES_TO_CHECK).filter(key => {
      const requiredScopes = EMAIL_TYPES_TO_CHECK[key];
      return hasAllScopes(requiredScopes);
    });
  }, [hasAllScopes]);
}