import { UPDATE_IS_PRIMARY_ACTION_TOOLTIP_OPEN } from 'email-management-lib/refactor/actions/actionTypes';
const initialState = {
  open: false,
  emailId: null
};
export default function primaryActionTooltip(state = initialState, action) {
  switch (action.type) {
    case UPDATE_IS_PRIMARY_ACTION_TOOLTIP_OPEN:
      return action.payload;
    default:
      return state;
  }
}