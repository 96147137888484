import useHasAllScopes from 'messaging-management-lib/user/hooks/useHasAllScopes';
import { useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
export default function useGetEditorHref({
  autoStartOnboarding
} = {}) {
  const hasAllScopes = useHasAllScopes();
  const isFreeEmail = useMemo(() => {
    return !hasAllScopes('marketing-email-no-branding') && !hasAllScopes('email-access');
  }, [hasAllScopes]);
  const isStarterEmail = useMemo(() => {
    return hasAllScopes('marketing-email-no-branding') && hasAllScopes('email-sending-starter') && !hasAllScopes('email-access');
  }, [hasAllScopes]);
  const hasOnboarding = useMemo(() => isFreeEmail || isStarterEmail, [isFreeEmail, isStarterEmail]);
  return useMemo(() => {
    const baseUrl = `/content/${PortalIdParser.get()}/create/email`;
    if (hasOnboarding && autoStartOnboarding) {
      return `${baseUrl}?onboardingTourId=create-email-1`;
    }
    return baseUrl;
  }, [hasOnboarding, autoStartOnboarding]);
}