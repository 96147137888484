import useHasAllGates from 'messaging-management-lib/user/hooks/useHasAllGates';
import useHasAllScopes from 'messaging-management-lib/user/hooks/useHasAllScopes';
import useAvailableEmailTypes from './useAvailableEmailTypes';
import { useMemo } from 'react';
import useGetPortalSetting from 'messaging-management-lib/portal/hooks/useGetPortalSetting';
export default function useGetEmailEditorType() {
  const {
    setting: hasLegacyClassicFlowBeenUsed
  } = useGetPortalSetting({
    name: 'email:portalUsedClassicBeforeCreationFlowMerge',
    defaultValue: 'false',
    valueParser: value => value === 'true'
  });
  const hasAllScopes = useHasAllScopes();
  const hasAllGates = useHasAllGates();
  const availableEmailTypes = useAvailableEmailTypes();
  const canUseClassicEditor = useMemo(() => hasAllScopes('email-access'), [hasAllScopes]);
  const canUseDNDEditor = useMemo(() => hasAllScopes('email-editor-access') && availableEmailTypes.length > 0, [availableEmailTypes.length, hasAllScopes]);
  const canAccessBothEditors = useMemo(() => canUseClassicEditor && canUseDNDEditor, [canUseClassicEditor, canUseDNDEditor]);
  const isUngatedForNewTemplateEditor = useMemo(() => {
    return hasAllGates('Email:NewContentCreatorPage');
  }, [hasAllGates]);
  return useMemo(() => {
    if (isUngatedForNewTemplateEditor) {
      return 'drag_drop_email';
    }
    if (!canAccessBothEditors && canUseClassicEditor || canAccessBothEditors && hasLegacyClassicFlowBeenUsed) {
      return 'classic_email';
    }
    return 'drag_drop_email';
  }, [isUngatedForNewTemplateEditor, canAccessBothEditors, canUseClassicEditor, hasLegacyClassicFlowBeenUsed]);
}