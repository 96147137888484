module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "EmailComponents": "static-1.39415"
    },
    "depPathPrefixes": {
      "EmailComponents": "/EmailComponents/static-1.39415"
    },
    "project": "EmailComponents",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};