'use es6';

import { Map as ImmutableMap, Record } from 'immutable';
import { OVER_TIME_PERIOD } from 'EmailData/utils/details/detailTypeUtil';
import CampaignEngagementOverTime from '../CampaignEngagementOverTime';
const defaults = {
  overTimeStats: ImmutableMap({}),
  interval: OVER_TIME_PERIOD.HOUR
};
class StatisticsOverTime extends Record(defaults) {
  static from(json) {
    const clone = Object.assign({}, json);
    if (json.campaignIdToChart) {
      const campaignIds = Object.keys(json.campaignIdToChart);
      const overTimeStats = campaignIds.reduce((accum, campaign) => {
        return [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
        ...accum, [campaign, CampaignEngagementOverTime.from(json.campaignIdToChart[campaign])]];
      }, []);
      clone.overTimeStats = ImmutableMap(overTimeStats);
    }
    return new StatisticsOverTime(clone);
  }
  mergeWith(newStats) {
    const currentCampaignStats = this.overTimeStats.keySeq();
    const mergedStats = currentCampaignStats.reduce((stats, campaignId) => {
      if (!stats.has(campaignId)) {
        return stats.set(campaignId, this.overTimeStats.get(campaignId));
      }
      return stats;
    }, newStats.overTimeStats);
    return this.set('interval', newStats.interval).set('overTimeStats', mergedStats);
  }
}
export default StatisticsOverTime;