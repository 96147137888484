export const defaultOptions = {
  max: 250
};
export const defaultLabeler = ({
  count
}) => count;
export const countUp = (prevValue, value) => {
  return value ? value.length : 0;
};
export const countDown = (prevValue, value, {
  max,
  maxLength
}) => {
  const computedMax = typeof maxLength === 'number' ? Math.min(max, maxLength) : max;
  return value ? computedMax - value.length : computedMax;
};
export const defaultCounters = {
  down: countDown,
  up: countUp
};