import { useCallback, useEffect, useMemo, useState } from 'react';
import userInfo, { userInfoSync } from 'hub-http/userInfo';
import { gate as hubHttpGate } from 'hub-http/gates';

/**
 * Cache userInfo response to avoid this hook from updating unnecessary times
 * after the side panel is loaded.
 */
let memoisedInfo;
export default function useFetchUserInfo() {
  const [userInfoState, setUserInfoState] = useState(() => {
    try {
      return userInfoSync();
    } catch (_unused) {
      return undefined;
    }
  });
  const isUngatedFor = useCallback(gate => Boolean(userInfoState && (userInfoState.gates.includes(gate) ||
  // eslint-disable-next-line hubspot-dev/no-bare-gate-strings
  userInfoState.gates.includes(hubHttpGate(gate)))), [userInfoState]);
  const hasScope = useCallback(scope => Boolean(userInfoState && userInfoState.user.scopes.includes(scope)), [userInfoState]);
  useEffect(() => {
    // Set cached value but re-fetch again so we always keep the userInfo up to date
    if (memoisedInfo) {
      setUserInfoState(memoisedInfo);
    }
    userInfo().then(authData => {
      memoisedInfo = authData;
      setUserInfoState(authData);
    }).catch(() => {});
  }, []);
  return useMemo(() => ({
    gates: userInfoState ? userInfoState.gates : [],
    scopes: userInfoState ? userInfoState.user.scopes : [],
    hasScope,
    isUngatedFor,
    userInfo: userInfoState
  }), [hasScope, isUngatedFor, userInfoState]);
}