export const EN = 'en';
export const DE = 'de';
export const ES = 'es';
export const PT = 'pt';
export const FR = 'fr';
export const JA = 'ja';
export const FI = 'fi';
export const IT = 'it';
export const NL = 'nl';
export const PL = 'pl';
export const SV = 'sv';