export const INITIAL_STAGE = 'INITIAL_STAGE';
export const NO_BACKWARDS_MOVEMENT = 'NO_BACKWARDS_MOVEMENT';
export const NO_SKIPPING_STAGES = 'NO_SKIPPING_STAGES';
export const CANNOT_SKIP_APPROVAL_STAGE = 'CANNOT_SKIP_APPROVAL_STAGE';
export const INCOMPLETE_APPROVAL = 'INCOMPLETE_APPROVAL';
export const REJECTED_APPROVAL_ONLY_TO_CLOSED_LOST = 'REJECTED_APPROVAL_ONLY_TO_CLOSED_LOST';
export const UNREJECTED_CLOSED_LOST = 'UNREJECTED_CLOSED_LOST';
export const REJECTED_APPROVAL_REVIVE_TO_APPROVAL_STAGE_OR_PRIOR = 'REJECTED_APPROVAL_REVIVE_TO_APPROVAL_STAGE_OR_PRIOR';

// These are warning codes only understood by the FE since we currently control the logic of when to show a warning.
// Ideally we move the logic for these "soft warnings" to the BE so all of this business logic can be ripped out
export const RESTART_APPROVAL_WARNING = 'RESTART_APPROVAL_WARNING';