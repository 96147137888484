// @ts-expect-error not migrated to TS yet
import Email from 'EmailData/models/Email';
import omit from 'hs-lodash/omit';
import { getConstraintsForStateAndType } from 'EmailData/utils/stateTypeConstraints';
import { List } from 'immutable';
import pick from 'hs-lodash/pick';
import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { getEarlyRequest, getEarlyEmailDetailsKey, readEarlyOrFetch } from 'EmailData/api/earlyRequests';
import { isValidDetailsUrl } from 'EmailData/utils/urlValidator';
import EmailCloneOptions from 'EmailData/data/requests/EmailCloneOptions';
const EXPORT_QUERY_PARAMS = ['archived', 'campaign', 'email', 'name', 'exportAdvancedStats', 'exportColumns', 'format', 'name__icontains', 'portalId', 'state__in', 'type', 'userId', 'startTimestamp', 'endTimestamp', 'team_perms__in', 'user_perms__in', 'extraFields', 'businessUnitId'];
const portalId = PortalIdParser.get();
const COSEMAIL_URI = 'cosemail/v1/emails';
const COSEMAIL_URI_SUFFIX_NO_TIMESTAMP = '/update-without-timestamping';
export function sanitizeEmailQuery(query = {}) {
  const {
    state,
    type,
    search,
    qualifiers,
    sortColumn,
    sortDir,
    teamPerms,
    userPerms,
    extraFields = []
  } = query;
  let sanitizedQuery = Object.assign({}, query);
  const stateQuery = {
    state__in: []
  };
  const {
    includedStates,
    excludedSubcategories,
    onlySubcategories,
    validState
  } = getConstraintsForStateAndType({
    state,
    type,
    blogRssChildren: false
  });
  if (!validState) {
    return {};
  }
  if (includedStates.length) {
    stateQuery.state__in = includedStates;
  }
  if (onlySubcategories.length) {
    stateQuery.subcategory__in = onlySubcategories;
  }
  if (excludedSubcategories.length) {
    stateQuery.subcategory__nin = excludedSubcategories;
  }
  if (state === 'archived') {
    stateQuery.archived = true;
  }
  if (!extraFields.length) {
    sanitizedQuery = omit(sanitizedQuery, 'extraFields');
  }
  sanitizedQuery = Object.assign({}, omit(sanitizedQuery, ['state', 'type']), stateQuery);
  if (search && search.length) {
    sanitizedQuery = Object.assign({}, omit(sanitizedQuery, 'search'), {
      name__icontains: search
    });
  }
  if (sortColumn && sortDir) {
    const order = `${sortDir === 'DESC' ? '-' : ''}${sortColumn}`;
    sanitizedQuery = Object.assign({}, omit(sanitizedQuery, ['sortColumn', 'sortDir']), {
      order
    });
  }
  if (qualifiers) {
    sanitizedQuery = Object.assign({}, omit(sanitizedQuery, 'qualifiers'), {
      qualifierStats: true
    });
  } else {
    sanitizedQuery.statistics = true;
  }
  if (teamPerms) {
    sanitizedQuery = Object.assign({}, omit(sanitizedQuery, 'teamPerms'), {
      team_perms__in: List.isList(teamPerms) ?
      // @ts-expect-error Property 'toArray' does not exist on type 'number'.
      teamPerms.toArray() : [teamPerms]
    });
  }
  if (userPerms) {
    sanitizedQuery = Object.assign({}, omit(sanitizedQuery, 'userPerms'), {
      user_perms__in: List.isList(userPerms) ?
      // @ts-expect-error Property 'toArray' does not exist on type 'number'.
      userPerms.toArray() : [userPerms]
    });
  }
  if (sanitizedQuery.statistics) {
    sanitizedQuery.timeout = 60000;
  }
  return sanitizedQuery;
}
export function exportEmailsRequest(query) {
  const url = 'cosemail/v1/export/dashboard';

  // We are handling special case for timeZone email type.
  // Details on the JIRA : https://issues.hubspotcentral.com/browse/COSEMAIL-15972
  const isFilteredByLocalTimeZoneType = query.type === 'timezone';
  const EXPORT_PARAMS = isFilteredByLocalTimeZoneType ? [...EXPORT_QUERY_PARAMS, 'subcategory__in'] : EXPORT_QUERY_PARAMS;
  const sanitizedQuery = pick(sanitizeEmailQuery(query), EXPORT_PARAMS);
  const settings = Object.assign({}, sanitizedQuery, {
    portalId: PortalIdParser.get({
      preserveGlobalId: true
    }),
    type: 'emails'
  });
  const options = {
    data: settings
  };
  return http.post(url, options);
}
export function getEmailDetails(id, queryParams = {}, supportedEarlyRequestParams = []) {
  const earlyDetailsRequest = getEarlyRequest(getEarlyEmailDetailsKey(id));
  if (earlyDetailsRequest && isValidDetailsUrl(window.location.pathname, window.location.search, supportedEarlyRequestParams)) {
    return readEarlyOrFetch(getEarlyEmailDetailsKey(id), {
      emailId: id
    });
  }
  const url = `cosemail/v1/details/${id}`;
  const options = {
    query: Object.assign({
      portalId: PortalIdParser.get()
    }, queryParams)
  };
  return http.get(url, options);
}
export function archive(id) {
  const archiveURI = `${COSEMAIL_URI}/${id}`;
  return http.put(archiveURI, {
    data: {
      archived: true
    }
  });
}
export function cancel(id) {
  const cancelURI = `${COSEMAIL_URI}/${id}/publish-action`;
  return http.post(cancelURI, {
    data: {
      action: 'cancel-publish'
    }
  });
}
export function archiveAndCancel(id) {
  return cancel(id).then(() => archive(id));
}
export function deleteEmail(id) {
  const deleteURI = `${COSEMAIL_URI}/${id}`;
  const query = {
    portalId,
    format: 'json'
  };
  return http.delete(deleteURI, {
    query
  });
}
export function unarchive(id) {
  const archiveURI = `${COSEMAIL_URI}/${id}`;
  return http.put(archiveURI, {
    data: {
      archived: false
    }
  });
}
export function moveToFolder(id, folderId) {
  const moveURI = `${COSEMAIL_URI}/${id}${COSEMAIL_URI_SUFFIX_NO_TIMESTAMP}`;
  return http.put(moveURI, {
    data: {
      folder_id: folderId
    },
    query: {
      casing: 'snake'
    }
  });
}
export function clone(id, emailCloneOptions = null) {
  if (emailCloneOptions === null || !(emailCloneOptions instanceof EmailCloneOptions)) {
    throw new Error('An email clone request was not provided');
  }
  const data = emailCloneOptions.buildRequestObj();
  const query = {
    portalId,
    _buffer: true,
    casing: 'snake'
  };
  const cloneURI = `${COSEMAIL_URI}/${id}/clone`;
  return http.post(cloneURI, {
    data,
    query
  }).then(res => Email.from(res));
}