import { useCallback, useMemo } from 'react';
import { useAdditionalPropertyValues } from '../../../v2/hooks/useAdditionalPropertyValues';
import { useObjectTypeId } from '../../../v2/hooks/useObjectTypeId';
import { getPipelineStagePropertyName, getPipelinePropertyName } from '../utils/pipelineUtils';
import { RESTART_APPROVAL_WARNING, REJECTED_APPROVAL_ONLY_TO_CLOSED_LOST } from '../utils/pipelineValidationTypes';
import { EDIT_INPUT_MODE } from '../../../v2/types/PropertyInputV2Component';
import { useStageValidationModal } from './useStageValidationModal';
import { useObjectId } from '../../../v2/hooks/useObjectId';
export const useStageChangeValidation = props => {
  const {
    onChangeMultiple,
    inputMode
  } = props;
  const objectTypeId = useObjectTypeId();
  const objectId = useObjectId();
  const pipelineStagePropertyName = getPipelineStagePropertyName(objectTypeId);
  const pipelinePropertyName = getPipelinePropertyName(objectTypeId);
  const {
    data: additionalValues,
    loading: additionalValuesLoading
  } = useAdditionalPropertyValues({
    originStageId: pipelineStagePropertyName,
    originPipelineId: pipelinePropertyName
  });
  const originPipelineId = additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.originPipelineId;
  const {
    stageValidationModal,
    openModal,
    closeModal
  } = useStageValidationModal();
  const handleValidation = useCallback((pendingChanges, reference) => {
    var _reference$additional;
    // If we're aware that specific validation codes should warrant a confirmation modal we fire an open modal action here.
    // The modal needs to propagate the original onChange callback once onConfirm is called so we include the callback as part of the dispatched modal data.
    const errorCode = reference === null || reference === void 0 || (_reference$additional = reference.additionalProperties) === null || _reference$additional === void 0 ? void 0 : _reference$additional.errorCode;
    if (errorCode === RESTART_APPROVAL_WARNING || errorCode === REJECTED_APPROVAL_ONLY_TO_CLOSED_LOST) {
      openModal({
        onConfirm: () => {
          onChangeMultiple(pendingChanges);
          closeModal();
        },
        onCancel: () => {
          closeModal();
        }
      }, errorCode);
      return;
    }

    // Otherwise continue sending values back to host
    onChangeMultiple(pendingChanges);
  }, [openModal, closeModal, onChangeMultiple]);
  const onChangeWithStageValidation = useCallback((changes, reference) => {
    // We don't want this validation triggering for other input modes such as bulk actions
    if (!objectId || inputMode !== EDIT_INPUT_MODE) {
      onChangeMultiple(changes);
      return;
    }
    const stageId = changes[pipelineStagePropertyName];

    // Check local changes first, then fallback to host app's value to grab the pipeline
    const pipelineId = changes[pipelinePropertyName] || originPipelineId;

    // If stage or pipeline isn't part of the property change, there is no validation needed.
    if (!stageId || !pipelineId) {
      onChangeMultiple(changes);
      return;
    }
    handleValidation(changes, reference);
  }, [originPipelineId, onChangeMultiple, pipelineStagePropertyName, handleValidation, pipelinePropertyName, objectId, inputMode]);
  return useMemo(() => ({
    stageChangeValidatorLoading: additionalValuesLoading,
    stageValidationModal,
    onChangeWithStageValidation
  }), [additionalValuesLoading, stageValidationModal, onChangeWithStageValidation]);
};