import { RESTART_APPROVAL_WARNING, REJECTED_APPROVAL_ONLY_TO_CLOSED_LOST } from './pipelineValidationTypes';
export const VALID = 'VALID';
export const INVALID = 'INVALID';
export const OVERRIDABLE = 'OVERRIDABLE';
export const isRecordMovingAtOrBeforeApprovalStage = (stages, originStageId, targetStageId) => {
  // Note: Stages must be sorted by display order
  const sourceStageIndex = stages.findIndex(stage => stage.stageId === originStageId);
  const destinationStageIndex = stages.findIndex(stage => stage.stageId === targetStageId);
  let approvalStageIndex = sourceStageIndex;
  for (; approvalStageIndex >= 0; approvalStageIndex--) {
    const stage = stages[approvalStageIndex];
    if (stage.isApprovalStage) {
      break;
    }
  }
  if (sourceStageIndex >= approvalStageIndex && destinationStageIndex <= approvalStageIndex) {
    return true;
  }
  return false;
};
export const getStageValidations = (response, pipelineStages) => {
  const result = [];
  if (!(response !== null && response !== void 0 && response.objects) || response.objects.length === 0) {
    return result;
  }
  response.objects.forEach(validationObject => {
    const stageValidations = validationObject.stageValidations;
    if (!stageValidations || stageValidations.length === 0) {
      return;
    }
    const {
      originStageId,
      originPipelineId,
      targetPipelineId
    } = validationObject;
    stageValidations.forEach(({
      validation,
      targetStageId
    }) => {
      let validationResult = {
        validation: VALID,
        targetStageId
      };
      if (!validation.valid) {
        validationResult = {
          validation: validation.userCanBypass ? OVERRIDABLE : INVALID,
          errorCode: validation.statusCode,
          targetStageId
        };
      }

      // Handle approval stage reset warning
      if (pipelineStages !== null && pipelineStages !== void 0 && pipelineStages.length && validationResult.validation !== INVALID && originPipelineId === targetPipelineId && isRecordMovingAtOrBeforeApprovalStage(pipelineStages, originStageId, targetStageId)) {
        // rejected revive takes precedence over restart approval
        // restart approval takes precedence over other validations (ex: skip & backwards)
        const errorCode = validation.statusCode === REJECTED_APPROVAL_ONLY_TO_CLOSED_LOST ? validation.statusCode : RESTART_APPROVAL_WARNING;
        validationResult = {
          validation: OVERRIDABLE,
          errorCode,
          targetStageId
        };
      }
      result.push(validationResult);
    });
  });
  return result;
};