import { deepFreeze } from '../cache/deepFreeze';
import { makePipelinesFetchKey } from 'framework-data-schema-quick-fetch';
import { createPersistedAsyncCache } from '../cache/createPersistedAsyncCache';
import { Metrics } from '../metrics';
import { makeLoadFailed } from '../utils/makeLoadFailed';
import { createInMemoryCache } from '../cache/createInMemoryCache';
import { fetchPipelines } from './pipelinesApi';
import { keyBy } from '../utils/keyBy';
const defaultAsyncPipelinesRequestCache = createPersistedAsyncCache({
  cacheName: 'pipelines',
  segmentKey: key => {
    const match = key.match(makePipelinesFetchKey({
      portalId: '.*',
      frameworkTypeIdentifier: '(.*)'
    }));
    return match && match[1] || null;
  },
  workerConfig: {
    normalizeForPatchDiffing: (data, env) => env.keyBy(data, ({
      pipelineId
    }) => pipelineId, pipeline => {
      if (!pipeline.stages) {
        return pipeline;
      }
      return Object.assign({}, pipeline, {
        stages: env.keyBy(pipeline.stages, ({
          stageId
        }) => stageId)
      });
    })
  }
});
const defaultPipelinesOperationCache = createInMemoryCache({
  cacheName: 'pipelines-ops'
});
const makeOperationCacheKey = ({
  frameworkTypeIdentifier,
  portalId
}) => `${makePipelinesFetchKey({
  portalId,
  frameworkTypeIdentifier
})}-operation`;
export const makePipelinesClient = ({
  httpClient,
  requestCache = defaultAsyncPipelinesRequestCache,
  toRequestCacheKey = makePipelinesFetchKey,
  operationCache = defaultPipelinesOperationCache,
  toOperationCacheKey = makeOperationCacheKey
}) => {
  const client = {
    /**
     * Prints debug info to the console.
     */
    debug: () => {
      requestCache.printDebug();
    },
    /**
     * Clears internal cache state
     *
     * @returns A promise which resolves when state is clear.
     */
    clearCache: async () => {
      await Promise.all([requestCache.clear(), operationCache.clear()]);
    },
    /**
     * Gets all pipelines for the requested framework type in the portal.
     *
     * @param options.frameworkTypeIdentifier The identifier of the framework type to get pipelines for.
     * @param options.refetch Set to true to force a refetch of the data.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all pipelines under this framework type, or null if the data could not be found.
     */
    get: ({
      frameworkTypeIdentifier,
      refetch,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('pipelines.get').increment();
      }
      const cachedValue = requestCache.readThrough({
        cacheKey: toRequestCacheKey({
          frameworkTypeIdentifier
        }),
        refresh: refetch,
        loadValue: () => fetchPipelines({
          frameworkTypeIdentifier,
          httpClient
        }).then(deepFreeze)
      });
      return cachedValue === null ? makeLoadFailed() : cachedValue;
    },
    /**
     * Gets the pipeline by id for the requested framework type in the portal.
     *
     * @param options.frameworkTypeIdentifier The identifier of the framework type to get pipelines for.
     * @param options.pipelineId The identifier of the pipeline to get.
     * @param options.refetch Set to true to force a refetch of the data.
     * @param options.__isComposed For internal metrics tracking purposes only. Set to true when called within another client method.
     * @returns A promise which resolves to all pipelines under this framework type, or null if the data could not be found.
     */
    getPipeline: ({
      frameworkTypeIdentifier,
      pipelineId,
      refetch,
      __isComposed = false
    }) => {
      if (!__isComposed) {
        Metrics.counter('pipelines.getPipeline').increment();
      }
      const operationCacheKey = toOperationCacheKey({
        frameworkTypeIdentifier
      });
      const pipelineMapPromise = operationCache.readThrough({
        cacheKey: operationCacheKey,
        refresh: refetch,
        loadValue: () => client.get({
          frameworkTypeIdentifier,
          refetch
        }).then(pipelines => keyBy(pipelines, ({
          pipelineId: id
        }) => id))
      });
      if (pipelineMapPromise === null) {
        return makeLoadFailed();
      }
      return pipelineMapPromise.then(pipelines => pipelines[pipelineId] ? deepFreeze(pipelines[pipelineId]) : null);
    }
  };
  return Promise.resolve(client);
};