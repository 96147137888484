export default function makeObservable(initialValue) {
  let listeners = [];
  let value = initialValue;
  function get() {
    return value;
  }
  function set(newValue) {
    if (newValue !== value) {
      value = newValue;
      listeners.forEach(listener => listener(newValue));
    }
  }
  function subscribe(listener) {
    listeners.push(listener);
    return () => {
      unsubscribe(listener);
    };
  }
  function unsubscribe(targetListener) {
    listeners = listeners.filter(listener => listener !== targetListener);
  }
  return {
    get,
    set,
    subscribe
  };
}