import { useLazyQuery } from 'data-fetching-client';
import { GET_EMAILS_FOR_BLOG } from 'email-management-lib/EmailCreation/queries/blogs';
import { useEffect, useMemo } from 'react';
export default function useGetHasExistingBlogEmail({
  id,
  frequency
}) {
  const [loadEmailsForBlog, {
    data,
    loading
  }] = useLazyQuery(GET_EMAILS_FOR_BLOG);
  useEffect(() => {
    if (id && frequency) {
      loadEmailsForBlog({
        variables: {
          blogId: id
        }
      });
    }
  }, [id, frequency, loadEmailsForBlog]);
  const existingBlogEmail = useMemo(() => {
    var _data$emailsForBlog$f;
    if (!id || !frequency) return null;
    if (loading || !(data !== null && data !== void 0 && data.emailsForBlog)) {
      return null;
    }
    return (_data$emailsForBlog$f = data.emailsForBlog.find(email => email.blogEmailType === frequency && email.id !== null)) !== null && _data$emailsForBlog$f !== void 0 ? _data$emailsForBlog$f : null;
  }, [data, frequency, id, loading]);
  return useMemo(() => {
    var _existingBlogEmail$id;
    return {
      hasExistingEmailForBlog: existingBlogEmail !== null,
      existingBlogEmailID: (_existingBlogEmail$id = existingBlogEmail === null || existingBlogEmail === void 0 ? void 0 : existingBlogEmail.id) !== null && _existingBlogEmail$id !== void 0 ? _existingBlogEmail$id : null,
      isLoadingHasExistingEmailForBlog: loading
    };
  }, [existingBlogEmail, loading]);
}