'use es6';

import { combineReducers } from 'redux';
import lastSelectedActionPopoverId from './lastSelectedActionPopoverId';
import selectedEmailCrmObject from './selectedEmailCrmObject';
import selectedMultiEmailCrmObjects from './selectedMultiEmailCrmObjects';
import subscriptions from './subscriptions';
import primaryActionTooltip from './primaryActionTooltip';
export default combineReducers({
  lastSelectedActionPopoverId,
  selectedEmailCrmObject,
  selectedMultiEmailCrmObjects,
  subscriptions,
  primaryActionTooltip
});