import { ApolloLink, Observable } from '@apollo/client';
import Raven from 'raven-js';
const getOperationDetails = operation => {
  const {
    operationName,
    variables
  } = operation;
  const context = operation.getContext();
  const correlationId = context.correlationId;
  const isMutation = operation.query.definitions && operation.query.definitions.some(definition => definition.kind === 'OperationDefinition' && definition.operation === 'mutation');
  return {
    operationName,
    isMutation,
    correlationId,
    // only include variables if query is not a mutation.
    // Mutations can contain PII/GDPR-sensitive data
    variables: isMutation ? 'mutation' : JSON.stringify(variables)
  };
};
export default new ApolloLink((operation, forward) => new Observable(observer => {
  const sub = forward(operation).subscribe({
    next(result) {
      observer.next(result);
      if (!result.errors || !result.errors.length) {
        return;
      }
      for (const err of result.errors) {
        Raven.captureException(new Error(`hubspot-apollo-client: ${err.message}`), {
          extra: getOperationDetails(operation)
        });
      }
    },
    error(err) {
      const operationDetails = getOperationDetails(operation);
      if (err.errorCode === 'TIMEOUT') {
        Raven.captureException(new Error('hubspot-apollo-client: graphQLTimeout'), {
          extra: Object.assign({
            errorCode: err.errorCode
          }, operationDetails)
        });
      } else {
        Raven.captureException(new Error('hubspot-apollo-client: networkError'), {
          extra: Object.assign({
            errorCode: err.errorCode
          }, operationDetails)
        });
      }
      observer.error(err);
    },
    complete() {
      observer.complete();
    }
  });
  return () => {
    if (sub) {
      sub.unsubscribe();
    }
  };
}));