export const DEFAULT_PADDING = 12;
export const FILTER_BAR_HEIGHT = 52;
export const PAGINATOR_HEIGHT = 40;
export const TABLE_MARGIN_BOTTOM = 16;
export const TABLE_TOP_BAR_HEIGHT = 44;
export const FDT_HEADER_HEIGHT = 44;
export const FDT_TOP_BAR_HEIGHT = 44;
export const TABLE_HEIGHT_OFFSET = PAGINATOR_HEIGHT + 2 * TABLE_MARGIN_BOTTOM;
export const TABLE_HEIGHT_OFFSET_FOLDERS_TAB = PAGINATOR_HEIGHT + 3 * TABLE_MARGIN_BOTTOM;
export const ROW_SIZE_EXTRA_SMALL = 40;
export const ROW_SIZE_SMALL = 56;
export const ROW_SIZE_MEDIUM = 72;
export const TABLE_MIN_HEIGHT = ROW_SIZE_MEDIUM + FDT_HEADER_HEIGHT + FDT_HEADER_HEIGHT + PAGINATOR_HEIGHT + TABLE_MARGIN_BOTTOM;