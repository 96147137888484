import { GET_PORTAL_SETTING } from 'messaging-management-lib/portal/queries/portalSettings';
import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
export default function useGetPortalSetting({
  name,
  defaultValue,
  valueParser
}) {
  // Frontend preferences client doesnt surface errors
  const {
    loading,
    data
  } = useQuery(GET_PORTAL_SETTING, {
    variables: {
      settingName: name,
      defaultValue
    }
  });
  const setting = useMemo(() => {
    if (loading || !data) {
      return valueParser ? valueParser(defaultValue) : defaultValue;
    }
    return valueParser ? valueParser(data.portalSetting) : data.portalSetting;
  }, [loading, data, defaultValue, valueParser]);
  return {
    setting,
    loading
  };
}