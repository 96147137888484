import { makePropertyMetadataClient } from './frameworkBuilder/makePropertyMetadataClient';
import { makeTypeMetadataClient } from './frameworkBuilder/makeTypeMetadataClient';
import { makeFrameworkTypesClient } from './frameworkTypes/makeFrameworkTypesClient';
import { makeGraphClient } from './graph/makeGraphClient';
import { makePropertiesClient } from './properties/makePropertiesClient';
import { Metrics } from './metrics';
import { clearIDB, enableEarlyCacheReturn } from 'framework-data-schema-quick-fetch';
import { makePipelinesClient } from './pipelines/makePipelinesClient';
import { gate } from 'hub-http/gates';
import { getGates } from './auth/getGates';
export const makeFrameworkDataSchemaResolversClient = async ({
  httpClient
}) => {
  const propertiesClientPromise = makePropertiesClient({
    httpClient
  });
  const frameworkTypesClientPromise = makeFrameworkTypesClient({
    httpClient
  });
  const typeMetadataClientPromise = makeTypeMetadataClient({
    httpClient
  });
  const propertyMetadataClientPromise = makePropertyMetadataClient({
    httpClient
  });
  const pipelinesClientPromise = makePipelinesClient({
    httpClient
  });
  const graphClientPromise = makeGraphClient({
    propertiesClientPromise,
    frameworkTypesClientPromise,
    typeMetadataClientPromise,
    propertyMetadataClientPromise
  });
  const enableEarlyCacheReturnPromise = getGates().then(gates => {
    if (gates.has(gate('FDSR:DefaultEarlyCacheReturn'))) {
      enableEarlyCacheReturn();
    }
  });
  const [properties, pipelines, frameworkTypes, typeMetadata, propertyMetadata, graph] = await Promise.all([propertiesClientPromise, pipelinesClientPromise, frameworkTypesClientPromise, typeMetadataClientPromise, propertyMetadataClientPromise, graphClientPromise, enableEarlyCacheReturnPromise]);
  return {
    /**
     * Methods for fetching properties and property groups.
     */
    properties,
    /**
     * Methods for fetching pipelines and their stages
     */
    pipelines,
    /**
     * Methods for fetching framework type (object and event) definitions.
     */
    frameworkTypes,
    /**
     * Methods for fetching framework builder type metadata entries.
     */
    typeMetadata,
    /**
     * Methods for fetching framework builder property metadata entries.
     */
    propertyMetadata,
    /**
     * Methods for fetching and automatically joining schemas data together.
     */
    graph,
    /**
     * Methods for operating on the client's internal state.
     */
    meta: {
      /**
       * Prints the client's internal state out to console.
       */
      debug: () => {
        properties.debug();
        frameworkTypes.debug();
        propertyMetadata.debug();
        typeMetadata.debug();
        graph.debug();
      },
      /**
       * Clears the client's internal caches, including any persistent caching.
       * Please be careful to only use this in tests.
       * @returns A promise which resolves when the internal state is reset.
       */
      clearCaches: async () => {
        Metrics.counter('clearCaches').increment();
        await Promise.all([properties.clearCache(), pipelines.clearCache(), frameworkTypes.clearCache(), propertyMetadata.clearCache(), typeMetadata.clearCache(), graph.clearCache(), clearIDB()]);
      }
    }
  };
};