import { useMemo } from 'react';
import useUserInfo from 'framework-listing-lib/internal/hooks/useUserInfo';
export default function useCrmObjectTypeScopes({
  viewAllAssetsScope = '',
  viewTeamOwnedAssetsScope = ''
}) {
  const {
    userInfo
  } = useUserInfo();
  const userScopes = useMemo(() => {
    if (!userInfo || !userInfo.user) {
      return [];
    }
    return userInfo.user.scopes;
  }, [userInfo]);
  return useMemo(() => {
    const canViewAllAssets = userScopes.includes(viewAllAssetsScope);
    const canViewTeamOwnedAssets = canViewAllAssets || userScopes.includes(viewTeamOwnedAssetsScope);
    return {
      canFilterByTeamsOrUsers: canViewAllAssets || canViewTeamOwnedAssets,
      canViewAllAssets,
      canViewTeamOwnedAssets
    };
  }, [viewAllAssetsScope, viewTeamOwnedAssetsScope, userScopes]);
}