import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchSimpleWorkflowsRequest, fetchWorkflowsRequest } from '../api/workflows';
import allSettled from 'hs-promise-utils/allSettled';
import { useMemo } from 'react';
export const FETCH_WORKFLOWS = registerQuery({
  fieldName: 'workflows',
  args: ['emails'],
  async fetcher({
    emails
  }) {
    const result = [];
    const promises = emails.map(email => {
      const emailId = email.get('id');
      const emailUpdatedAt = email.get('updated');
      return fetchWorkflowsRequest(emailId).then(response => {
        result.push({
          flows: response.filter(flow => flow.enabled),
          emailUpdatedAt,
          emailId,
          emailName: email.get('name')
        });
      });
    });
    await allSettled(promises);
    return result;
  }
});
export const FETCH_SIMPLE_WORKFLOWS = registerQuery({
  fieldName: 'simpleWorkflows',
  args: ['emails'],
  async fetcher({
    emails
  }) {
    const result = [];
    const promises = emails.map(email => {
      const emailId = email.get('id');
      const emailUpdatedAt = email.get('updated');
      return fetchSimpleWorkflowsRequest(emailId).then(response => {
        result.push({
          flows: response.results.reduce((acc, curr) => {
            if (curr.isEnabled) {
              acc.push(Object.assign({}, curr, {
                id: curr.flowId
              }));
            }
            return acc;
          }, []),
          emailUpdatedAt,
          emailId,
          emailName: email.get('name')
        });
      });
    });
    await allSettled(promises);
    return result;
  }
});
function useWorkflows({
  emails
}) {
  const automatedLikeEmails = [];
  const nonAutomatedLikeEmails = [];
  emails.forEach(email => {
    if (email.isAutomatedLike()) {
      automatedLikeEmails.push(email);
    } else {
      nonAutomatedLikeEmails.push(email);
    }
  });
  const {
    data: workflowResponse,
    loading: isFetchingWorkflows
  } = useQuery(FETCH_WORKFLOWS, {
    variables: {
      emails: automatedLikeEmails
    }
  });
  const {
    data: simpleWorkflowResponse,
    loading: isFetchingSimpleWorkflows
  } = useQuery(FETCH_SIMPLE_WORKFLOWS, {
    variables: {
      emails: nonAutomatedLikeEmails
    }
  });
  const emailsWithWFInfo = useMemo(() => {
    if (!workflowResponse || !simpleWorkflowResponse) {
      return [];
    }
    return [...workflowResponse.workflows, ...simpleWorkflowResponse.simpleWorkflows].sort((a, b) => {
      if (a.flows.length && b.flows.length) {
        return b.emailUpdatedAt - a.emailUpdatedAt;
      } else {
        return a.flows.length ? -1 : 1;
      }
    });
  }, [simpleWorkflowResponse, workflowResponse]);
  return useMemo(() => ({
    isLoading: isFetchingWorkflows || isFetchingSimpleWorkflows,
    emailsWithWFInfo
  }), [emailsWithWFInfo, isFetchingSimpleWorkflows, isFetchingWorkflows]);
}
export default useWorkflows;