import { registerQuery } from 'data-fetching-client';
import { getEmailsForBlog } from 'email-management-lib/EmailCreation/api/blogs';
export const GET_EMAILS_FOR_BLOG = registerQuery({
  fieldName: 'emailsForBlog',
  args: ['blogId'],
  fetcher({
    blogId
  }) {
    return getEmailsForBlog(blogId);
  }
});