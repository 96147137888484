import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import memoizeOne from 'react-utils/memoizeOne';
import debounce from 'transmute/debounce';
import getIn from 'transmute/getIn';
import { useHttpClient } from '../../client/HttpClientContext';
import { TIME_TOKENS, VALIDATION_DEBOUNCE_KEY } from '../../utils/timeTokens';
import { useGetAdditionalPropertyValue } from '../../v2/hooks/useGetAdditionalPropertyValue';
import { useIsMounted } from '../../v2/hooks/useIsMounted';
import { INVALID, NOT_YET_VALIDATED, PENDING, VALID } from '../../validation/PropertyValidationStatus';
import { isEmptyValue } from '../../validation/utils/isEmptyValue';
import { getValidationMessage } from '../../validation/utils/validationResultUtils';
import { isInvalidValidationStatus, isPendingValidationStatus, isSaveableValidationStatus } from '../../validation/utils/validationStatusUtils';
import { validateProperty } from '../../validation/validateProperty';
import { canValidateImmediately } from '../utils/canValidateImmediately';
const buildDebouncedValidator = () => debounce(TIME_TOKENS[VALIDATION_DEBOUNCE_KEY], (validationRequest, httpClient, callback) => {
  validateProperty(validationRequest, httpClient).then(callback).catch(callback);
});
const buildValidationRequest = memoizeOne(({
  objectId,
  objectTypeId,
  property,
  value,
  getAdditionalPropertyValue
}) => ({
  objectId,
  objectTypeId,
  property,
  value,
  getAdditionalPropertyValue
}));
const buildValidationState = validationResult => ({
  errors: validationResult.errors,
  validationStatus: validationResult.validationStatus,
  loading: isPendingValidationStatus(validationResult.validationStatus),
  invalid: isInvalidValidationStatus(validationResult.validationStatus),
  saveable: isSaveableValidationStatus(validationResult.validationStatus),
  message: getValidationMessage(validationResult),
  value: validationResult.validationRequest.value,
  propertyName: validationResult.validationRequest.property.name
});
const buildInitialValidationState = (propertyDefinition, value) => ({
  errors: [],
  validationStatus: NOT_YET_VALIDATED,
  loading: false,
  invalid: false,
  saveable: false,
  message: undefined,
  value,
  propertyName: propertyDefinition.name
});
export const buildPendingValidationState = (propertyDefinition, value) => ({
  errors: [],
  loading: true,
  invalid: false,
  saveable: false,
  message: undefined,
  validationStatus: PENDING,
  value,
  propertyName: propertyDefinition.name
});
export const buildSaveableValidationState = (propertyDefinition, value) => ({
  errors: [],
  loading: false,
  invalid: false,
  saveable: true,
  message: undefined,
  validationStatus: VALID,
  value,
  propertyName: propertyDefinition.name
});
export const buildInvalidValidationState = (propertyDefinition, value, errors) => ({
  errors,
  loading: false,
  invalid: true,
  saveable: false,
  message: errors[0].message,
  validationStatus: INVALID,
  value,
  propertyName: propertyDefinition.name
});
export const usePropertyValidation = ({
  disableValidation,
  objectId,
  objectTypeId,
  onChange,
  onValidationChange,
  propertyDefinition,
  propertyValue,
  validationSettings
}) => {
  const getAdditionalPropertyValue = useGetAdditionalPropertyValue();
  const currentValidationRequest = useRef(buildValidationRequest({
    objectId,
    objectTypeId,
    property: propertyDefinition,
    value: propertyValue,
    getAdditionalPropertyValue
  }));
  const [currentValidationState, setCurrentValidationState] = useState(() => buildInitialValidationState(propertyDefinition, propertyValue));

  // Make a copy of the validator for the component
  const debouncedValidator = useMemo(() => buildDebouncedValidator(), []);
  const httpClient = useHttpClient();
  const isMounted = useIsMounted();
  const handleValidationResultChange = useCallback(validationResult => {
    // Ignore responses that arrive after the component has unmounted.
    // This is equivalent to cancelling the pending request on unmount
    // (though requests can't be cancelled)

    // Also, if validation is disabled, ignore the validation result change
    if (!isMounted.current || disableValidation) {
      return;
    }

    // Ignore responses that arrive after the value has changed, because they
    // are no longer relevant. A new validation request for the current value
    // is either in the debounce queue or in-flight
    if (validationResult.validationRequest !== currentValidationRequest.current) {
      return;
    }
    const validationState = buildValidationState(validationResult);
    onValidationChange(validationState);
    setCurrentValidationState(validationState);
  }, [disableValidation, isMounted, onValidationChange]);
  const handleAsyncValidation = useCallback(newValue => {
    // If validation is disabled do not run async validation
    if (disableValidation) {
      return;
    }
    const loadingValidationState = buildPendingValidationState(propertyDefinition, newValue);
    onValidationChange(loadingValidationState);
    setCurrentValidationState(loadingValidationState);
    const validationRequest = buildValidationRequest({
      objectId,
      objectTypeId,
      property: propertyDefinition,
      value: newValue,
      getAdditionalPropertyValue
    });
    currentValidationRequest.current = validationRequest;
    if (canValidateImmediately(validationRequest.property)) {
      validateProperty(validationRequest, httpClient).then(handleValidationResultChange).catch(handleValidationResultChange);
    } else {
      debouncedValidator(validationRequest, httpClient, handleValidationResultChange);
    }
  }, [debouncedValidator, disableValidation, handleValidationResultChange, httpClient, objectId, objectTypeId, onValidationChange, propertyDefinition, getAdditionalPropertyValue]);

  // If the consuming app switches the value or for some reason the hook gets
  // out of sync this will fire a validation update for the current value.
  //
  // In general this hook does not fire for value changes though, that will be
  // dealt with in the onChange handlers
  //
  // This hook will also not run on the initial value because that situation
  // is handled by the initial validation hook below and the default value of
  // the current validation request is the initial property value.
  useEffect(() => {
    if (disableValidation) {
      return;
    }
    if (currentValidationRequest.current.value !== propertyValue) {
      handleAsyncValidation(propertyValue);
    }
  }, [disableValidation, handleAsyncValidation, propertyValue]);

  // Handle initial validation if it's enabled
  useEffect(() => {
    if (disableValidation) {
      return;
    }
    const isInitialValueValidationEnabled = getIn(['validateInitialValue'], validationSettings);
    if (isInitialValueValidationEnabled) {
      // If the value is empty we know it's valid so we can skip the entire
      // validation process. This has a noticeable performance benefit because
      // it does not require promises to resolve in order to update the state.
      // On pages with a large number of inputs this significantly reduces the
      // initial render cost.
      if (isEmptyValue(propertyValue)) {
        const validValidationState = buildSaveableValidationState(propertyDefinition, propertyValue);
        onValidationChange(validValidationState);
        setCurrentValidationState(validValidationState);
        return;
      } else {
        handleAsyncValidation(propertyValue);
      }
    }
    // We only want to run this on mount to check initial values if the setting
    // is enabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeMultiple = useCallback(changes => {
    onChange(changes);

    // For now we are only validating the primary property change
    handleAsyncValidation(changes[propertyDefinition.name]);
  }, [handleAsyncValidation, onChange, propertyDefinition]);
  const handleChangeSingle = useCallback(({
    target: {
      value: newValue
    }
  }) => {
    handleChangeMultiple({
      [propertyDefinition.name]: newValue
    });
  }, [handleChangeMultiple, propertyDefinition]);
  return {
    onChangeSingle: handleChangeSingle,
    onChangeMultiple: handleChangeMultiple,
    validationState: currentValidationState
  };
};