// @ts-expect-error not yet migrated
import { getShouldUseBUPicker } from 'EmailData/selectors/user';
import getBUFromLocalStorage, { ALL_BUSINESS_UNITS } from 'EmailComponents/utils/getBUFromLocalStorage';
import I18n from 'I18n';
import { FETCH_SUBSCRIPTIONS, SELECT_EMAIL_CRM_OBJECT, SELECT_MULTI_EMAIL_CRM_OBJECTS, SET_LAST_SELECTED_ACTION_POPOVER_ID, UPDATE_IS_PRIMARY_ACTION_TOOLTIP_OPEN } from 'email-management-lib/refactor/actions/actionTypes';
import { TAB } from 'framework-asset-preview-lib/utils/tabs';
import { getCanUseTransactional } from 'email-management-lib/refactor/selectors/user';
import { fetchSubscriptionDefinitionsWithTranslations } from 'messaging-publishing-lib/api/subscription';
import Raven from 'raven-js';
import { SELECT_FRAMEWORK_DETAILS_PANEL_TAB } from 'email-management-lib/refactor/actions/actionTypes';
export function selectEmailCrmObject(crmObject) {
  return dispatch => {
    dispatch({
      type: SELECT_EMAIL_CRM_OBJECT,
      payload: {
        crmObject
      }
    });
  };
}
export function openEmailDetailsPanel({
  crmObject,
  tab = TAB.DETAILS
}) {
  return dispatch => {
    dispatch({
      type: SELECT_FRAMEWORK_DETAILS_PANEL_TAB,
      payload: {
        tab
      }
    });
    dispatch(selectEmailCrmObject(crmObject));
  };
}
export function selectMultiEmailCrmObjects(crmObjects) {
  return dispatch => {
    dispatch({
      type: SELECT_MULTI_EMAIL_CRM_OBJECTS,
      payload: {
        crmObjects
      }
    });
  };
}
export function setLastSelectedActionPopoverId(id) {
  return {
    type: SET_LAST_SELECTED_ACTION_POPOVER_ID,
    payload: id
  };
}
export function fetchSubscriptionsInFrameworkTable() {
  return (dispatch, getState) => {
    const state = getState();
    const canUseTransactional = getCanUseTransactional(state);
    const buFromLocalStorage = getBUFromLocalStorage();
    const shouldUseBUPicker = getShouldUseBUPicker(state) && buFromLocalStorage !== ALL_BUSINESS_UNITS;
    const businessUnitId = shouldUseBUPicker ? Number(buFromLocalStorage) : null;
    fetchSubscriptionDefinitionsWithTranslations(businessUnitId).then(response => {
      const subscriptions = response.map(subscription => {
        return {
          text: subscription.subscriptionDefinition.name,
          value: subscription.subscriptionDefinition.id.toString(),
          internal: subscription.subscriptionDefinition.internal
        };
      });
      if (canUseTransactional) {
        subscriptions.push({
          text: I18n.text('messaging-publishing.filters.transactional'),
          value: '-1',
          internal: false
        });
      }
      subscriptions.sort((a, b) => a.text.localeCompare(b.text));
      return dispatch({
        type: FETCH_SUBSCRIPTIONS,
        payload: subscriptions
      });
    }).catch(e => Raven.captureException(e));
  };
}
export function updateIsPrimaryActionTooltipOpen(payload) {
  return {
    type: UPDATE_IS_PRIMARY_ACTION_TOOLTIP_OPEN,
    payload
  };
}