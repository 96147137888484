import Raven from 'raven-js';

// Not a complete type, but supplies us with the fields we need.

export const parseUpdateResponse = (response, requestedUpdates) => {
  const updates = {};
  for (const {
    objectId,
    properties
  } of response) {
    const id = String(objectId);
    updates[id] = {};
    for (const [propertyName, update] of Object.entries(properties)) {
      let value = update.value;

      // Encrypted values are not returned by the endpoint, so we rely on
      // the requested value since it's our best guess at what the value should be.
      if (update.isEncrypted) {
        const requestedUpdate = requestedUpdates.find(_update => _update.objectId === objectId);
        if (requestedUpdate) {
          var _requestedUpdate$prop;
          const requestedValue = (_requestedUpdate$prop = requestedUpdate.propertyValues.find(({
            name
          }) => name === propertyName)) === null || _requestedUpdate$prop === void 0 ? void 0 : _requestedUpdate$prop.value;
          value = requestedValue;
        }
      }
      updates[id][propertyName] = value;
    }
  }
  return updates;
};
export const savePropertyValues = (objectTypeId, updates, httpClient) => {
  // TODO: consider using the client-types package to type this data
  // import { UpdateBatchResponse } from 'inbounddb-objects-service-types/InboundDbObjects-Service-v1/types';
  // at the moment, this type is really lacking since the PropertiesWrapper type is just Record<string, unknown>
  // and the extra dep is not worth it.
  // sswindell @ 10-17-2024
  return httpClient.put(`inbounddb-objects/v1/crm-objects/${encodeURIComponent(objectTypeId)}`, {
    data: updates
  }).catch(e => {
    Raven.captureException(e, {
      extra: {
        objectTypeId,
        properties: updates.map(update => update.propertyValues.map(({
          name
        }) => name))
      }
    });
    throw e;
  });
};