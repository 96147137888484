import { InMemoryCache } from '../../cache/InMemoryCache';
import identity from 'transmute/identity';
import PortalIdParser from 'PortalIdParser';
import { PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { COMMERCE_PRODUCT_TAX_CATEGORY } from 'customer-data-objects/externalOptions/ExternalOptionsReferenceTypes';
const fetchTaxCategories = (__unusedKey, httpClient) => {
  const data = {
    formatLabel: true,
    includeDeleted: false,
    limit: 10000,
    objectType: PRODUCT_TYPE_ID,
    offset: '0',
    searchQuery: '',
    portalId: PortalIdParser.get(),
    referenceType: COMMERCE_PRODUCT_TAX_CATEGORY,
    useIndexOffset: false
  };
  return httpClient.post('external-options/v3/pagedFetch/COMMERCE_PRODUCT_TAX_CATEGORY', {
    data
  });
};
export const cache = new InMemoryCache('TaxCategoriesCache', fetchTaxCategories, identity);

// exported for unit test usage only
export const _resetCache = () => {
  cache.clear();
};