import userInfo from 'hub-http/userInfo';
const __cache = new Map();
const emptySet = new Set();
export const getGates = async () => {
  try {
    // WARN: ignoreRedirect is important! Without it, any consumers that don't
    // support or shim userInfo can end up redirecting (the default behavior).
    // This has caused past critsits:
    // https://git.hubteam.com/HubSpot/Critsit/issues/3557
    // https://git.hubteam.com/HubSpot/Critsit/issues/1040
    const {
      gates
    } = await userInfo({
      ignoreRedirect: true
    });
    if (!__cache.has(gates)) {
      __cache.set(gates, new Set(gates));
    }
    return __cache.get(gates) || emptySet;
  } catch (e) {
    return emptySet;
  }
};