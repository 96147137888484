import { Record } from 'immutable';
import { calculatePercentage } from 'EmailData/utils/statisticsUtils';
const defaults = {
  'actually-sent': 0,
  bounce: 0,
  click: 0,
  click_on_identified_link: 0,
  contactslost: 0,
  cumulativeclicks: 0,
  cumulativeopens: 0,
  deferred: 0,
  delivered: 0,
  dropped: 0,
  error: 0,
  forward: 0,
  hardbounced: 0,
  mta_dropped: 0,
  notsent: 0,
  open: 0,
  ordered: 0,
  print: 0,
  processed: 0,
  pending: 0,
  reply: 0,
  selected: 0,
  sent: 0,
  softbounced: 0,
  spamreport: 0,
  statuschange: 0,
  suppressed: 0,
  unbounce: 0,
  unsubscribed: 0,
  unreliableOpens: 0,
  totalClicksExcludingBot: 0,
  totalOpensExcludingBots: 0,
  uniqueClicksIncludingBots: 0
};
class StatisticsCounters extends Record(defaults) {
  static from(json) {
    json.notsent = (json.selected || 0) - (json.sent || 0);
    return new StatisticsCounters(Object.assign({}, defaults, json));
  }
  getRatio(counter) {
    switch (counter) {
      case 'open':
      case 'click':
      case 'unsubscribed':
      case 'spamreport':
      case 'reply':
        return calculatePercentage(this[counter], this.delivered);
      default:
        return calculatePercentage(this[counter], this.sent);
    }
  }
}
export default StatisticsCounters;