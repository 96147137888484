import { SUBSCRIPTION_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useAdditionalPropertyValues } from './useAdditionalPropertyValues';

// See https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1921,
// https://git.hubteam.com/HubSpot/PropertiesFrontendTeam/issues/1984
export const useIsReadOnlySubscriptionProperty = (objectTypeId, propertyDefinition) => {
  // We only need this readonly override for hubspotDefined Subscription properties
  // (excluding hs_name, hubspot_owner_id, and hs_automatically_email_invoices)
  const skip = objectTypeId !== SUBSCRIPTION_TYPE_ID || !propertyDefinition.hubspotDefined || ['hs_name', 'hubspot_owner_id', 'hs_automatically_email_invoices'].includes(propertyDefinition.name);
  const {
    data,
    loading
  } = useAdditionalPropertyValues({
    hs_invoice_creation: 'hs_invoice_creation'
  }, {
    skip
  });
  if (skip) {
    return false;
  }
  const invoiceCreationOption = data === null || data === void 0 ? void 0 : data.hs_invoice_creation;
  if (loading || !invoiceCreationOption) {
    // default to read only if we cannot determine invoice creation options
    return true;
  }
  return invoiceCreationOption === 'on';
};