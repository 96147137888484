import { useMemo } from 'react';
import I18n from 'I18n';
import useGetAreEmailTypesAvailable from './useGetAreEmailTypesAvailable';
export default function useGetEmailCreationOptions() {
  const areEmailTypesAvailable = useGetAreEmailTypesAvailable();
  const regularEmailType = useMemo(() => ({
    type: 'REGULAR',
    upgradeKey: 'email-optimized-content',
    isLocked: !areEmailTypesAvailable(['BATCH_EMAIL']),
    illustrationName: 'successfully-connected-email',
    buttonLabel: I18n.text('email-management.emailCreation.emailCreationTypes.REGULAR.buttonLabel'),
    buttonDescription: I18n.text('email-management.emailCreation.emailCreationTypes.REGULAR.buttonDescription')
  }), [areEmailTypesAvailable]);
  const automatedEmailType = useMemo(() => ({
    type: 'AUTOMATED',
    upgradeKey: 'email-automated-sends',
    isLocked: !areEmailTypesAvailable(['AUTOMATED_EMAIL']),
    illustrationName: 'workflows',
    buttonLabel: I18n.text('email-management.emailCreation.emailCreationTypes.AUTOMATED.buttonLabel'),
    buttonDescription: I18n.text('email-management.emailCreation.emailCreationTypes.AUTOMATED.buttonDescription')
  }), [areEmailTypesAvailable]);
  const blogRssEmailType = useMemo(() => {
    const isRSSEmailAvailable = areEmailTypesAvailable(['RSS_EMAIL']);
    const buttonLabel = isRSSEmailAvailable ? I18n.text('email-management.emailCreation.emailCreationTypes.BLOG_RSS.buttonLabel') : I18n.text('email-management.emailCreation.emailCreationTypes.BLOG.buttonLabel');
    const buttonDescription = isRSSEmailAvailable ? I18n.text('email-management.emailCreation.emailCreationTypes.BLOG_RSS.buttonDescription') : I18n.text('email-management.emailCreation.emailCreationTypes.BLOG.buttonDescription');
    return {
      type: 'BLOG_RSS',
      upgradeKey: 'email-blog-rss-sends',
      isLocked: !areEmailTypesAvailable(['BLOG_EMAIL']),
      illustrationName: 'forms',
      buttonLabel,
      buttonDescription
    };
  }, [areEmailTypesAvailable]);
  return useMemo(() => [regularEmailType, automatedEmailType, blogRssEmailType], [regularEmailType, automatedEmailType, blogRssEmailType]);
}