import ApiNameToUpgradeProductMap from 'self-service-api/constants/ApiNameToUpgradeProductMap';
import { track, trackBeforeUnload } from '../../_core/common/eventTracking/tracker';
import { getSource } from '../../ums/utils';
export const getDeployableName = () => {
  // @ts-expect-error the error notes that bender is not defined on the
  // window.hubspot object, but it's documented in the auth docs:
  // https://product.hubteam.com/docs/appsystems/team-os/user-setup-fe/useful-info.html#how-to-check-which-app-your-looking-at
  return window.hubspot.bender.currentProject;
};
export function getBuyNowUsageTrackingProperties({
  apiNames,
  uniqueBuyNowUseCase,
  screen,
  upgradeReferralSource
}) {
  const app = getDeployableName();
  const upgradeProducts = [];
  for (const apiName of apiNames) {
    const upgradeProduct = ApiNameToUpgradeProductMap[apiName];
    if (upgradeProduct) {
      upgradeProducts.push(upgradeProduct);
    }
  }
  return {
    apiName: apiNames[0],
    apiNames,
    app,
    checkoutType: 'buy-now',
    screen,
    source: getSource(app, screen, uniqueBuyNowUseCase),
    uniqueId: uniqueBuyNowUseCase,
    upgradeProduct: upgradeProducts.length ? upgradeProducts[0] : undefined,
    upgradeProducts,
    upgradeSource: upgradeReferralSource
  };
}
export const trackViewBuyNowButton = buyNowTrackingProperties => {
  track('checkoutButtonInteraction', Object.assign({
    action: 'viewed'
  }, buyNowTrackingProperties));
};
export const trackClickedBuyNowButton = buyNowTrackingProperties => {
  // Existing view tracker to maintain backwards compatability
  trackBeforeUnload('checkoutButtonInteraction', Object.assign({
    action: 'clicked'
  }, buyNowTrackingProperties));

  // New tracking event to easily distinguish new buy now flow
  trackBeforeUnload('checkoutButtonInteraction', Object.assign({
    action: 'clicked checkout anywhere buy now button'
  }, buyNowTrackingProperties));
};
export const trackPurchaseOrderGenerated = buyNowTrackingProperties => {
  trackBeforeUnload('checkoutButtonInteraction', Object.assign({
    action: 'purchase order generated'
  }, buyNowTrackingProperties));
};
export const trackPurchaseOrderGenerationFailed = buyNowTrackingProperties => {
  track('checkoutButtonInteraction', Object.assign({
    action: 'purchase order generation failed'
  }, buyNowTrackingProperties));
};