import { useCallback, useEffect, useState } from 'react';
import makeObservable from './makeObservable';

/**
 * This function will return a custom hook that can be used to manage global state.
 * This should be called at a top level in a file, the returned function can then be used
 * within a component as a hook, eg
 * const useMyGlobalState = makeUseGlobalState<string>();
 * function myComponent {
 *   const myState = useMyGlobalState('initialValue');
 * }
 **/
export default function makeGlobalStateHook() {
  const sharedState$ = makeObservable();
  function useGlobalState(initialValue) {
    const sharedStateValue = sharedState$.get();
    const [state, setState] = useState(sharedStateValue !== undefined ? sharedStateValue : initialValue);
    const wrappedSetState = useCallback(newValue => {
      sharedState$.set(newValue);
    }, []);
    useEffect(() => {
      sharedState$.subscribe(setState);
    }, [setState]);
    return [state, wrappedSetState];
  }
  return useGlobalState;
}