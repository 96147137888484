import { gate } from 'hub-http/gates';
import { useCallback, useContext, useEffect, useRef } from 'react';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { useHttpClient } from '../client/HttpClientContext';
import { useGetIsUngated } from '../v2/hooks/useGetIsUngated';
import { useObjectId } from '../v2/hooks/useObjectId';
import { useObjectTypeId } from '../v2/hooks/useObjectTypeId';
import { usePropertyDefinition } from '../v2/hooks/usePropertyDefinition';
import { EDIT_INPUT_MODE } from '../v2/types/PropertyInputV2Component';
import { canValidateImmediately } from '../validation/utils/canValidateImmediately';
import { buildRefreshAlert, buildRetryAlert } from './alerts';
import { AutosaveContext } from './AutosaveContext';
import uniqueId from 'transmute/uniqueId';
const getShouldAutosaveImmediately = property => {
  return canValidateImmediately(property);
};
export const useAutosave = ({
  onBlur,
  onFocus,
  inputMode,
  onChange,
  onSave,
  onValidationChange,
  enableAutosave
}) => {
  const autosaveContext = useContext(AutosaveContext);
  const objectId = useObjectId();
  const objectTypeId = useObjectTypeId();
  const httpClient = useHttpClient();
  const propertyDefinition = usePropertyDefinition();
  const getIsUngated = useGetIsUngated();

  // We don't need to re-render when these are changed and we want synchronous
  // reading and writing so refs are a better fit compared to using react state
  const pendingEditsRef = useRef({});
  const validationRef = useRef();
  const isFocusedRef = useRef(false);
  const isUngatedToAutosave = getIsUngated(gate('CRM:Properties:Autosave'));
  const shouldAutosaveImmediately = getShouldAutosaveImmediately(propertyDefinition);
  const attemptSave = useCallback(() => {
    var _validationRef$curren;
    const editEntries = Object.entries(pendingEditsRef.current);
    if (!autosaveContext || inputMode !== EDIT_INPUT_MODE || !objectId || !((_validationRef$curren = validationRef.current) !== null && _validationRef$curren !== void 0 && _validationRef$curren.saveable) || !editEntries.length) {
      return;
    }
    const values = editEntries.map(([name, value]) => ({
      name,
      value
    }));
    pendingEditsRef.current = {};
    autosaveContext.logUpdate(objectTypeId, objectId, values);

    // Intentionally delay gate and enabled checks until after we have tracked changes that might have occurred.
    // We want to capture some of this data even if autosave is disabled to help guide early decisions.
    if (isUngatedToAutosave && enableAutosave) {
      const retryAlertId = uniqueId('autosave');
      const saveAndNotify = () => autosaveContext.save(objectTypeId, [{
        objectId: Number(objectId),
        propertyValues: values
      }], httpClient).then(updates => {
        if (onSave) {
          onSave(updates);
        }
      });
      saveAndNotify().catch(error => {
        FloatingAlertStore.addAlert(buildRetryAlert({
          id: retryAlertId,
          __error: error,
          onRetry: () => {
            FloatingAlertStore.removeAlert(retryAlertId);
            saveAndNotify().catch(retryError => {
              FloatingAlertStore.addAlert(buildRefreshAlert({
                __error: retryError
              }));
            });
          }
        }));
      });
    }
  }, [autosaveContext, enableAutosave, inputMode, objectId, objectTypeId, isUngatedToAutosave, httpClient, onSave]);
  const wrappedOnFocus = useCallback(evt => {
    isFocusedRef.current = true;
    onFocus(evt);
  }, [onFocus]);
  const wrappedOnBlur = useCallback(evt => {
    isFocusedRef.current = false;
    onBlur(evt);
    attemptSave();
  }, [attemptSave, onBlur]);

  // TODO: add a similar case for beforeunload
  // https://git.hubteam.com/orgs/HubSpot/projects/297/views/23?pane=issue&itemId=217559

  useEffect(
  // Save on unmount to handle annoying cases like index table ENTER key handler
  // where we may not have saved before unmounting
  () => () => {
    attemptSave();
  }, [attemptSave]);
  const wrappedOnChange = useCallback(changes => {
    for (const change in changes) {
      if (Object.prototype.hasOwnProperty.call(changes, change)) {
        pendingEditsRef.current[change] = changes[change];
      }
    }
    onChange(changes);
  }, [onChange]);
  const wrappedOnValidationChange = useCallback(validation => {
    validationRef.current = validation;
    onValidationChange(validation);
    if (shouldAutosaveImmediately || !isFocusedRef.current) {
      attemptSave();
    }
  }, [onValidationChange, shouldAutosaveImmediately, attemptSave]);
  return {
    onFocus: wrappedOnFocus,
    onBlur: wrappedOnBlur,
    onChange: wrappedOnChange,
    onValidationChange: wrappedOnValidationChange
  };
};