import * as ApiNames from './ApiNames';
const VersionNumbers = {
  [ApiNames.ADS_LIMIT_INCREASE]: {},
  [ApiNames.API_LIMIT_INCREASE]: {},
  [ApiNames.BRAZIL_ONBOARDING]: {},
  [ApiNames.BRAND_DOMAIN]: {},
  [ApiNames.BREEZE_INTELLIGENCE_CREDITS_100]: {},
  [ApiNames.BREEZE_INTELLIGENCE_CREDITS_1000]: {},
  [ApiNames.BREEZE_INTELLIGENCE_CREDITS_10000]: {},
  [ApiNames.BREEZE_INTELLIGENCE_CREDITS_100_COMMITTED]: {},
  [ApiNames.BREEZE_INTELLIGENCE_CREDITS_1000_COMMITTED]: {},
  [ApiNames.BREEZE_INTELLIGENCE_CREDITS_10000_COMMITTED]: {},
  [ApiNames.CALCULATED_PROPERTIES_LIMIT_INCREASE]: {},
  [ApiNames.CALLING_LIMIT_INCREASE]: {},
  [ApiNames.CMS_HUB_ENTERPRISE]: {
    LATEST: 4,
    LATEST_PRESEATS: 3
  },
  [ApiNames.CMS_HUB_ENTERPRISE_ONBOARDING]: {},
  [ApiNames.CMS_HUB_FREE]: {},
  [ApiNames.CMS_HUB_PROFESSIONAL]: {
    LATEST: 12,
    LATEST_PRESEATS: 11
  },
  [ApiNames.CMS_HUB_PROFESSIONAL_ONBOARDING]: {},
  [ApiNames.CMS_HUB_STARTER]: {
    LATEST: 7,
    LATEST_PRESEATS: 6
  },
  [ApiNames.CORE_SEATS_ENTERPRISE]: {},
  [ApiNames.CORE_SEATS_INCLUDED_STARTER]: {},
  [ApiNames.CORE_SEATS_PROFESSIONAL]: {},
  [ApiNames.CORE_SEATS_STARTER]: {},
  [ApiNames.CRM_CONTACT_RECORDS_LIMIT_INCREASE]: {},
  [ApiNames.CRM_OBJECT_LIMIT_INCREASE]: {},
  [ApiNames.CRM_SUITE_ENTERPRISE_BUNDLE]: {
    LATEST: 2
  },
  [ApiNames.CRM_SUITE_PROFESSIONAL_BUNDLE]: {
    LATEST: 2
  },
  [ApiNames.CRM_SUITE_STARTER_BUNDLE]: {
    LATEST: 3
  },
  [ApiNames.CUSTOM_OBJECTS_LIMIT_INCREASE]: {},
  [ApiNames.CUSTOM_PROPERTIES_LIMIT_INCREASE]: {},
  [ApiNames.CUSTOM_SSL]: {},
  [ApiNames.DEDICATED_IP]: {
    LEGACY: 1,
    EARLY_2020: 3
  },
  [ApiNames.DOMAINS_LIMIT_INCREASE]: {},
  [ApiNames.ESIGNATURES_LIMIT_INCREASE]: {},
  [ApiNames.ESIGNATURE_LIMIT_INCREASE]: {},
  [ApiNames.FIRST_FULL_TIME_MIGRATION]: {},
  [ApiNames.HUBSPOT_FOR_STARTUPS_SETUP]: {},
  [ApiNames.HUBSPOT_FREE]: {},
  [ApiNames.INBOUND_CONSULTING_ADVANCED]: {},
  [ApiNames.INBOUND_CONSULTING_PREMIUM]: {},
  [ApiNames.LISTS_LIMIT_INCREASE]: {},
  [ApiNames.MARKETING_HUB_BASIC]: {},
  [ApiNames.MARKETING_HUB_ENTERPRISE]: {
    LATEST: 15,
    LATEST_PRESEATS: 14,
    MARKETING_CONTACTS: 12
  },
  [ApiNames.MARKETING_HUB_ENTERPRISE_ONBOARDING]: {},
  [ApiNames.MARKETING_HUB_FREE]: {},
  [ApiNames.MARKETING_HUB_PROFESSIONAL]: {
    LATEST: 15,
    LATEST_PRESEATS: 14,
    MARKETING_CONTACTS: 12
  },
  [ApiNames.MARKETING_HUB_PROFESSIONAL_ONBOARDING]: {},
  [ApiNames.MARKETING_HUB_STARTER]: {
    LATEST: 8,
    LATEST_PRESEATS: 7,
    LEGACY_250: 2,
    NOV_2019: 3,
    MARKETING_CONTACTS: 4
  },
  [ApiNames.MARKETING_HUB_STARTER_ONBOARDING]: {},
  [ApiNames.MARKETING_PLUS_ENTERPRISE_BUNDLE]: {
    LATEST: 1,
    LATEST_PRESEATS: 2
  },
  [ApiNames.MARKETING_PLUS_PROFESSIONAL_BUNDLE]: {
    LATEST: 1,
    LATEST_PRESEATS: 2
  },
  [ApiNames.MARKETING_SMS_ADDON]: {},
  [ApiNames.MARKETING_SMS_LIMIT_INCREASE]: {},
  [ApiNames.MARKETING_SMS_SHORT_CODE]: {},
  [ApiNames.ONBOARDING_FOUNDATIONS]: {},
  [ApiNames.OPERATIONS_HUB_ENTERPRISE]: {
    LATEST: 2,
    LATEST_PRESEATS: 1
  },
  [ApiNames.OPERATIONS_HUB_FREE]: {},
  [ApiNames.OPERATIONS_HUB_PROFESSIONAL]: {
    LATEST: 2,
    LATEST_PRESEATS: 1
  },
  [ApiNames.OPERATIONS_HUB_STARTER]: {
    LATEST: 3,
    LATEST_PRESEATS: 1
  },
  [ApiNames.PHONE_LIMIT_INCREASE]: {},
  [ApiNames.PREMIUM_CONSULTING]: {},
  [ApiNames.REPORTING_LIMIT_INCREASE]: {},
  [ApiNames.SALES_HUB_ENTERPRISE]: {
    LATEST: 3,
    LATEST_PRESEATS: 1,
    QUANTIFIABLE_SEAT: 3
  },
  [ApiNames.SALES_HUB_ENTERPRISE_ONBOARDING]: {},
  [ApiNames.SALES_HUB_FREE]: {},
  [ApiNames.SALES_HUB_PROFESSIONAL]: {
    LATEST: 7,
    LATEST_PRESEATS: 5,
    LEGACY_BUSINESS_USER: 3,
    QUANTIFIABLE_SEAT: 7
  },
  [ApiNames.SALES_HUB_PROFESSIONAL_ONBOARDING]: {},
  [ApiNames.SALES_HUB_STARTER]: {
    LATEST: 5,
    LATEST_PRESEATS: 3,
    LEGACY: 1,
    MARCH_2020: 3
  },
  [ApiNames.SALES_HUB_STARTER_ONBOARDING]: {},
  [ApiNames.SANDBOX_LIMIT_INCREASE]: {},
  [ApiNames.SERVICE_HUB_ENTERPRISE]: {
    LATEST: 3,
    LATEST_PRESEATS: 1,
    QUANTIFIABLE_SEAT: 3
  },
  [ApiNames.SERVICE_HUB_ENTERPRISE_ONBOARDING]: {},
  [ApiNames.SERVICE_HUB_FREE]: {},
  [ApiNames.SERVICE_HUB_PROFESSIONAL]: {
    LATEST: 2,
    LATEST_PRESEATS: 1,
    QUANTIFIABLE_SEAT: 2
  },
  [ApiNames.SERVICE_HUB_PROFESSIONAL_ONBOARDING]: {},
  [ApiNames.SERVICE_HUB_STARTER]: {
    LATEST: 4,
    LATEST_PRESEATS: 2,
    LEGACY: 1,
    MARCH_2020: 2
  },
  [ApiNames.SERVICE_HUB_STARTER_ONBOARDING]: {},
  [ApiNames.STARTER_KIT]: {},
  [ApiNames.STRATEGIC_CONSULTING_4_HOUR]: {},
  [ApiNames.STRATEGIC_CONSULTING_MONTHLY]: {},
  [ApiNames.STRATEGIC_CONSULTING_ONGOING]: {},
  [ApiNames.TEAMS_LIMIT_INCREASE]: {},
  [ApiNames.TECHNICAL_CONSULTING_4_HOUR]: {},
  [ApiNames.TECHNICAL_CONSULTING_ADVANCED]: {},
  [ApiNames.TECHNICAL_CONSULTING_ONGOING]: {},
  [ApiNames.TECHNICAL_CONSULTING_PREMIUM]: {},
  [ApiNames.TRAINING_AGENCY_MARKETING_HUB_FUNDAMENTALS]: {},
  [ApiNames.TRANSACTIONAL_EMAIL]: {},
  [ApiNames.TRANSCRIPTION_LIMIT_INCREASE]: {},
  [ApiNames.USERS_LIMIT_INCREASE]: {},
  [ApiNames.VIDEO_LIMIT_INCREASE]: {},
  [ApiNames.WHATSAPP_LIMIT_INCREASE]: {},
  [ApiNames.WORKFLOWS_LIMIT_INCREASE]: {}
};
export default VersionNumbers;