import { isFile, isHtml, isPhoneNumber } from 'customer-data-objects/property/PropertyIdentifier';
/**
 * Returns true if the given validation request can be validated immediately,
 * rather than being debounced in case of further changes.
 *
 * Property changes that happen slowly can be validated immediately (i.e.,
 * inputs that do not allow typing). For instance, choosing an option from a
 * dropdown or a date from a date picker do not need to wait for a debounce
 * period, because the user is not actively typing. Waiting for a debounce
 * after manually choosing from dropdown select adds unnecessary latency to
 * the UI.
 *
 * Inputs which can be interactively typed into are:
 *
 * - `type=string` - text inputs, text areas, and rich text
 * - `type=number` - number inputs, currency inputs, percentage inputs
 */
export const canValidateImmediately = property => {
  switch (property.type) {
    case 'string':
      {
        // some string fieldTypes open popovers. validation only happens once,
        // when the popover closes and the change is applied
        if (isFile(property) || isHtml(property) || isPhoneNumber(property)) {
          return true;
        }
        return false;
      }
    case 'number':
      {
        return false;
      }
    case 'bool':
    case 'date':
    case 'datetime':
    case 'enumeration':
      return true;
    // @ts-expect-error CDO is not aware of this property type yet
    case 'phone_number':
      return true;
    // @ts-expect-error CDO is not aware of this property type yet
    case 'currency_number':
      return true;
    // @ts-expect-error CDO is not aware of this property type yet
    case 'json':
      return true;
    // @ts-expect-error CDO is not aware of this property type yet
    case 'object_coordinates':
      return true;
    default:
      {
        // default to allowing immediate validation for unknown property types.
        // NOTE: we can't throw an exception here because it breaks many unit
        // tests which pass partial property definitions missing a `type`
        const __exhaustiveCheck = property.type;
        return true;
      }
  }
};