import styled from 'styled-components';
import SkeletonBase from './SkeletonBase';
import { CIRCLE_BASE_SIZE, getSize } from './skeletonBaseUtils';
// Default props
const DEFAULT_SIZE = CIRCLE_BASE_SIZE;
const SkeletonCircle = styled(SkeletonBase).withConfig({
  displayName: "SkeletonCircle",
  componentId: "sc-1sgn2tt-0"
})(["border-radius:50%;width:", ";height:", ";min-width:", ";"], ({
  size = DEFAULT_SIZE
}) => getSize(size), ({
  size = DEFAULT_SIZE
}) => getSize(size), ({
  size = DEFAULT_SIZE
}) => getSize(size));
SkeletonCircle.displayName = 'SkeletonCircle';
export default SkeletonCircle;