import http from 'hub-http/clients/apiClient';
export function getBlogs({
  searchTerm,
  businessUnitId
}) {
  const uriToUse = businessUnitId === undefined ? 'blog-settings/v1/blogs' : `blog-settings/v1/blogs/business-unit/${businessUnitId}`;
  return http.get(uriToUse, {
    query: {
      name__icontains: searchTerm
    }
  });
}
export function getEmailsForBlog(blogId) {
  return http.get(`cosemail/v1/emails/blog/${blogId}`);
}

// There are much more keys in the response, but we only care about these ones