const isExpired = removedAt => {
  const now = Date.now();
  return removedAt && removedAt < now;
};
const getInTrial = ({
  removedAt,
  createdAt,
  endsAt
}) => {
  const hasExpired = isExpired(removedAt);
  if (!hasExpired) {
    return {
      createdTs: createdAt,
      expiresTs: endsAt
    };
  }
  return null;
};
const getHasTrialed = ({
  removedAt,
  createdAt
}) => {
  const hasExpired = isExpired(removedAt);
  if (hasExpired) {
    return {
      lastTrialStarted: createdAt,
      lastTrialExpired: removedAt
    };
  }
  return null;
};
export const buildTrialState = (createdAt, endsAt, removedAt, id) => {
  const inTrial = getInTrial({
    removedAt,
    createdAt,
    endsAt
  });
  const hasTrialed = getHasTrialed({
    removedAt,
    createdAt
  });
  return {
    id,
    inTrial,
    hasTrialed
  };
};