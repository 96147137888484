import { useState } from 'react';
import Raven from 'raven-js';
import { useHttpClient } from '../../client/HttpClientContext';
import { cache } from '../../input/taxCategory/taxCategoriesCache';
export const useTaxCategories = () => {
  const [categories, setCategories] = useState([]);
  const httpClient = useHttpClient();
  cache.get('TaxCategoriesCache', httpClient).then(res => {
    var _res$results;
    setCategories((_res$results = res === null || res === void 0 ? void 0 : res.results) !== null && _res$results !== void 0 ? _res$results : []);
  }).catch(error => {
    if ('message' in error) {
      Raven.captureException(new Error(error.message));
    }
  });
  return categories;
};