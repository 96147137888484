import { useCallback } from 'react';
import useUserInfo from './useUserInfo';

// For v3 gates, always pass gates called with the hub-http/gates:gate() function
// https://product.hubteam.com/docs/gating/next-gen/usage.html#frontend-usage
// eg for v2 gates hasAllGates('my-gate') and for v3 gates hasAllGates(gate('my-gate'))
export default function useHasAllGates() {
  const {
    isUngatedFor
  } = useUserInfo();
  const hasAllGates = useCallback(gateOrGates => {
    if (gateOrGates === undefined || Array.isArray(gateOrGates) && gateOrGates.length === 0) {
      return true;
    }
    if (Array.isArray(gateOrGates)) {
      return gateOrGates.every(gate => isUngatedFor(gate));
    }
    return isUngatedFor(gateOrGates);
  }, [isUngatedFor]);
  return hasAllGates;
}