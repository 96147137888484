import ApiNameToUpgradeProductMap from '../constants/ApiNameToUpgradeProductMap';
// @ts-expect-error needs to be migrated to TS
import UpgradeProductToApiNameMap from '../constants/UpgradeProductToApiNameMap';
import { buildTrialState } from '../core/utilities/trialUtils';
export const trialStateAdapter = trialState => {
  if (trialState.length === 0) return trialState;
  const transformedTrialState = {};
  trialState.forEach(hubTrial => {
    const {
      trial: {
        name,
        id
      },
      createdAt,
      endsAt,
      removedAt
    } = hubTrial;

    // for Marketing+ trials, the name of the trial is the ApiName, not the UpgradeProduct
    // to handle this, we will check if the name is an ApiName, if it is not we
    // will look it up in the UpgradeProductToApiNameMap.
    const label = name in ApiNameToUpgradeProductMap ? name : UpgradeProductToApiNameMap[name];
    if (label) {
      transformedTrialState[label] = buildTrialState(createdAt, endsAt, removedAt, id);
    }
  });
  return transformedTrialState;
};